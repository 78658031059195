import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
// import { useEmailResetPasswordMutation } from "src/redux/auth/apiSlice";

// Libraries
import { zodResolver } from "@hookform/resolvers/zod";

// Utils
import { ForgotPassword, ForgotPasswordSchema } from "src/redux/auth/schema";

// Components
import { Input } from "src/shared/components/ui/input";
import { Button } from "src/shared/components/ui/button";
import { Label } from "src/shared/components/ui/label";
import {
	Card,
	CardContent,
	CardDescription,
	CardFooter,
	CardHeader,
	CardTitle,
} from "src/shared/components/ui/card";
import FormController from "src/shared/components/form/FormController";
import { Form, FormField, FormMessage } from "src/shared/components/form/Form";

import AuthEmailIconSvg from "src/assets/svg/auth/AuthEmailIconSvg";
import BackIconSvg from "src/assets/svg/BackIconSvg";
import diyLogo from "src/assets/images/mini_diy_logo.png";

const ForgotPasswordPage = () => {
	const navigate = useNavigate();
	// const [emailResetPassword, { isLoading, isSuccess }] =
	// 	useEmailResetPasswordMutation();
	const [message, setMessage] = useState("");

	const form = useForm<ForgotPassword>({
		resolver: zodResolver(ForgotPasswordSchema),
		defaultValues: {
			email: "",
		},
		mode: "onChange",
		criteriaMode: "all",
	});

	const _onSubmit = async (values: ForgotPassword) => {
		// try {
		// 	const result = await emailResetPassword({ email: values.email }).unwrap();
		// 	if (result.status === 201) {
		// 		setMessage(result.message);
		// 	}
		// } catch (error) {
		// 	// if (isErrorResponse(error)) {
		// 	// 	if (error.status === 500) {
		// 	// 		console.log("An unexpected error occured.");
		// 	// 		// REDIRECT TO ERROR PAGE
		// 	// 	} else {
		// 	// 		form.setError("email", { message: error.data.message });
		// 	// 	}
		// 	// }
		// }`
	};

	return (
		<Form {...form}>
			<form onSubmit={form.handleSubmit(_onSubmit)}>
				<Card className="w-[80%] max-w-[20em] sm:w-[20em] sm:max-w-[28em] lg:w-[23em] 2xl:w-[28em] rounded-3xl absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/4">
					<CardHeader className="pb-5 2xl:pb-8">
						<CardTitle className="relative mb-9 lg:mb-14 2xl:mb-20">
							<div className="absolute h-[6em] w-[6em] -top-24 left-1/2 -translate-x-1/2 overflow-hidden lg:w-[8em] lg:h-[8em] 2xl:w-[10em] 2xl:h-[10em] lg:-top-32 2xl:-top-36">
								<img
									src={diyLogo}
									alt="banner"
									className="w-full h-full object-fill"
								/>
							</div>
						</CardTitle>
						<CardDescription className="flex flex-col gap-4 w-full lg:w-[90%] mx-auto">
							<div className="flex flex-row text-center">
								<Button
									size="icon"
									variant="icon"
									onClick={() => navigate("/auth")}
								>
									<BackIconSvg
										className="h-7 w-7 lg:h-8 lg:w-8 2xl:h-10 2xl:w-10"
										fill="#292D32"
									/>
								</Button>
								<Label
									variant="sub_header"
									className="flex mx-auto w-fit text-secondary font-semibold 2xl:text-2xl 2xl:my-2"
								>
									Forgot Password
								</Label>
							</div>
							<Label
								variant="description"
								className="flex mx-auto text-secondary lg:text-xs text-center leading-tight tracking-tight"
							>
								Please enter your email address where the link to reset your
								password will be sent.
							</Label>
						</CardDescription>
					</CardHeader>
					<CardContent>
						<div className="flex flex-col w-full items-center gap-4 2xl:gap-6">
							<div className="flex flex-col gap-1 w-[90%]">
								<Label
									variant="form"
									className="flex flex-row gap-1 2xl:gap-2 items-center"
								>
									<AuthEmailIconSvg
										className="h-4 w-4 2xl:h-6 2xl:w-6"
										fill="#292D32"
									/>
									Email
								</Label>
								<FormField
									control={form.control}
									name="email"
									render={({ field }) => (
										<FormController>
											<Input {...field} />
										</FormController>
									)}
								/>
								{message && (
									<FormMessage className="text-green-400">
										{message}
									</FormMessage>
								)}
							</div>
						</div>
					</CardContent>
					<CardFooter className="flex justify-center mb-2 lg:mt-1 lg:mb-8 2xl:mt-2">
						{/* <Button
							size="sm"
							className="px-6 h-7 lg:h-9 lg:px-10 xl:w-[40%] shadow-lg rounded-full"
							type="submit"
							disabled={isLoading || isSuccess}
						>
							{isLoading ? (
								<>
									<ButtonLoading />
								</>
							) : (
								"Submit"
							)}
						</Button> */}
					</CardFooter>
				</Card>
			</form>
		</Form>
	);
};

export default ForgotPasswordPage;
