import { useCallback, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
	useGetActiveBannersQuery,
	useGetInactiveBannersQuery,
} from "../../redux/banner/apiSlice";
import { useActiveBannerColumns } from "./activeBannersColumns";
import { useInactiveBannerColumns } from "./inactiveBannersColumns";

// Components
import { DataTable } from "src/shared/components/table/DataTable";
import { Label } from "src/shared/components/ui/label";
import { Button } from "src/shared/components/ui/button";
import PageControls from "src/shared/components/control/PageControls";
import PageContainer from "src/shared/components/layout/container/PageContainer";
import PageHeader from "src/shared/components/layout/header/PageHeader";
import SearchInput from "src/shared/components/textfield/SearchInput";
import TableTabs from "./TableTabs";
import Loading from "src/shared/components/loading-indicator/Loading";

// Icons
import ButtonAddIconSvg from "src/assets/svg/ButtonAddIconSvg";
import ButtonDeleteIconSvg from "src/assets/svg/ButtonDeleteIconSvg";
import ConfirmationDialog from "src/shared/components/dialog/ConfirmationDialog";
import DesignBackground from "src/shared/components/background/DesignBackground";
import { Banner, BannerAPI } from "src/redux/banner/types";
import NotificationDialog from "src/shared/components/dialog/NotificationDialog";

const BannerPage = () => {
	const navigate = useNavigate();

	const { data: activeBanners = [], isFetching: isActiveBannersFetching } =
		useGetActiveBannersQuery();

	const { data: inactiveBanners = [], isFetching: isInactiveBannersFetching } =
		useGetInactiveBannersQuery();

	const [showLoading, setShowLoading] = useState(false);

	const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
	const [isMultipleDeleteModalOpen, setIsMultipleDeleteModalOpen] =
		useState(false);
	const [isDeleteNotifModalOpen, setIsDeleteNotifModalOpen] = useState(false);
	const [searchValue, setSearchValue] = useState("");
	const [selectedBanner, setSelectedBanner] = useState({
		id: 0,
		name: "",
	});
	const [selectedRows, setSelectedRows] = useState<BannerAPI[]>([]);
	const [isActiveTable, setIsActiveTable] = useState(true);

	// const bannerArray = isActiveTable ? activeBanners : inactiveBanners;
	const bannerArray = activeBanners;

	const filteredBanners = useMemo(() => {
		return bannerArray.filter(
			(banner) =>
				banner.title.toLowerCase().includes(searchValue.toLowerCase()) ||
				banner.description.toLowerCase().includes(searchValue.toLowerCase())
		);
	}, [searchValue, bannerArray]);

	const handleSelectionChange = useCallback((newSelectedRows: BannerAPI[]) => {
		setSelectedRows(newSelectedRows);
	}, []);

	const _onOpenDeleteModal = (id: number, name: string) => {
		setIsDeleteModalOpen(true);
		setSelectedBanner({
			id: id,
			name: name,
		});
	};

	const _onCloseDeleteModal = () => {
		setIsDeleteModalOpen(false);
	};

	const _onConfirmDelete = async () => {
		setShowLoading(true);
		setTimeout(async () => {
			setShowLoading(false);
			setIsDeleteModalOpen(false);
			setTimeout(() => {
				setIsDeleteNotifModalOpen(true);
			}, 250);
		}, 2000);
	};

	const _onOpenMultipleDeleteModal = () => {
		setIsMultipleDeleteModalOpen(true);
	};

	const _onCloseMultipleDeleteModal = () => {
		setIsMultipleDeleteModalOpen(false);
	};

	const _onConfirmMultipleDelete = async () => {
		setShowLoading(true);
		setTimeout(async () => {
			setShowLoading(false);
			setIsMultipleDeleteModalOpen(false);
			setTimeout(() => {
				setIsDeleteNotifModalOpen(true);
			}, 250);
		}, 2000);
	};

	const _onDeleteNotifOkayPress = () => {
		setIsDeleteNotifModalOpen(false);
	};

	const activeColumns = useActiveBannerColumns(_onOpenDeleteModal);
	const inactiveColumns = useInactiveBannerColumns(_onOpenDeleteModal);

	if (isActiveBannersFetching || isInactiveBannersFetching) {
		return <Loading />;
	}

	return (
		<PageContainer>
			<PageHeader>
				<Label variant="title">BannerAPI Management</Label>
			</PageHeader>

			<PageControls>
				<div className="flex gap-2 w-full sm:w-[15em]">
					<Button onClick={() => navigate("add")}>
						<ButtonAddIconSvg fill="#292d32" className="h-6 w-6" />
						Add Banner
					</Button>
					<Button
						variant="destructive"
						disabled={selectedRows.length < 1}
						onClick={_onOpenMultipleDeleteModal}
					>
						<ButtonDeleteIconSvg
							fill={`${selectedRows.length < 1 ? "#cbcbcc" : "white"}`}
							className="h-5 w-5 mb-[2px]"
						/>
						Delete
					</Button>
				</div>
				<div className="flex w-fit ml-auto">
					<SearchInput setSearchValue={setSearchValue} />
				</div>{" "}
			</PageControls>

			{/* Active and Inactive button */}
			<TableTabs
				isActiveTable={isActiveTable}
				setIsActiveTable={setIsActiveTable}
			/>

			{isActiveTable && (
				<DataTable
					data={filteredBanners}
					columns={activeColumns}
					onSelectionChange={handleSelectionChange}
					hasPagination={activeBanners.length > 10}
				/>
			)}

			{/* {!isActiveTable && (
				<DataTable
					data={filteredBanners}
					columns={inactiveColumns}
					onSelectionChange={handleSelectionChange}
					hasPagination={inactiveBanners.length > 10}
				/>
			)} */}

			<ConfirmationDialog
				title="Delete BannerAPI?"
				description={`BannerAPI ${selectedBanner.name} will be deleted`}
				closeButtonLabel="Cancel"
				confirmButtonLabel="Delete"
				confirmButtonIcon={ButtonDeleteIconSvg}
				modalState={isDeleteModalOpen}
				isLoading={showLoading}
				_onConfirm={_onConfirmDelete}
				_onCancel={_onCloseDeleteModal}
			/>

			<ConfirmationDialog
				title={`Delete Selected Banners?`}
				description={`${selectedRows.length} banners will be deleted`}
				closeButtonLabel="Cancel"
				confirmButtonLabel="Delete"
				confirmButtonIcon={ButtonDeleteIconSvg}
				modalState={isMultipleDeleteModalOpen}
				isLoading={showLoading}
				_onConfirm={_onConfirmMultipleDelete}
				_onCancel={_onCloseMultipleDeleteModal}
			/>

			<NotificationDialog
				description={
					selectedRows.length > 0
						? "Banners has been deleted successfully!"
						: "Banner has been deleted successfully!"
				}
				confirmButtonLabel="Ok"
				modalState={isDeleteNotifModalOpen}
				_onConfirm={_onDeleteNotifOkayPress}
			/>
		</PageContainer>
	);
};

export default BannerPage;
