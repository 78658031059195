import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {
	useAddCategoryMutation,
	useGetSelectedCategoryQuery,
	useUpdateCategoryMutation,
} from "src/redux/category/apiSlice";

// Utils
import {
	validateFileExtension,
	validateFileSize,
} from "src/shared/helpers/validateAttachment";
import { zodResolver } from "@hookform/resolvers/zod";

// Components
import { Button } from "src/shared/components/ui/button";
import {
	Dialog,
	DialogContent,
	DialogFooter,
	DialogHeader,
} from "src/shared/components/ui/dialog";
import { Input } from "src/shared/components/ui/input";
import { Label } from "src/shared/components/ui/label";
import { Category } from "../../../redux/category/types";
import { CategoryFormSchema } from "../../../redux/category/schema";
import { Form, FormField } from "src/shared/components/form/Form";
import FormController from "src/shared/components/form/FormController";
import UploadButton from "src/shared/components/button/UploadButton";
import ButtonLoading from "src/shared/components/loading-indicator/ButtonLoading";
import ConfirmationDialog from "src/shared/components/dialog/ConfirmationDialog";

// Icon
import ButtonPlusIconSvg from "src/assets/svg/ButtonPlusIconSvg";
import { Loader2, X } from "lucide-react";
import { useToast } from "src/shared/hooks/useToast";
import { isErrorResponse } from "src/shared/helpers/errorAssertion";
import { ToastClose } from "src/shared/components/ui/toast";

type PreviewProductModalProps = {
	selectedCategory: Category;
	setCategorySelected: React.Dispatch<React.SetStateAction<Category>>;
	modalState: boolean;
	modalTrigger: () => void;
	setIsNotifModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const CategoryFormModal = ({
	selectedCategory,
	setCategorySelected,
	modalState,
	modalTrigger,
	setIsNotifModalOpen,
}: PreviewProductModalProps) => {
	const { toast } = useToast();
	const [isLeaveModalOpen, setIsLeaveModalOpen] = useState(false);
	const [isSaveModalOpen, setIsSaveModalOpen] = useState(false);
	const [imagePath, setImagePath] = useState("");

	const { data: category, isFetching } = useGetSelectedCategoryQuery(
		selectedCategory.id,
		{ skip: selectedCategory.id == 0 }
	);

	const [_updateCategory, { isLoading: isUpdateCategoryLoading }] =
		useUpdateCategoryMutation();

	const [_addCategory, { isLoading: isCreateCategoryLoading }] =
		useAddCategoryMutation();

	const form = useForm<Category>({
		resolver: zodResolver(CategoryFormSchema),
		mode: "onChange",
		reValidateMode: "onSubmit",
		criteriaMode: "all",
	});

	const { isDirty, isValid } = form.formState;

	const _onCancelPress = () => {
		if (!isDirty) {
			form.reset();
			setCategorySelected({
				id: 0,
				name: "",
				image: "",
			});
			modalTrigger();
		}

		if (isDirty) setIsLeaveModalOpen(true);
	};

	const _onConfirmLeave = () => {
		form.reset();
		setCategorySelected({
			id: 0,
			name: "",
			image: "",
		});
		modalTrigger();
		setIsLeaveModalOpen(false);
	};

	const _onSavePress = () => {
		setIsSaveModalOpen(true);
	};

	const _onConfirmSave = () => {
		_onSubmit(form.getValues());
	};

	const _onSubmit = async (values: Category) => {
		setIsSaveModalOpen(false);

		try {
			if (!selectedCategory.id) {
				const newCategory: Omit<Category, "id"> = {
					name: values.name,
					image: `http://example.com/${imagePath}`,
					description: "Category Description",
					status: 1,
					updated_by: Number(localStorage.getItem("USER_ID")),
					updated_from: 1,
					created_by: Number(localStorage.getItem("USER_ID")),
					created_from: 1,
					country_code: "US",
				};

				const response = await _addCategory(newCategory);

				if (response.error) {
					throw response.error;
				}

				modalTrigger();
				setTimeout(() => {
					setIsNotifModalOpen(true);
				}, 250);
			} else {
				const updatedCategory: Category = {
					id: selectedCategory.id,
					name: values.name,
					image: `http://example.com/${imagePath}`,
					description: selectedCategory.description,
					status: selectedCategory.status,
					updated_by: Number(localStorage.getItem("USER_ID")),
					updated_from: 1,
					country_code: selectedCategory.country_code,
				};

				const response = await _updateCategory(updatedCategory);

				if (response.error) {
					throw response.error;
				}

				modalTrigger();
				setTimeout(() => {
					setIsNotifModalOpen(true);
				}, 250);
			}
		} catch (error) {
			if (isErrorResponse(error)) {
				toast({
					description: error.data.message,
					duration: 2000,
					variant: "destructive",
					action: (
						<ToastClose
							className="absolute top-1/2 -translate-y-1/2 right-5 text-white hover:text-gray-200 focus:text-gray-200"
							aria-label="Close"
						>
							<X className="w-5 h-5" />
						</ToastClose>
					),
				});
			} else {
				toast({
					description: "An unexpected error occurred.",
					duration: 2000,
					variant: "destructive",
				});
			}
		}
	};

	useEffect(() => {
		if (selectedCategory.id == 0) {
			form.reset({
				name: "",
				image: "",
			});
			setImagePath("");
		}
		if (category && selectedCategory.id != 0) {
			form.reset(
				{
					name: category.name,
					image: category.image,
				},
				{
					keepDirty: false,
					keepTouched: false,
					keepIsValid: false,
					keepErrors: false,
				}
			);
			setImagePath(category.image);
		}
	}, [selectedCategory, category]);

	return (
		<Dialog open={modalState} onOpenChange={modalTrigger}>
			<Form {...form}>
				<div className="h-auto relative">
					<form onSubmit={form.handleSubmit(_onSubmit)}>
						<DialogContent
							className={`lg:h-fit  ${
								isFetching
									? "w-fit rounded-lg lg:rounded-xl"
									: "w-[90%] max-w-[380px] sm:w-full sm:max-w-[460px] lg:w-[460px] rounded-xl lg:rounded-2xl"
							}`}
						>
							{isFetching && selectedCategory.id != 0 ? (
								<Loader2 className="text-primary h-14 w-14 animate-spin" />
							) : (
								<>
									<DialogHeader className="mt-4 xl:h-1 xl:my-4">
										<Label className="w-full text-center xl:text-lg">
											{!selectedCategory?.id ? "Add Category" : "Edit Category"}
										</Label>
									</DialogHeader>
									<div className="grid grid-cols-3">
										<div className="col-span-3">
											<FormField
												control={form.control}
												name="name"
												render={({ field }) => (
													<FormController label="Category Name">
														<Input {...field} placeholder="-" />
													</FormController>
												)}
											/>
										</div>

										<div className="col-span-3 gap-4 md:flex md:flex-row">
											<FormField
												control={form.control}
												name="image"
												render={({
													field: { value, onChange, ...fieldProps },
												}) => (
													<div>
														<FormController label="Category Image">
															<div className="relative h-[8em] w-[8em] md:w-[10em]  md:h-[10em] aspect-square border-[1px] border-secondary rounded-md overflow-hidden mx-auto sm:ml-2">
																{value && (
																	<div
																		className="absolute inset-0 bg-cover bg-no-repeat bg-center opacity-70"
																		style={{
																			backgroundImage: `url(${
																				value ===
																					"https://example.com/image.png" ||
																				!value
																					? "/assets/categories/default_category.png"
																					: value
																			})`,
																		}}
																	/>
																)}

																<div className="relative h-full w-full flex items-center justify-center">
																	<div className="absolute bottom-10 md:bottom-14 ">
																		<UploadButton
																			icon={ButtonPlusIconSvg}
																			className="w-[5em]"
																			{...fieldProps}
																			accept="image/*"
																			onChange={(
																				e: React.ChangeEvent<HTMLInputElement>
																			) => {
																				const file = e.target.files?.[0];

																				if (file) {
																					const isAttachmentValid =
																						validateFileSize({
																							file: file,
																							form: form,
																							errorMessage:
																								"Image size must not exceed 2mb.",
																							fieldName: "image",
																							sizeLimit: 2,
																						});

																					const isExtensionValid =
																						validateFileExtension({
																							file: file,
																							form: form,
																							errorMessage:
																								"Invalid file type. Please upload a .png, .jpg file.",
																							fieldName: "image",
																							validExtensions: [
																								"image/jpeg",
																								"image/jpg",
																								"image/png",
																							],
																						});

																					if (
																						!isExtensionValid ||
																						!isAttachmentValid
																					) {
																						return;
																					} else {
																						form.clearErrors();
																					}

																					const imageUrl =
																						URL.createObjectURL(file);

																					setImagePath(file.name);
																					onChange(imageUrl);

																					// Here you would typically upload the file to your server
																					// and then update the form with the returned URL
																					// uploadFileToServer(file).then(url => onChange(url));
																				}
																			}}
																			value="" // Keep the file input value empty
																			{...fieldProps}
																		/>
																	</div>
																</div>
															</div>
														</FormController>
													</div>
												)}
											/>
											<div className="flex flex-col gap-1 lg:gap-0 leading-tight tracking-tight md:tracking-normal md:leading-normal text-secondary md:mt-8 text-justify">
												<Label
													variant="description"
													className="lg:text-[0.70rem]"
												>
													&bull; Recommended image resolution is minimum of{" "}
													<span className="font-bold">88px</span> by{" "}
													<span className="font-bold">88px</span>
												</Label>
												<Label
													variant="description"
													className="lg:text-[0.70rem]"
												>
													&bull; Image file size should be no more than{" "}
													<span className="font-bold">2mb</span>
												</Label>
												<Label
													variant="description"
													className="lg:text-[0.70rem]"
												>
													&bull; Allowed image formats are{" "}
													<span className="font-bold">.jpg or .jpeg</span> and{" "}
													<span className="font-bold">.png</span> only
												</Label>
											</div>
										</div>
									</div>

									<DialogFooter className="flex flex-row gap-2 w-fit mx-auto mt-2 md:mt-0">
										<Button
											size="sm"
											className="py-[2px] px-6 h-8"
											disabled={
												!isDirty ||
												!isValid ||
												isUpdateCategoryLoading ||
												isCreateCategoryLoading
											}
											onClick={_onSavePress}
										>
											{isUpdateCategoryLoading || isCreateCategoryLoading ? (
												<ButtonLoading />
											) : (
												<>Save</>
											)}
										</Button>
										<Button
											size="sm"
											variant="secondary"
											className="py-[2px] px-4 h-8"
											onClick={_onCancelPress}
											disabled={
												isUpdateCategoryLoading || isCreateCategoryLoading
											}
										>
											Cancel
										</Button>
									</DialogFooter>
								</>
							)}
						</DialogContent>
					</form>
					<ConfirmationDialog
						title="Leave page?"
						description="Changes are not yet saved."
						confirmButtonLabel="Leave"
						closeButtonLabel="Cancel"
						modalState={isLeaveModalOpen}
						_onCancel={() => setIsLeaveModalOpen(false)}
						_onConfirm={_onConfirmLeave}
					/>

					<ConfirmationDialog
						title="Save Changes?"
						description="Saving will apply all changes."
						confirmButtonLabel="Continue"
						closeButtonLabel="Cancel"
						modalState={isSaveModalOpen}
						_onCancel={() => setIsSaveModalOpen(false)}
						_onConfirm={_onConfirmSave}
						confirmButtonVariant="default"
					/>
				</div>
			</Form>
		</Dialog>
	);
};

export default CategoryFormModal;
