import { z } from "zod";

const SocialMediaSchema = z.object({
	status: z.boolean({
		required_error: "Please specify if the social media is selected.",
		invalid_type_error: "Selection status must be true or false.",
	}),
	link: z
		.string({
			required_error: "Please specify your social media link.",
		})
		.trim()
		.refine((val) => val === "" || z.string().url().safeParse(val).success, {
			message: "Please enter a valid social media link.",
		})
		.optional(),
});

const ContactSchema = z.object({
	email_address: z
		.string({
			required_error: "Please enter the primary email address.",
		})
		.min(2, {
			message: "Please enter a valid email address.",
		})
		.email({ message: "Please enter a valid email address." })
		.trim(),
	mobile_no: z.coerce
		.number({
			required_error: "Please enter the contact number.",
		})
		.refine((val) => val.toString().length === 10, {
			message: "Contact must be exactly 10 digits long.",
		}),
	caremail: z
		.string({
			required_error: "Please enter the caremail address.",
		})
		.email({
			message: "Please enter a valid caremail address.",
		})
		.trim(),
	main_office_address: z
		.string({
			required_error: "Please specify the office address.",
		})
		.min(5, {
			message: "Address must be at least 10 characters long.",
		})
		.max(200, {
			message: "Address must not exceed 200 characters.",
		})
		.trim(),
});

export const LibraryFormSchema = z
	.object({
		image: z.union([z.instanceof(File), z.string().url()]),
		content: z
			.string({
				required_error: "Please enter the AboutUs content.",
			})
			.min(10, {
				message: "Content must be at least 10 characters long.",
			})
			.max(5000, {
				message: "Content must not exceed 5000 characters.",
			})
			.trim(),
		contact_info: ContactSchema,
		social_media: z
			.array(SocialMediaSchema, {
				required_error: "Please provide at least one social media entry.",
			})
			.refine((data) => data.some((platform) => platform.status), {
				message:
					"Please provide at least one social media entry with isSelected: true.",
			}),
	})
	.strict();
