import { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

// Library
import { zodResolver } from "@hookform/resolvers/zod";

// Utils
import { isErrorResponse } from "src/shared/helpers/errorAssertion";
import { LoginCredentials, LoginFormSchema } from "src/redux/auth/schema";

// Components
import { Input } from "src/shared/components/ui/input";
import { Button } from "src/shared/components/ui/button";
import { Label } from "src/shared/components/ui/label";
import FormController from "src/shared/components/form/FormController";
import { Form, FormField, FormMessage } from "src/shared/components/form/Form";
import {
	Card,
	CardContent,
	CardDescription,
	CardFooter,
	CardHeader,
	CardTitle,
} from "src/shared/components/ui/card";

// Icons & Images
import AuthEmailIconSvg from "src/assets/svg/auth/AuthEmailIconSvg";
import AuthHideIconSvg from "src/assets/svg/auth/AuthHideIconSvg";
import AuthLockIconSvg from "src/assets/svg/auth/AuthLockIconSvg";
import AuthShowIconSvg from "src/assets/svg/auth/AuthShowIconSvg";
import diyLogo from "src/assets/images/mini_diy_logo.png";
import { LoaderCircle } from "lucide-react";
import { jwtDecode } from "jwt-decode";
import { CustomJwtPayload } from "src/redux/types";
import { useLoginMutation } from "src/redux/auth/apiSlice";
import { useAuth } from "src/providers/AuthProvider";

const LoginForm = () => {
	const navigate = useNavigate();
	const { setCredentials } = useAuth();
	const [_login, { isLoading }] = useLoginMutation();

	const [showPassword, setShowPassword] = useState(false);

	const form = useForm<LoginCredentials>({
		resolver: zodResolver(LoginFormSchema),
		defaultValues: {
			email: "",
			password: "",
		},
		mode: "onChange",
		criteriaMode: "all",
	});

	const togglePasswordVisibility = () => {
		setShowPassword((prevShowPassword: boolean) => !prevShowPassword);
	};

	const _onSubmit = async (values: LoginCredentials) => {
		const { email, password } = values;

		try {
			const response = await _login({ email, password });

			if (!response.error) {
				const { data } = response.data;
				const { id, firstname, lastname, image, token: tokens } = data;
				const { token, rolesToken } = tokens[0];
				const decodedPermissions = jwtDecode<CustomJwtPayload>(rolesToken);
				const { permissions } = decodedPermissions;

				localStorage.setItem("ACCESS_TOKEN", token);
				localStorage.setItem("PERMISSION_TOKEN", rolesToken);
				localStorage.setItem("USER_ID", String(id));
				localStorage.setItem("USER_ID", String(id));
				localStorage.setItem("FIRST_NAME", String(firstname));
				localStorage.setItem("LAST_NAME", String(lastname));
				localStorage.setItem("PROFILE", String(image));

				setCredentials(permissions, token);
				navigate("/banner");
			} else {
				if (isErrorResponse(response.error)) {
					const { data } = response.error;
					form.setError("password", { message: data.message });
				}
			}
		} catch (e) {
			console.log("Unexpected Error. ", e);
			// REDIRECT TO ERROR PAGE
		}
	};

	return (
		<Form {...form}>
			<form onSubmit={form.handleSubmit(_onSubmit)}>
				<Card className="w-[80%] max-w-[20em] sm:w-[20em] sm:max-w-[28em] lg:w-[23em] 2xl:w-[28em] rounded-3xl absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/4 border-none shadow-xl">
					<CardHeader className="pb-5 2xl:pb-8">
						<CardTitle className="relative mb-9 lg:mb-14 2xl:mb-20">
							<div className="absolute h-[6em] w-[6em] -top-24 left-1/2 -translate-x-1/2 overflow-hidden lg:w-[8em] lg:h-[8em] 2xl:w-[10em] 2xl:h-[10em] lg:-top-32 2xl:-top-36">
								<img
									src={diyLogo}
									alt="banner"
									className="w-full h-full object-fill"
								/>
							</div>
						</CardTitle>
						<CardDescription>
							<Label
								variant="sub_header"
								className="flex mx-auto w-fit text-secondary text-base font-semibold 2xl:text-2xl 2xl:my-2"
							>
								Login
							</Label>
						</CardDescription>
					</CardHeader>
					<CardContent>
						<div className="flex flex-col w-full items-center gap-4 2xl:gap-6">
							<div className="flex flex-col w-[90%]">
								<Label
									variant="form"
									className="flex flex-row gap-1 2xl:gap-2 items-center"
								>
									<AuthEmailIconSvg
										className="h-4 w-4 2xl:h-6 2xl:w-6"
										fill="#292D32"
									/>
									Email
								</Label>

								<FormField
									control={form.control}
									name="email"
									render={({ field }) => (
										<FormController>
											<Input {...field} />
										</FormController>
									)}
								/>
							</div>
							<div className="flex flex-col w-[90%]">
								<Label
									variant="form"
									className="flex flex-row gap-1 2xl:gap-2 items-center"
								>
									<AuthLockIconSvg
										className="h-4 w-4 2xl:h-6 2xl:w-6"
										fill="#292D32"
									/>
									Password
								</Label>
								<FormField
									control={form.control}
									name="password"
									render={({ field }) => (
										<FormController hasValidationMessage={false}>
											<>
												<div className="relative w-full">
													<Input
														className="pr-6 lg:pr-8 text-secondary"
														id="password"
														type={showPassword ? "" : "password"}
														{...field}
													/>
													<div onClick={togglePasswordVisibility}>
														{showPassword ? (
															<AuthHideIconSvg
																fill="#292D32"
																className="text-secondary absolute mb-1 right-1 bottom-0 transform w-4 h-4 2xl:h-6 2xl:w-6"
															/>
														) : (
															<AuthShowIconSvg
																fill="#292D32"
																className="text-secondary absolute mb-1 right-1 bottom-0 transform w-4 h-4 2xl:h-6 2xl:w-6"
															/>
														)}
													</div>
												</div>
												{form.formState.errors.password ? (
													<FormMessage>
														<>{form.formState.errors.password}</>
													</FormMessage>
												) : (
													<></>
												)}
											</>
										</FormController>
									)}
								/>
								<Button
									variant="link"
									size="icon"
									className="ml-auto mt-1 tracking-tight]"
									onClick={() => navigate("/auth/forgot-password")}
								>
									<Label variant="description" className="hover:cursor-pointer">
										Forgot Password
									</Label>
								</Button>
							</div>
						</div>
					</CardContent>
					<CardFooter className="flex justify-center mb-2 2xl:mt-2 2xl:mb-4">
						<Button
							size="sm"
							className="px-6 h-7 lg:h-9 lg:px-10 xl:w-[40%] shadow-lg rounded-full"
							type="submit"
							disabled={isLoading}
						>
							{isLoading ? (
								<>
									<LoaderCircle className="h-4 w-4 animate-spin" />
									Signing-in
								</>
							) : (
								"Sign-In"
							)}
						</Button>
					</CardFooter>
				</Card>
			</form>
		</Form>
	);
};

export default LoginForm;
