import { ReactNode, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import { useAuth } from "src/providers/AuthProvider";
import { setErrorState } from "src/redux/slices/errorSlice";
import { RootState } from "src/redux/store";

const ProtectedRoute = ({ children }: { children: ReactNode }) => {
	const token = localStorage.getItem("ACCESS_TOKEN");
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const errorState = useSelector((state: RootState) => state.error);

	useEffect(() => {
		if (errorState.status === 404) {
			// Handle 404 error state
			console.log("Not Found Error:", errorState.data);

			dispatch(setErrorState({ data: {}, status: 0 }));

			// ! Current solution to unlimited calls
			// navigate("/*");
		}
	}, [errorState]);

	if (!token) {
		return <Navigate to="/auth" replace />;
	}

	return <>{children}</>;
};

export default ProtectedRoute;
