import { useCallback, useEffect, useMemo, useState } from "react";
import { useGetProductsQuery } from "src/redux/product/apiSlice";
import { useProductColumns } from "src/modules/product/columns";
import { useNavigate } from "react-router-dom";
import { useGetCategoriesQuery } from "src/redux/category/apiSlice";

// Types

// Components
import { DataTable } from "src/shared/components/table/DataTable";
import { Label } from "src/shared/components/ui/label";
import { Button } from "src/shared/components/ui/button";
import {
	Select,
	SelectContent,
	SelectGroup,
	SelectItem,
	SelectLabel,
	SelectTrigger,
	SelectValue,
} from "src/shared/components/ui/select";
import PageControls from "src/shared/components/control/PageControls";
import PageContainer from "src/shared/components/layout/container/PageContainer";

// Icons
import ButtonAddIconSvg from "src/assets/svg/ButtonAddIconSvg";
import { Input } from "src/shared/components/ui/input";
import { Search, X } from "lucide-react";
import PreviewProductModal from "./PreviewProductModal";
import Loading from "src/shared/components/loading-indicator/Loading";
import { Product, ProductWithCategory } from "src/redux/product/types";
import { useToast } from "src/shared/hooks/useToast";
import { ToastClose } from "src/shared/components/ui/toast";

const BannerProductsList = () => {
	const { toast } = useToast();
	const navigate = useNavigate();
	const { data: productsArray = [], isFetching: isProductsFetching } =
		useGetProductsQuery();
	const { data: categoriesOption = [], isFetching: isCategoriesFetching } =
		useGetCategoriesQuery();
	const [searchValue, setSearchValue] = useState("");
	const [categorySelected, setCategorySelected] = useState("");
	const [isProductPreviewModalOpen, setIsProductPreviewModalOpen] =
		useState(false);
	const [selectedRows, setSelectedRows] = useState<Product[]>([]);
	const [selectedProduct, setSelectedProduct] = useState<ProductWithCategory>({
		id: 0,
		sku: "",
		images: [],
		color: "",
		name: "",
		categoryId: 0,
		categoryName: "",
		description: "",
		variations: 0,
		price: 0,
		discounted_price: 0,
		size: "",
	});

	const filteredProducts = useMemo(() => {
		let filteredProd: ProductWithCategory[] = [];

		if (Number(categorySelected) > 0) {
			filteredProd = productsArray.filter(
				(prod) => prod.categoryId == Number(categorySelected)
			);
		} else {
			filteredProd = [...productsArray];
		}

		return filteredProd.filter(
			(product) =>
				product.sku.toLowerCase().includes(searchValue.toLowerCase()) ||
				product.name.toLowerCase().includes(searchValue.toLowerCase()) ||
				product.categoryName
					.toLowerCase()
					.includes(searchValue.toLowerCase()) ||
				product.description.toLowerCase().includes(searchValue.toLowerCase())
		);
	}, [searchValue, productsArray, categorySelected]);

	const handleSelectionChange = useCallback((newSelectedRows: Product[]) => {
		setSelectedRows(newSelectedRows);
	}, []);

	const _onPreviewPress = (product: ProductWithCategory) => {
		setSelectedProduct(product);
		setIsProductPreviewModalOpen(true);
	};

	const _onCancelPress = () => {
		navigate(-1);
	};

	const columns = useProductColumns({ page: "Products List", _onPreviewPress });

	useEffect(() => {
		if (categorySelected == "0") {
			setCategorySelected("");
		}
	}, [categorySelected]);

	if (isProductsFetching || isCategoriesFetching) {
		return <Loading />;
	}

	return (
		<PageContainer>
			<div className="flex flex-col sm:flex-row sm:justify-between gap-4 mb-4 lg:mb-8">
				<Label variant="title">Add Products to Banner</Label>

				<div className="w-full h-fit sm:w-fit flex gap-2">
					<Button
						type="submit"
						disabled={selectedRows.length < 2}
						onClick={() => {
							toast({
								description: "Products has been added.",
								duration: 2000,
								variant: "success",
								action: (
									<ToastClose
										className="absolute top-1/2 -translate-y-1/2 right-5 text-white hover:text-gray-200 focus:text-gray-200"
										aria-label="Close"
									>
										<X className="w-5 h-5" />
									</ToastClose>
								),
							});

							setSelectedRows([]);
						}}
					>
						<ButtonAddIconSvg
							className="h-5 w-5"
							fill={`${selectedRows.length < 2 ? "#cbcbcc" : "#292d32"}`}
						/>
						Add Products
					</Button>
					<Button
						variant="secondary"
						size="md"
						type="reset"
						onClick={_onCancelPress}
					>
						Cancel
					</Button>
				</div>
			</div>

			<div className="flex mt-2 mb-10">
				<Label variant="header">Product List</Label>
			</div>

			<PageControls className="lg:mt-2">
				<div className="flex gap-2 w-full sm:w-[18em]">
					<Select
						onValueChange={setCategorySelected}
						defaultValue={String(categorySelected)}
					>
						<SelectTrigger
							className={`pl-4 ${
								!categorySelected ? "text-gray-300" : "text-secondary"
							}`}
						>
							<SelectValue placeholder="Select a category" />
						</SelectTrigger>
						<SelectContent>
							<SelectGroup>
								<SelectItem value="0">No Category</SelectItem>
								{categoriesOption.map((category) => {
									return (
										<SelectItem key={category.id} value={String(category.id)}>
											{category.name}
										</SelectItem>
									);
								})}
							</SelectGroup>
						</SelectContent>
					</Select>
				</div>
				<div className="w-full xl:w-fit flex flex-row-reverse sm:flex-row gap-6 ml-auto justify-end items-center">
					<div className="relative w-full row-start-1 sm:row-start-auto sm:w-[15em] sm:max-w-[15em] md:w-[25em] md:max-w-[25em]">
						<Search className="absolute left-0 bottom-0 transform -translate-y-1/2 w-[14px] h-[14px] md:w-4 md:h-4 text-secondary" />
						<Input
							placeholder="Search"
							className="pl-6 lg:pl-8 text-[0.65rem] sm:text-sm"
							onChange={(e) => setSearchValue(e.target.value)}
						/>
					</div>
				</div>
			</PageControls>

			<div className="mt-8">
				<DataTable
					data={filteredProducts}
					columns={columns}
					emptyTableText="No Product has been added"
					onSelectionChange={handleSelectionChange}
					hasPagination={filteredProducts.length > 10}
				/>
			</div>

			<PreviewProductModal
				product={selectedProduct}
				modalState={isProductPreviewModalOpen}
				modalTrigger={() =>
					setIsProductPreviewModalOpen(!isProductPreviewModalOpen)
				}
			/>
		</PageContainer>
	);
};

export default BannerProductsList;
