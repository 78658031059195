import { z } from "zod";

export const CustomerFormSchema = z
	.object({
		firstname: z
			.string({
				required_error: "Please enter your first name.",
				invalid_type_error: "First name cannot contain numbers or symbols.",
			})
			.min(2, {
				message: "First name must be at least 2 characters.",
			})
			.max(30, {
				message: "First name must not exceed 20 characters.",
			})
			.regex(/^[A-Za-z.'\s-]+$/, {
				message: "First name cannot contain numbers or symbols.",
			})
			.trim(),
		lastname: z
			.string({
				required_error: "Please enter your last name.",
				invalid_type_error: "Last name cannot contain numbers or symbols.",
			})
			.min(2, {
				message: "Last name must be at least 2 characters.",
			})
			.max(25, {
				message: "Last name must not exceed 25 characters.",
			})
			.regex(/^[A-Za-z.'-]+$/, {
				message: "Last name cannot contain numbers or symbols.",
			})
			.trim(),
		email: z
			.string({
				required_error: "Please enter your email.",
			})
			.min(2, {
				message: "Please enter a valid email address.",
			})
			.email({ message: "Please enter a valid email address." }),
		mobile_no: z.coerce
			.string({
				required_error: "Please enter your mobile number.",
			})
			.refine((val) => val === "" || val.toString().length === 10, {
				message: "Mobile number must be exactly 10 digits long.",
			}),
		landline: z.coerce
			.string()
			.refine((val) => val === "" || val.toString().length <= 11, {
				message:
					"Landline number must be lower than or equal to 11 digits long.",
			})
			// .refine((value) => value === "" || /^\d{3}-\d{3}-\d{4}$/.test(value), {
			// 	message:
			// 		"Please input a valid landline number in the format 111-222-3333.",
			// })
			.refine((value) => value === "" || /^[\d-]+$/.test(value), {
				message: "Please input a valid landline number using only numbers.",
			})
			.optional(),
	})
	.strict();
