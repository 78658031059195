import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { useGetRolesQuery } from "../../redux/role/apiSlice";
import {
	useGetSelectedUserQuery,
	useUpdateUserMutation,
} from "src/redux/user/apiSlice";

// Libraries
import { zodResolver } from "@hookform/resolvers/zod";

// Utils
import { User } from "src/redux/user/types";
import { UserFormSchema } from "src/redux/user/schema";

// Component
import { Button } from "src/shared/components/ui/button";
import { Input } from "src/shared/components/ui/input";
import { Label } from "src/shared/components/ui/label";
import {
	Select,
	SelectContent,
	SelectGroup,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from "src/shared/components/ui/select";
import {
	RadioGroup,
	RadioGroupItem,
} from "src/shared/components/ui/radio-group";
import { Form, FormField } from "src/shared/components/form/Form";
import PageContainer from "src/shared/components/layout/container/PageContainer";
import SectionContainer from "src/shared/components/layout/container/SectionContainer";
import SectionHeader from "src/shared/components/layout/header/SectionHeader";
import ButtonCheckIconSvg from "src/assets/svg/ButtonCheckIconSvg";
import FormHeader from "src/shared/components/layout/header/FormHeader";
import FormController from "src/shared/components/form/FormController";
import ConfirmationDialog from "src/shared/components/dialog/ConfirmationDialog";
import Loading from "src/shared/components/loading-indicator/Loading";
import ButtonLoading from "src/shared/components/loading-indicator/ButtonLoading";
import NotificationDialog from "src/shared/components/dialog/NotificationDialog";

const UserForm = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const pathSegments = location.pathname.split("/");
	const { userId } = useParams<{ userId: string }>();
	const { data: roles, isFetching: isFetchingRoles } = useGetRolesQuery();
	const { data: selectedUser, isFetching: isSelectedUserFetching } =
		useGetSelectedUserQuery(Number(userId));

	const [_updateUser] = useUpdateUserMutation();
	const [showLoading, setShowLoading] = useState(false);

	const [isLeaveModalOpen, setIsLeaveModalOpen] = useState(false);
	const [isSaveModalOpen, setIsSaveModalOpen] = useState(false);
	const [isNotifModalOpen, setIsNotifModalOpen] = useState(false);

	const form = useForm<User>({
		resolver: zodResolver(UserFormSchema),
		defaultValues: {
			firstName: "",
			lastName: "",
			email: "",
			roleId: 0,
			status: 0,
		},
		mode: "onChange",
		reValidateMode: "onSubmit",
		criteriaMode: "all",
	});

	const { isDirty, isValid } = form.formState;

	const _onConfirmLeave = () => {
		navigate("/user");

		form.reset();
	};

	const _onCancelPress = () => {
		if (!isDirty) navigate("/user");

		setIsLeaveModalOpen(true);
	};

	const _onSavePress = () => {
		setIsSaveModalOpen(true);
	};

	const _onConfirmSave = () => {
		_onSubmit(form.getValues());
	};

	const _onSubmit = (values: User) => {
		if (values) {
			setShowLoading(true);
			setTimeout(async () => {
				// await _updateUser(values);

				setShowLoading(false);
				setIsNotifModalOpen(true);
			}, 2000);
			setIsSaveModalOpen(false);
		}
	};

	const _onNotifOkayPress = () => {
		setIsNotifModalOpen(false);
		navigate("/user");
	};

	useEffect(() => {
		if (selectedUser) {
			form.reset(selectedUser, {
				keepDirty: false,
				keepTouched: false,
				keepIsValid: false,
				keepErrors: false,
			});
		}
	}, [selectedUser, form]);

	if (isSelectedUserFetching || isFetchingRoles) {
		return <Loading />;
	}

	if (!isSelectedUserFetching && !selectedUser) {
		if (pathSegments[2] != "add") {
			navigate("/user", { replace: true });
		}
	}

	return (
		<Form {...form}>
			<form onSubmit={form.handleSubmit(_onSubmit)}>
				<PageContainer>
					<FormHeader>
						<Label variant="title">User Management</Label>
						<div className="w-full h-fit sm:w-fit flex gap-2">
							<Button
								disabled={!isDirty || !isValid || showLoading}
								onClick={_onSavePress}
							>
								{showLoading ? (
									<ButtonLoading />
								) : (
									<>
										<ButtonCheckIconSvg
											className="h-5 w-5"
											fill={!isDirty || !isValid ? "#cbcbcc" : "#292D32"}
										/>
										Save
									</>
								)}
							</Button>
							<Button
								variant="secondary"
								size="md"
								type="reset"
								onClick={_onCancelPress}
								disabled={showLoading}
							>
								Cancel
							</Button>
						</div>
					</FormHeader>
					<SectionContainer className="lg:grid-cols-3">
						<SectionHeader className="lg:col-span-3">
							<div className="flex flex-col space-y-0 lg:gap-2">
								<Label variant="header">
									{!selectedUser?.id
										? "Add User Information"
										: "Edit User Information"}
								</Label>
								{selectedUser?.id && (
									<Label variant="description">
										Last edited on <span className="font-bold">15/08/2022</span>{" "}
										at <span className="font-bold">16:40:51</span>
									</Label>
								)}
							</div>
						</SectionHeader>

						<FormField
							control={form.control}
							name="firstName"
							render={({ field }) => (
								<FormController label="First Name" required>
									<Input {...field} />
								</FormController>
							)}
						/>
						<FormField
							control={form.control}
							name="lastName"
							render={({ field }) => (
								<FormController label="Last Name" required>
									<Input {...field} />
								</FormController>
							)}
						/>
						<FormField
							control={form.control}
							name="email"
							render={({ field }) => (
								<FormController label="Email" required>
									<Input {...field} />
								</FormController>
							)}
						/>
						<FormField
							control={form.control}
							name="roleId"
							render={({ field }) => (
								<FormController label="Role" required>
									<Select
										onValueChange={(value) => field.onChange(Number(value))}
										value={String(field.value)}
									>
										<SelectTrigger>
											<SelectValue placeholder="Default Option" />
										</SelectTrigger>
										<SelectContent>
											<SelectGroup>
												<SelectItem value="0" disabled>
													Default Option
												</SelectItem>
												{roles?.map((role) => {
													return (
														<SelectItem value={String(role.id)}>
															{role.name}
														</SelectItem>
													);
												})}
											</SelectGroup>
										</SelectContent>
									</Select>
								</FormController>
							)}
						/>
						<FormField
							control={form.control}
							name="status"
							render={({ field }) => (
								<FormController label="Status" required>
									<RadioGroup
										onValueChange={(value) => field.onChange(Number(value))}
										value={String(field.value)}
										className="flex gap-4 mt-2"
									>
										<div className="flex items-center space-x-2">
											<RadioGroupItem value="1" id="active" />
											<Label
												htmlFor="active"
												variant="form"
												className="text-secondary"
											>
												Active
											</Label>
										</div>
										<div className="flex items-center space-x-2">
											<RadioGroupItem value="0" id="inactive" />
											<Label
												htmlFor="inactive"
												variant="form"
												className="text-secondary"
											>
												Inactive
											</Label>
										</div>
									</RadioGroup>
								</FormController>
							)}
						/>
					</SectionContainer>
				</PageContainer>
			</form>
			<ConfirmationDialog
				title="Leave page?"
				description="Changes are not yet saved."
				confirmButtonLabel="Leave"
				closeButtonLabel="Cancel"
				modalState={isLeaveModalOpen}
				_onCancel={() => setIsLeaveModalOpen(false)}
				_onConfirm={_onConfirmLeave}
			/>

			<ConfirmationDialog
				title="Save Changes?"
				description="Saving will apply all changes."
				confirmButtonLabel="Continue"
				closeButtonLabel="Cancel"
				modalState={isSaveModalOpen}
				_onCancel={() => setIsSaveModalOpen(false)}
				_onConfirm={_onConfirmSave}
				confirmButtonVariant="default"
			/>

			<NotificationDialog
				description={
					!userId
						? "User has been added successfully!"
						: "User has been updated successfully!"
				}
				confirmButtonLabel="Ok"
				modalState={isNotifModalOpen}
				_onConfirm={_onNotifOkayPress}
			/>
		</Form>
	);
};

export default UserForm;
