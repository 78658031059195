import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useForm, useWatch } from "react-hook-form";
import { useEffect, useState } from "react";
import {
	useGetActiveBannersQuery,
	useGetSelectedBannerQuery,
} from "../../redux/banner/apiSlice";
import { useGetCategoriesQuery } from "src/redux/category/apiSlice";
import { useGetProductsQuery } from "src/redux/product/apiSlice";

// Libraries
import { zodResolver } from "@hookform/resolvers/zod";

// Utils
import { Banner } from "src/redux/banner/types";
import { BannerFormSchema } from "src/redux/banner/schema";

// Component
import { Button } from "src/shared/components/ui/button";
import { Label } from "src/shared/components/ui/label";

import { Form } from "src/shared/components/form/Form";
import PageContainer from "src/shared/components/layout/container/PageContainer";
import FormHeader from "src/shared/components/layout/header/FormHeader";
import ConfirmationDialog from "src/shared/components/dialog/ConfirmationDialog";
import ProductListSection from "./fragments/ProductListSection";
import Loading from "src/shared/components/loading-indicator/Loading";
import ButtonLoading from "src/shared/components/loading-indicator/ButtonLoading";

// Icons
import ButtonCheckIconSvg from "src/assets/svg/ButtonCheckIconSvg";
import BannerInformationSection from "./fragments/BannerInformationSection";
import PromoDurationSection from "./fragments/PromoDurationSection";
import ArrowRightIconSvg from "src/assets/svg/ArrowRightIconSvg";
import NotificationDialog from "src/shared/components/dialog/NotificationDialog";

const BannerForm = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const pathSegments = location.pathname.split("/");
	const { bannerId } = useParams<{ bannerId: string }>();

	const { data: selectedBanner, isFetching: isSelectedBannerFetching } =
		useGetSelectedBannerQuery({ id: Number(bannerId) });
	const { data: banners = [], isFetching: isActiveBannersFetching } =
		useGetActiveBannersQuery();
	const { data: categoriesOption = [], isFetching: isCategoriesFetching } =
		useGetCategoriesQuery();
	const { data: productsArray = [], isFetching: isProductsArrayFetching } =
		useGetProductsQuery();

	const [activeBanners, setActiveBanners] = useState(banners);
	const [isActiveBanner, setIsActiveBanner] = useState(
		!!selectedBanner?.status
	);

	// ! Remove me on API Integration and use isLoading by rtk query
	const [showLoading, setShowLoading] = useState(false);
	const [isLeaveModalOpen, setIsLeaveModalOpen] = useState(false);
	const [isSaveModalOpen, setIsSaveModalOpen] = useState(false);
	const [isNotifModalOpen, setIsNotifModalOpen] = useState(false);

	const form = useForm<Banner>({
		resolver: zodResolver(BannerFormSchema),
		defaultValues: {
			id: 0,
			title: "",
			image: "",
			description: "",
			products: 0,
			from: "",
			to: "",
			order: 0,
			categoryId: 0,
			status: 0,
		},
		mode: "onChange",
		reValidateMode: "onSubmit",
		criteriaMode: "all",
	});
	const { isDirty, isValid } = form.formState;

	const status = useWatch({
		control: form.control,
		name: "status",
	});

	const dates = useWatch({
		control: form.control,
		name: ["from", "to"],
	});

	const _navigateToAddProducts = () => {
		if (!bannerId) {
			navigate("products");
		} else {
			navigate("add/products");
		}
	};

	const _onConfirmLeave = () => {
		navigate("/banner");

		form.reset();
	};

	const _onCancelPress = () => {
		if (!isDirty) navigate("/banner");

		setIsLeaveModalOpen(true);
	};

	const _onSavePress = () => {
		setIsSaveModalOpen(true);
	};

	const _onConfirmSave = () => {
		_onSubmit(form.getValues());
	};

	const _onSubmit = (values: Banner) => {
		if (values) {
			setShowLoading(true);
			setTimeout(async () => {
				setShowLoading(false);
				setIsNotifModalOpen(true);
			}, 2000);
			setIsSaveModalOpen(false);
		}
	};

	const _onNotifOkayPress = () => {
		setIsNotifModalOpen(false);
		navigate("/banner");
	};

	useEffect(() => {
		if (selectedBanner) {
			form.reset(selectedBanner, {
				keepDirty: false,
				keepTouched: false,
				keepIsValid: false,
				keepErrors: false,
			});

			if (selectedBanner.status === 1) {
				setIsActiveBanner(true);
			}
		}
	}, [selectedBanner, form]);

	useEffect(() => {
		if (selectedBanner) {
			if (isActiveBanner) {
				if (status === 0) {
					setActiveBanners((prevBanners) => prevBanners.slice(0, -1));
				} else {
					setActiveBanners(banners);
				}
			}
		}
	}, [status]);

	useEffect(() => {
		if (isDirty) {
			form.trigger();
		}
	}, [dates]);

	if (
		isSelectedBannerFetching ||
		isCategoriesFetching ||
		isActiveBannersFetching ||
		isProductsArrayFetching
	) {
		return <Loading />;
	}

	console.log(selectedBanner);

	if (!isSelectedBannerFetching && !selectedBanner) {
		if (pathSegments[2] != "add") {
			navigate("/banner", { replace: true });
		}
	}

	return (
		<Form {...form}>
			<div className="h-auto relative">
				<form onSubmit={form.handleSubmit(_onSubmit)}>
					<PageContainer>
						<FormHeader>
							<Label variant="title">
								{!selectedBanner?.id ? "New Promo Banner" : "Edit Promo Banner"}
							</Label>
							<div className="w-full h-fit sm:w-fit flex gap-2">
								<Button
									disabled={!isDirty || !isValid || showLoading}
									onClick={_onSavePress}
								>
									{showLoading ? (
										<ButtonLoading />
									) : (
										<>
											<ButtonCheckIconSvg
												className="h-5 w-5"
												fill={!isDirty || !isValid ? "#cbcbcc" : "#292D32"}
											/>
											Save
										</>
									)}
								</Button>
								<Button
									variant="secondary"
									size="md"
									type="reset"
									onClick={_onCancelPress}
									disabled={showLoading}
								>
									Cancel
								</Button>
							</div>
						</FormHeader>
						<div className="grid gap-4 xl:gap-6 w-full h-fit xl:h-full">
							<div className="flex flex-col gap-4 w-full my-1 justify-start items-start sm:flex-row sm:my-4 sm:justify-between sm:items-center lg:my-6">
								<Label variant="header">Banner Information</Label>
								<Button size="md" onClick={_navigateToAddProducts}>
									Add Products
									<ArrowRightIconSvg className="h-5 w-5" fill="#292D32" />
								</Button>
							</div>
							<BannerInformationSection
								form={form}
								categoriesOption={categoriesOption}
							/>
							<PromoDurationSection
								form={form}
								noOfActiveBanners={activeBanners.length || 0}
							/>
						</div>

						<ProductListSection
							productsArray={productsArray}
							pathSegments={pathSegments}
						/>
					</PageContainer>

					<ConfirmationDialog
						title="Leave page?"
						description="Changes are not yet saved."
						confirmButtonLabel="Leave"
						closeButtonLabel="Cancel"
						modalState={isLeaveModalOpen}
						_onCancel={() => setIsLeaveModalOpen(false)}
						_onConfirm={_onConfirmLeave}
					/>

					<ConfirmationDialog
						title="Save Changes?"
						description="Saving will apply all changes."
						confirmButtonLabel="Continue"
						closeButtonLabel="Cancel"
						modalState={isSaveModalOpen}
						_onCancel={() => setIsSaveModalOpen(false)}
						_onConfirm={_onConfirmSave}
						confirmButtonVariant="default"
					/>

					<NotificationDialog
						description={
							!bannerId
								? "Banner has been added successfully!"
								: "Banner has been updated successfully!"
						}
						confirmButtonLabel="Ok"
						modalState={isNotifModalOpen}
						_onConfirm={_onNotifOkayPress}
					/>
				</form>
			</div>
		</Form>
	);
};

export default BannerForm;
