import { createSlice } from "@reduxjs/toolkit";

type ErrorState = {
	data: any;
	status: number;
};

const initialState: ErrorState = {
	data: {},
	status: 0,
};

const errorSlice = createSlice({
	name: "error",
	initialState,
	reducers: {
		setErrorState: (state, action) => {
			state.data = action.payload.data;
			state.status = action.payload.status;
		},
	},
});

export const { setErrorState } = errorSlice.actions;
export default errorSlice.reducer;
