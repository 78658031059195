// Types
import { ColumnDef } from "@tanstack/react-table";
import { InvalidStore } from "src/redux/store/types";

// Icons
import { FaSort } from "react-icons/fa";

type StoreColumnProps = (id: number, name: string) => void;

export const useInvalidStoreDataColumns = (
	_onOpenDeleteModal?: StoreColumnProps
): ColumnDef<InvalidStore>[] => {
	const columns: ColumnDef<InvalidStore>[] = [
		{
			accessorKey: "reason",
			header: () => <p className="font-bold">Reasons</p>,
			cell: ({ row }) => {
				return (
					<div className="w-[12ch] text-red-500 overflow-hidden text-ellipsis line-clamp-3 max-h-[4em]">
						{row.getValue("reason")}
					</div>
				);
			},
		},
		{
			accessorKey: "store_code",
			header: ({ column }) => (
				<button
					type="button"
					className="flex justify-between w-fit"
					onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
				>
					<FaSort className="h-3 w-3 group-hover:cursor-pointer text-gray-400 my-auto mr-1" />
					<p className="font-bold">
						Store
						<br />
						Code
					</p>
				</button>
			),
			cell: ({ row }) => {
				const storeCode: string = row.getValue("store_code");
				return (
					<div
						className="w-[6ch] overflow-hidden text-ellipsis whitespace-nowrap text-center"
						title={storeCode}
					>
						{row.getValue("store_code")}
					</div>
				);
			},
		},
		{
			accessorKey: "system_name",
			header: ({ column }) => (
				<button
					type="button"
					className="flex justify-between w-fit"
					onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
				>
					<FaSort className="h-3 w-3 group-hover:cursor-pointer text-gray-400 my-auto mr-1" />
					<p className="font-bold">
						System
						<br />
						Name
					</p>
				</button>
			),
			cell: ({ row }) => {
				const systemName: string = row.getValue("system_name");
				return (
					<div
						className="w-[6ch] overflow-hidden text-ellipsis whitespace-nowrap text-center"
						title={systemName}
					>
						{row.getValue("system_name")}
					</div>
				);
			},
		},
		{
			accessorKey: "name",
			header: ({ column }) => (
				<button
					type="button"
					className="flex justify-between w-fit"
					onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
				>
					<FaSort className="h-3 w-3 group-hover:cursor-pointer text-gray-400 my-auto mr-1" />
					<p className="font-bold">Name</p>
				</button>
			),
			cell: ({ row }) => {
				const storeName: string = row.getValue("name");
				return (
					<div
						className="w-[10ch] overflow-hidden text-ellipsis whitespace-nowrap text-center"
						title={storeName}
					>
						{row.getValue("name")}
					</div>
				);
			},
		},
		{
			accessorKey: "address",
			header: ({ column }) => (
				<button
					type="button"
					className="flex justify-between w-fit"
					onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
				>
					<FaSort className="h-3 w-3 group-hover:cursor-pointer text-gray-400 my-auto mr-1" />
					<p className="font-bold">Address</p>
				</button>
			),
			cell: ({ row }) => {
				return (
					<div className="w-[6em] overflow-hidden text-ellipsis line-clamp-2">
						{row.getValue("address")}
					</div>
				);
			},
		},
		{
			accessorKey: "postal_code",
			header: ({ column }) => (
				<button
					type="button"
					className="flex justify-between w-fit"
					onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
				>
					<FaSort className="h-3 w-3 group-hover:cursor-pointer text-gray-400 my-auto mr-1" />
					<p className="font-bold">Code</p>
				</button>
			),
			cell: ({ row }) => {
				return (
					<div className="w-[5ch] overflow-hidden text-ellipsis whitespace-nowrap">
						{row.getValue("postal_code")}
					</div>
				);
			},
		},
		{
			accessorKey: "city",
			header: ({ column }) => (
				<button
					type="button"
					className="flex justify-between w-fit"
					onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
				>
					<FaSort className="h-3 w-3 group-hover:cursor-pointer text-gray-400 my-auto mr-1" />
					<p className="font-bold">City</p>
				</button>
			),
		},
		{
			accessorKey: "opening_hrs",
			header: ({ column }) => <p className="font-bold w-fit">Opening</p>,
			cell: ({ row }) => {
				return (
					<div className="w-[4ch] overflow-hidden text-ellipsis whitespace-nowrap">
						{row.getValue("opening_hrs")}
					</div>
				);
			},
		},
		{
			accessorKey: "landline_no",
			header: () => <p className="font-bold w-fit">Landline</p>,
			cell: ({ row }) => {
				return (
					<div className="w-[4ch] overflow-hidden text-ellipsis whitespace-nowrap">
						{row.getValue("landline_no")}
					</div>
				);
			},
		},
		{
			accessorKey: "latitude",
			header: () => <p className="font-bold">Lat</p>,
			cell: ({ row }) => {
				return (
					<div className="w-[3ch] overflow-hidden text-ellipsis whitespace-nowrap max-w-24">
						{row.getValue("latitude")}
					</div>
				);
			},
		},
		{
			accessorKey: "longitude",
			header: () => <p className="font-bold">Long</p>,
			cell: ({ row }) => {
				return (
					<div className="w-[3ch] overflow-hidden text-ellipsis whitespace-nowrap max-w-24">
						{row.getValue("longitude")}
					</div>
				);
			},
		},
	];

	return columns;
};
