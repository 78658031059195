import { useNavigate } from "react-router-dom";

// Components
import { Button } from "src/shared/components/ui/button";
import {
	Dialog,
	DialogClose,
	DialogContent,
	DialogFooter,
	DialogHeader,
} from "src/shared/components/ui/dialog";
import { Input } from "src/shared/components/ui/input";
import { Label } from "src/shared/components/ui/label";

// Image
import product1 from "src/assets/images/product1.png";
import product2 from "src/assets/images/product2.png";
import { ProductWithCategory } from "src/redux/product/types";
import { Category, CategoryFilter } from "src/redux/category/types";
import { Checkbox } from "src/shared/components/ui/checkbox";
import { X } from "lucide-react";
import { useEffect, useState } from "react";

type FilterByCategoriesModalProps = {
	categories: CategoryFilter[];
	modalState: boolean;
	modalTrigger: () => void;
	setCategories: React.Dispatch<React.SetStateAction<CategoryFilter[]>>;
};

const FilterByCategoriesModal = ({
	categories = [],
	modalState,
	modalTrigger,
	setCategories,
}: FilterByCategoriesModalProps) => {
	const navigate = useNavigate();

	const [categorySelected, setCategorySelected] = useState<CategoryFilter[]>(
		[]
	);

	useEffect(() => {
		if (categories.length !== 0) {
			setCategorySelected(categories);
		}
	}, [categories]);

	return (
		<Dialog open={modalState} onOpenChange={modalTrigger}>
			<DialogContent className="rounded-xl space-y-0 pt-2 w-[80%] h-fit xl:space-y-4 max-h-[40em] max-w-[35em] sm:w-[80%]">
				<DialogHeader className="mt-4 xl:h-1">
					<Label className="w-full text-center xl:text-lg">
						Filter by Categories
					</Label>

					<DialogClose className="absolute top-2 right-2">
						<X className="h-4 w-4  opacity-60 hover:opacity-100 transition-opacity" />
					</DialogClose>
				</DialogHeader>

				<div className="w-full h-full flex flex-wrap gap-2 shadow-none overflow-auto px-1 pt-2 pb-4 max-h-[20em] border-b-[1px] border-gray-300">
					{categorySelected.map((category) => {
						return (
							<Button
								className="w-fit rounded-full"
								variant={category.checked ? "default" : "filter"}
								key={category.id}
								size="sm"
								onClick={(e) => {
									e.preventDefault();
									e.stopPropagation();
									setCategorySelected((prevCategories) =>
										prevCategories.map((ctg) =>
											ctg.id === category.id
												? { ...ctg, checked: !ctg.checked }
												: ctg
										)
									);
								}}
							>
								<Checkbox
									id={`checkbox-${category.id}`}
									checked={category.checked}
									onCheckedChange={(value: boolean) => {}}
									className="w-4 h-4 rounded-full bg-white border-[1px] border-secondary data-[state=checked]:text-secondary data-[state=checked]:border-secondary data-[state=checked]:border-[2px] data-[state=checked]:bg-white"
								/>
								<Label className="leading-none tracking-tight text-sm font-base hover:cursor-pointer">
									{category.name}
								</Label>
							</Button>
						);
					})}
				</div>

				<DialogFooter className="flex flex-row gap-2 w-fit mx-auto">
					<Button
						size="sm"
						className="py-[2px] px-12 h-8 mt-auto"
						onClick={() => {
							setCategories(categorySelected);
							modalTrigger();
						}}
					>
						Filter
					</Button>
					<Button
						size="sm"
						variant="secondary"
						className="py-[2px] px-4 h-8 mt-auto"
						onClick={() => {
							setCategories([...categories]);
							modalTrigger();
						}}
					>
						Cancel
					</Button>
				</DialogFooter>
			</DialogContent>
		</Dialog>
	);
};

export default FilterByCategoriesModal;
