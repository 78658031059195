// // import "./styles/reset-password.css";
// // import Styled from "styled-components";
// // import { useState } from "react";
// // import { useNavigate } from "react-router-dom";

// // // TODO SHOULD BE REMOVED, USED FROM MUI
// // import { Stack } from "@mui/material";
// // // import { Toaster } from "../../shared/components/toaster/Toaster";
// // import Buttons from "../../shared/components/form-field/button/Buttons";
// // import TextBoxPassword from "../../shared/components/form-field/textbox/TextBoxPassword";
// // import AppModalNotifications from "../../shared/components/form-field/modal/ModalNotifcation";

// // // Icons & Images
// // import { LockFilled, LeftCircleOutlined } from "@ant-design/icons";
// // import DIYLogo from "../../assets/images/diy_main_logo.png";

// // const ResetPassword = () => {
// //   const navigate = useNavigate();
// //   const [newPassword, setNewPassword] = useState("");
// //   const [confirmPassword, setConfirmPassword] = useState("");
// //   const [newPasswordError, setNewPasswordError] = useState("");
// //   const [confirmPasswordError, setConfirmPasswordError] = useState("");
// //   const [modalOpen, setModelOpen] = useState(false);

// //   // TODO: Refactor: use zod or yup for form validation
// //   const handlePasswordValidation = () => {
// //     let isValid = true;

// //     // Check if new password is empty
// //     const minLength = 8;
// //     const hasUppercase = /[A-Z]/.test(newPassword);
// //     const hasLowercase = /[a-z]/.test(newPassword);
// //     const hasDigit = /\d/.test(newPassword);
// //     const hasSpecialChar = /[@$!%*?&]/.test(newPassword);

// //     if (newPassword === "") {
// //       setNewPasswordError("New password is required");
// //       Toaster("Transaction Invalid", "error");
// //       isValid = false;
// //     } else if (newPassword.length < minLength) {
// //       setNewPasswordError("Password must be at least 8 characters long");
// //       Toaster("Transaction Invalid", "error");
// //       isValid = false;
// //     } else if (!hasUppercase) {
// //       setNewPasswordError(
// //         "Password must include at least one uppercase letter"
// //       );
// //       Toaster("Transaction Invalid", "error");
// //       isValid = false;
// //     } else if (!hasLowercase) {
// //       setNewPasswordError(
// //         "Password must include at least one lowercase letter"
// //       );
// //       Toaster("Transaction Invalid", "error");
// //       isValid = false;
// //     } else if (!hasDigit) {
// //       setNewPasswordError("Password must include at least one number");
// //       Toaster("Transaction Invalid", "error");
// //       isValid = false;
// //     } else if (!hasSpecialChar) {
// //       setNewPasswordError(
// //         "Password must include at least one special character"
// //       );
// //       Toaster("Transaction Invalid", "error");
// //       isValid = false;
// //     } else {
// //       setNewPasswordError("");
// //     }
// //     // Check if confirm password is empty
// //     if (confirmPassword === "") {
// //       setConfirmPasswordError("Confirm password is required");

// //       isValid = false;
// //     } else if (newPassword !== confirmPassword) {
// //       setConfirmPasswordError("Passwords do not match");

// //       isValid = false;
// //     } else {
// //       setConfirmPasswordError("");
// //     }
// //     if (isValid) {
// //       setModelOpen(true);
// //       // Toaster(
// //       //   'Please go back to login',
// //       //   'success'
// //       // );
// //     }
// //   };

// //   const StyledLink = Styled.a`
// //     color: black;
// //     text-decoration: none;
// //     &:hover {
// //       color: #none;
// //     }`;

// //   return (
// //     <>
// //       <section className="ResetPassword">
// //         <div className="reset-password-container">
// //           <div className="login-logo">
// //             <Stack mb={4}>
// //               <img
// //                 src={DIYLogo}
// //                 alt="diylogo"
// //                 style={{ alignSelf: "center", width: "210px" }}
// //               />
// //             </Stack>
// //           </div>
// //           <div className="reset-password-content">
// //             <div className="reset-logo"></div>
// //             <div className="reset-form">
// //               <h2 className="forgot-title">
// //                 <StyledLink href={`/auth`}>
// //                   <LeftCircleOutlined />
// //                 </StyledLink>{" "}
// //                 Reset Password
// //               </h2>
// //               <TextBoxPassword
// //                 placeholder="New Password"
// //                 prefix={<LockFilled />}
// //                 onChange={(e) => setNewPassword(e.target.value)}
// //                 errorMessage={newPasswordError}
// //               />
// //               <br />
// //               <TextBoxPassword
// //                 placeholder="Confirm Password"
// //                 prefix={<LockFilled />}
// //                 onChange={(e) => setConfirmPassword(e.target.value)}
// //                 errorMessage={confirmPasswordError}
// //               />
// //               <AppModalNotifications
// //                 title="Password Reset Successful"
// //                 contents="You will be redirected to the login page."
// //                 isModalOpen={modalOpen}
// //                 handleOk={() => setModelOpen(false)}
// //                 onCancel={() => navigate(`/auth`, { replace: true })}
// //               />
// //               <br />
// //               <Buttons
// //                 onClick={handlePasswordValidation}
// //                 title="Submit"
// //                 disabled={newPassword && confirmPassword ? false : true}
// //               />
// //             </div>
// //           </div>
// //         </div>
// //       </section>
// //     </>
// //   );
// // };

// // export default ResetPassword;

import React from "react";

const ResetPassword = () => {
	return <div>reset pass</div>;
};

export default ResetPassword;
