import {
	BaseQueryFn,
	FetchArgs,
	fetchBaseQuery,
	FetchBaseQueryError,
} from "@reduxjs/toolkit/query/react";
import { isErrorResult } from "./errorAssertion";

// Type guard functions
function isHttpError(
	error: unknown
): error is Extract<FetchBaseQueryError, { status: number }> {
	return (
		(error as FetchBaseQueryError).status !== undefined &&
		typeof (error as FetchBaseQueryError).status === "number"
	);
}

function isFetchError(
	error: unknown
): error is Extract<FetchBaseQueryError, { status: "FETCH_ERROR" }> {
	return (error as FetchBaseQueryError).status === "FETCH_ERROR";
}

function isParsingError(
	error: unknown
): error is Extract<FetchBaseQueryError, { status: "PARSING_ERROR" }> {
	return (error as FetchBaseQueryError).status === "PARSING_ERROR";
}

function isCustomError(
	error: unknown
): error is Extract<FetchBaseQueryError, { status: "CUSTOM_ERROR" }> {
	return (error as FetchBaseQueryError).status === "CUSTOM_ERROR";
}

export const baseQueryWithReauth: BaseQueryFn<
	string | FetchArgs,
	unknown,
	FetchBaseQueryError
> = async (args, api, extraOptions) => {
	const baseQuery = fetchBaseQuery({
		baseUrl: process.env.REACT_APP_API_URL,
		credentials: "include",
		prepareHeaders: (headers, { getState }) => {
			const token = localStorage.getItem("ACCESS_TOKEN");

			if (token) {
				headers.set("Authorization", `Bearer ${token}`);
			}

			return headers;
		},
	});

	try {
		// First attempt to fetch
		const result = await baseQuery(args, api, extraOptions);

		console.log("Interceptor result:", result);
		console.log("Args:", args);

		if (isErrorResult(result)) {
			if (result.error.status === 401) {
				localStorage.clear();
				window.location.href = "/auth";
				return { error: { status: 401, message: "Unauthorized" } }; // Return an error in the expected format
			}
			// else if (result.error.status === 404) {
			// 	console.log("ERROR 404!");
			// 	console.log(result);
			// }
		} else {
			// If the token is expired, refresh it
			const refreshResult: any = await baseQuery(
				{
					url: "/auth/refresh-token",
					method: "GET",
				},
				api,
				extraOptions
			);

			if (!refreshResult.error) {
				localStorage.setItem("ACCESS_TOKEN", refreshResult.data.data[0]);
			} else {
				localStorage.clear();
				window.location.href = "/auth";
				return { error: refreshResult.error }; // Return the refresh error in the expected format
			}
		}

		return result; // Return the successful result
	} catch (error: unknown) {
		console.log("Error thrown: ", error);

		// Type assertion for error handling
		if (isHttpError(error)) {
			console.log("HTTP", error);
			console.error("HTTP error:", error.status, error.data);
		} else if (isFetchError(error)) {
			console.log("FETCH", error);
			console.error("Fetch error:", error.error);
		} else if (isParsingError(error)) {
			console.log("PARSING", error);
			console.error("Parsing error:", error.error, error.data);
		} else if (isCustomError(error)) {
			console.log("CUSTOM", error);
			console.error("Custom error:", error.error, error.data);
		} else {
			console.log("UNKNOWN", error);
			console.error("Unknown error:", error);
		}

		// Return a default error format that matches the expected `FetchBaseQueryError`
		return {
			error: {
				status: "CUSTOM_ERROR",
				message: "An unknown error occurred",
			},
		};
	}
};
