import { z } from "zod";

export const UserFormSchema = z.object({
	firstName: z
		.string({
			required_error: "Please enter your first name.",
			invalid_type_error: "First name cannot contain numbers or symbols.",
		})
		.min(2, {
			message: "First name must be at least 2 characters.",
		})
		.max(30, {
			message: "First name must not exceed 30 characters.",
		})
		.regex(/^[A-Za-z.'\s-]+$/, {
			message: "First name cannot contain numbers or symbols.",
		})
		.trim(),
	lastName: z
		.string({
			required_error: "Please enter your last name.",
			invalid_type_error: "Last name cannot contain numbers or symbols.",
		})
		.min(2, {
			message: "Last name must be at least 2 characters.",
		})
		.max(25, {
			message: "Last name must not exceed 25 characters.",
		})
		.regex(/^[A-Za-z.'-]+$/, {
			message: "Last name cannot contain numbers or symbols.",
		})
		.trim(),
	email: z
		.string({
			required_error: "Please enter your email.",
		})
		.min(2, {
			message: "Please enter a valid email address.",
		})
		.email({ message: "Please enter a valid email address." }),
	roleId: z.coerce
		.number({
			required_error: "Please select a role.",
		})
		.min(1, "Please select a role."),
	status: z.coerce.number({
		required_error: "Please select a status.",
	}),
});
