import { z } from "zod";

export const LoginFormSchema = z.object({
	email: z
		.string()
		.min(1, { message: "Email is required" })
		.email("Invalid email format"),
	password: z
		.string({
			message: "Password is required",
		})
		.min(1, { message: "Password is required" })
		.transform((val) => val.trim()),
});

export const ForgotPasswordSchema = z.object({
	email: z
		.string()
		.min(1, { message: "Email is required" })
		.email("Invalid email format"),
});

export type LoginCredentials = z.infer<typeof LoginFormSchema>;

export type ForgotPassword = z.infer<typeof ForgotPasswordSchema>;
