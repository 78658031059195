import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import {
	useGetSelectedCustomerQuery,
	useUpdateCustomerMutation,
} from "src/redux/customer/apiSlice";

// Libraries
import { zodResolver } from "@hookform/resolvers/zod";

// Utils
import { Customer, UpdateCustomerType } from "src/redux/customer/types";
import { CustomerFormSchema } from "src/redux/customer/schema";

// Component
import { Button } from "src/shared/components/ui/button";
import { Input } from "src/shared/components/ui/input";
import { Label } from "src/shared/components/ui/label";
import { Form, FormField } from "src/shared/components/form/Form";
import PageContainer from "src/shared/components/layout/container/PageContainer";
import SectionContainer from "src/shared/components/layout/container/SectionContainer";
import SectionHeader from "src/shared/components/layout/header/SectionHeader";
import ButtonCheckIconSvg from "src/assets/svg/ButtonCheckIconSvg";
import FormHeader from "src/shared/components/layout/header/FormHeader";
import FormController from "src/shared/components/form/FormController";
import ConfirmationDialog from "src/shared/components/dialog/ConfirmationDialog";
import Loading from "src/shared/components/loading-indicator/Loading";
import NotificationDialog from "src/shared/components/dialog/NotificationDialog";
import { useToast } from "src/shared/hooks/useToast";
import { ToastClose } from "src/shared/components/ui/toast";
import { X } from "lucide-react";
import { formatDateToIso } from "src/shared/helpers/formatDate";

const CustomerForm = () => {
	const { toast } = useToast();
	const navigate = useNavigate();
	const location = useLocation();
	const pathSegments = location.pathname.split("/");
	const { customerId } = useParams<{ customerId: string }>();

	const { data: selectedCustomer, isFetching: isSelectedCustomerFetching } =
		useGetSelectedCustomerQuery(Number(customerId));

	const [
		_updateCustomer,
		{ isLoading: isUpdateCustomerLoading, isError: isUpdateCustomerError },
	] = useUpdateCustomerMutation();

	const [showLoading, setShowLoading] = useState(false);

	const [isLeaveModalOpen, setIsLeaveModalOpen] = useState(false);
	const [isSaveModalOpen, setIsSaveModalOpen] = useState(false);
	const [isUpdateNotifModalOpen, setIsUpdateNotifModalOpen] = useState(false);

	const form = useForm<Customer>({
		resolver: zodResolver(CustomerFormSchema),
		mode: "onChange",
		reValidateMode: "onSubmit",
		criteriaMode: "all",
	});

	const { isDirty, isValid } = form.formState;

	const _onConfirmLeave = () => {
		navigate("/customer");

		form.reset();
	};

	const _onCancelPress = () => {
		if (!isDirty) navigate("/customer");

		setIsLeaveModalOpen(true);
	};

	const _onSavePress = () => {
		setIsSaveModalOpen(true);
	};

	const _onConfirmSave = () => {
		_onSubmit(form.getValues());
	};

	// Created and Updated date is the same
	const _onSubmit = async (values: Customer) => {
		if (selectedCustomer) {
			const customer: UpdateCustomerType = {
				id: selectedCustomer.id,
				firstname: values.firstname,
				middlename: selectedCustomer.middlename,
				lastname: values.lastname,
				email: values.email,
				country_code: "US",
				created_by: selectedCustomer.created_by,
				created_from: selectedCustomer.created_from,
				landline: values.landline,
				mobile_no: values.mobile_no,
				updated_by: Number(localStorage.getItem("USER_ID")),
				updated_from: 1,
				user_id: selectedCustomer.user_id,
			};

			const response = await _updateCustomer(customer);

			if (response.error) {
				setIsSaveModalOpen(false);
				toast({
					description: "Something went wrong. Please try again.",
					duration: 2000,
					variant: "destructive",
					action: (
						<ToastClose
							className="absolute top-1/2 -translate-y-1/2 right-5 text-white hover:text-gray-200 focus:text-gray-200"
							aria-label="Close"
						>
							<X className="w-5 h-5" />
						</ToastClose>
					),
				});
			} else {
				setIsSaveModalOpen(false);
				setTimeout(() => {
					setIsUpdateNotifModalOpen(true);
				}, 500);
			}
		}
	};

	const _onUpdateNotifOkayPress = () => {
		setIsUpdateNotifModalOpen(false);
		navigate("/customer");
	};

	useEffect(() => {
		if (selectedCustomer) {
			form.reset({
				firstname: selectedCustomer.firstname,
				lastname: selectedCustomer.lastname,
				email: selectedCustomer.email,
				mobile_no: selectedCustomer.mobile_no,
				landline: selectedCustomer.landline,
			});

			form.trigger();
		}
	}, [selectedCustomer, form.reset]);

	if (isSelectedCustomerFetching) {
		return <Loading />;
	}

	if (!isSelectedCustomerFetching && !selectedCustomer) {
		if (pathSegments[2] != "add") {
			navigate("/customer", { replace: true });
		}
	}

	return (
		<Form {...form}>
			<form onSubmit={form.handleSubmit(_onSubmit)}>
				<PageContainer>
					<FormHeader>
						<Label variant="title">Edit Customer Information</Label>
						<div className="w-full h-fit sm:w-fit flex gap-2">
							<Button
								disabled={!isDirty || !isValid || isUpdateCustomerLoading}
								onClick={_onSavePress}
							>
								<ButtonCheckIconSvg
									className="h-5 w-5"
									fill={!isDirty || !isValid ? "#cbcbcc" : "#292D32"}
								/>
								Save
							</Button>
							<Button
								variant="secondary"
								size="md"
								type="reset"
								onClick={_onCancelPress}
								disabled={showLoading}
							>
								Cancel
							</Button>
						</div>
					</FormHeader>
					<SectionContainer className="lg:grid-cols-3">
						<SectionHeader className="lg:col-span-3">
							<Label variant="header">Customer Information</Label>
						</SectionHeader>

						<FormField
							control={form.control}
							name="firstname"
							render={({ field }) => (
								<FormController label="First Name" required>
									<Input {...field} />
								</FormController>
							)}
						/>
						<FormField
							control={form.control}
							name="lastname"
							render={({ field }) => (
								<FormController label="Last Name" required>
									<Input {...field} />
								</FormController>
							)}
						/>
						<FormField
							control={form.control}
							name="email"
							render={({ field }) => (
								<FormController label="Email" required>
									<Input {...field} />
								</FormController>
							)}
						/>
						<FormField
							control={form.control}
							name="mobile_no"
							render={({ field }) => (
								<FormController label="Mobile Number" required>
									<div className="relative">
										<Label className="absolute left-0 bottom-[5px] text-disabled-foreground text-[0.65rem] lg:text-sm tracking-tight whitespace-nowrap">
											+63
										</Label>
										<Input {...field} type="number" className="pl-6 lg:pl-8" />
									</div>
								</FormController>
							)}
						/>
						<FormField
							control={form.control}
							name="landline"
							render={({ field }) => (
								<FormController label="Landline (Optional)">
									<Input {...field} />
								</FormController>
							)}
						/>
					</SectionContainer>
				</PageContainer>
			</form>
			<ConfirmationDialog
				title="Leave page?"
				description="Changes are not yet saved."
				confirmButtonLabel="Leave"
				closeButtonLabel="Cancel"
				modalState={isLeaveModalOpen}
				_onCancel={() => setIsLeaveModalOpen(false)}
				_onConfirm={_onConfirmLeave}
			/>

			<ConfirmationDialog
				title="Save Changes?"
				description="Saving will apply all changes."
				confirmButtonLabel="Continue"
				closeButtonLabel="Cancel"
				modalState={isSaveModalOpen}
				isLoading={isUpdateCustomerLoading}
				_onCancel={() => setIsSaveModalOpen(false)}
				_onConfirm={_onConfirmSave}
				confirmButtonVariant="default"
			/>

			<NotificationDialog
				description="Customer has been updated successfully!"
				confirmButtonLabel="Ok"
				modalState={isUpdateNotifModalOpen}
				_onConfirm={_onUpdateNotifOkayPress}
			/>
		</Form>
	);
};

export default CustomerForm;
