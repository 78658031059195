import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

// Type

// Data
import productsArray from "../../data/product/products.json";
import categoriesArray from "../../data/categories/categories.json";
import { Product, ProductWithCategory } from "src/redux/product/types";
import { formatDate } from "src/shared/helpers/formatDate";

// TODO: Change this to api call
export const productsApi = createApi({
	reducerPath: "productsApi",
	tagTypes: ["Products", "Product"],
	baseQuery: fetchBaseQuery({ baseUrl: "/" }),
	endpoints: (builder) => ({
		getProducts: builder.query<ProductWithCategory[], void>({
			queryFn() {
				return new Promise<{ data: ProductWithCategory[] }>(
					(resolve, reject) => {
						try {
							setTimeout(() => {
								const productsWithCategory = productsArray
									.map((product: Product) => {
										const foundCategory = categoriesArray.find(
											(category) => category.id === product.categoryId
										);

										if (foundCategory) {
											return {
												...product,
												categoryName: foundCategory.name,
											};
										} else {
											return undefined;
										}
									})
									.filter(
										(product): product is ProductWithCategory =>
											product !== undefined
									);

								if (productsWithCategory.length > 0) {
									resolve({
										data: productsWithCategory,
									});
								} else {
									reject({
										message: "No valid products found!",
									});
								}
							}, 1000);
						} catch (error) {
							reject({ error: error as Error });
						}
					}
				);
			},
			providesTags: ["Products"],
		}),
		getSelectedProducts: builder.query<Product, { id: number }>({
			async queryFn({ id }) {
				try {
					await new Promise((resolve) => setTimeout(resolve, 1000));

					const selectedProduct = productsArray.find(
						(product) => id === product.id
					);

					if (!selectedProduct) {
						return {
							error: { status: 404, data: { message: "Product not found" } },
						};
					}

					try {
						// Format images
						const formattedImages = await Promise.all(
							selectedProduct.images.map(async (image) => {
								if (!image) return "";
								const response = await fetch(image);
								const blob = await response.blob();
								return URL.createObjectURL(blob);
							})
						);

						const selectedProductWithFormattedImages: Product = {
							...selectedProduct,
							categoryId: selectedProduct.categoryId,
							endDate: formatDate(selectedProduct.endDate),
							startDate: formatDate(selectedProduct.startDate),
							images: formattedImages,
						};

						return { data: selectedProductWithFormattedImages };
					} catch (error) {
						console.error("Error fetching images:", error);
						return {
							error: {
								status: 500,
								data: { message: "Error fetching images" },
							},
						};
					}
				} catch (error) {
					console.error("Unexpected error:", error);
					return {
						error: {
							status: 500,
							data: { message: "Unexpected error occurred" },
						},
					};
				}
			},
			keepUnusedDataFor: 0,
		}),
		addProduct: builder.mutation<void, Product>({
			query: (newProduct) => ({
				url: "/new",
				method: "POST",
				body: newProduct,
			}),
			invalidatesTags: ["Products"],
		}),
		updateProduct: builder.mutation<void, Product>({
			query: (updatedProduct) => ({
				url: `/update/${updatedProduct.id}`,
				method: "PUT",
				body: updatedProduct,
			}),
			invalidatesTags: ["Products"],
		}),
		deleteProduct: builder.mutation<void, string>({
			query: (id) => ({
				url: `/delete/${id}`,
				method: "DELETE",
			}),
			invalidatesTags: ["Products"],
		}),
	}),
});

export const {
	useGetProductsQuery,
	useGetSelectedProductsQuery,
	useAddProductMutation,
	useUpdateProductMutation,
	useDeleteProductMutation,
} = productsApi;
