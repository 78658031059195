import { FormEvent, useEffect, useState } from "react";
import { UseFormReturn, useWatch } from "react-hook-form";

// Types

// Components
import { Checkbox } from "src/shared/components/ui/checkbox";
import { Label } from "src/shared/components/ui/label";
import { FormField } from "src/shared/components/form/Form";
import {
	RadioGroup,
	RadioGroupItem,
} from "src/shared/components/ui/radio-group";
import FormController from "src/shared/components/form/FormController";
import DateInput from "src/shared/components/textfield/DateInput";

import DatePickerInput from "src/shared/components/date-picker/DatePicker";
import { Banner } from "src/redux/banner/types";
import CustomDateInput from "src/shared/components/date-picker/CustomDateInput";
import { formatDate } from "src/shared/helpers/formatDate";
import { BannerFormSchema } from "src/redux/banner/schema";

type PromoDurationSectionProps = {
	form: UseFormReturn<Banner, any, undefined>;
	noOfActiveBanners: number;
};

const PromoDurationSection = ({
	form,
	noOfActiveBanners,
}: PromoDurationSectionProps) => {
	const [isNoExpirationDate, setIsNoExpirationDate] = useState(false);

	// * Don't delete, this triggers rerenders and validations if an event happens in CustomDateInput component
	const dates = useWatch({
		control: form.control,
		name: ["from", "to"],
	});

	const handleDateChange =
		(field: "from" | "to") => (value: Date | FormEvent<HTMLInputElement>) => {
			const otherField = field === "from" ? "to" : "from";
			const otherValue = form.getValues(otherField);

			// Convert value to date string based on its type
			let dateString = "";
			if (value instanceof Date) {
				// Adjust for timezone to keep the selected date
				const offset = value.getTimezoneOffset();
				const adjustedDate = new Date(value.getTime() - offset * 60 * 1000);
				dateString = adjustedDate.toISOString().split("T")[0];
			} else {
				dateString = (value.target as HTMLInputElement).value;
			}

			// Set the value
			form.setValue(field, formatDate(dateString));

			// Only validate if both dates have values
			if (dateString && otherValue) {
				const fromDate = field === "from" ? dateString : form.getValues("from");
				const toDate = field === "to" ? dateString : form.getValues("to");

				// Compare dates
				if (new Date(toDate) < new Date(fromDate)) {
					form.setError("from", {
						type: "manual",
						message: "End date cannot be earlier than Start date.",
					});
				} else {
					form.clearErrors(["from", "to"]);
				}
			}
		};

	useEffect(() => {
		if (isNoExpirationDate == true) {
			form.setValue("to", "", { shouldDirty: true, shouldValidate: true });
		}
	}, [isNoExpirationDate]);

	return (
		<div className="flex flex-col lg:flex-row lg:flex-wrap gap-4">
			<Label variant="sub_header" className="w-full">
				Promo Duration<span className="text-destructive">*</span>
			</Label>

			<div>
				<FormField
					control={form.control}
					name="from"
					render={({ field }) => (
						<FormController label="Start Date">
							<CustomDateInput
								{...field}
								onChange={handleDateChange("from")}
								value={String(field.value)}
							/>
						</FormController>
					)}
				/>
			</div>

			<div className="flex flex-col-reverse gap-2 lg:flex-row lg:gap-4">
				<FormField
					control={form.control}
					name="to"
					render={({ field }) => (
						<FormController label="End Date">
							<CustomDateInput
								{...field}
								onChange={handleDateChange("to")}
								value={String(field.value)}
								disabled={isNoExpirationDate}
							/>
						</FormController>
					)}
				/>
				<div className="flex flex-col gap-4">
					<Label variant="form">Expiration</Label>
					<div className="flex gap-1 items-center">
						<Checkbox
							className="w-3 h-3 lg:w-4 lg:h-4"
							checked={isNoExpirationDate}
							onCheckedChange={() => setIsNoExpirationDate(!isNoExpirationDate)}
						/>
						<Label variant="description">No Expiration</Label>
					</div>
				</div>
			</div>

			<div className="lg:flex-1">
				<FormField
					control={form.control}
					name="status"
					render={({ field }) => (
						<FormController
							label="Status"
							description="* Inactive promo banners will have their Display Order nullified by default and will not reflect in the app."
						>
							<RadioGroup
								onValueChange={(value) => field.onChange(Number(value))}
								value={String(field.value)}
								className="flex gap-4 mt-2"
							>
								<div className="flex items-center space-x-2">
									<RadioGroupItem
										value="1"
										id="active"
										disabled={noOfActiveBanners == 8}
									/>
									<Label
										htmlFor="active"
										variant="form"
										className={`${
											noOfActiveBanners == 8
												? "text-disabled-foreground"
												: "text-secondary"
										}`}
									>
										Active
									</Label>
								</div>
								<div className="flex items-center space-x-2">
									<RadioGroupItem value="0" id="inactive" />
									<Label
										htmlFor="inactive"
										variant="form"
										className="text-secondary"
									>
										Inactive
									</Label>
								</div>
							</RadioGroup>
						</FormController>
					)}
				/>
			</div>
		</div>
	);
};

export default PromoDurationSection;
