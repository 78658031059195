import { z } from "zod";

export const ProductFormSchema = z
	.object({
		sku: z
			.string({
				message: "Please enter the product SKU.",
			})
			.min(1, "Please enter the product SKU.")
			.max(50, "SKU must be less than 50 characters."),
		image: z
			.union([
				z.instanceof(File),
				z.string().url({
					message: "Image is empty.",
				}),
			])
			.optional(),
		name: z
			.string({
				required_error: "Please enter the product name.",
			})
			.min(3, "Name must be at least 3 characters long.")
			.max(100, "Name must be less than 100 characters."),
		categoryId: z
			.number({
				required_error: "Please select a category.",
				invalid_type_error: "Please select a category.",
			})
			.min(1, "Category cannot be empty."),
		description: z
			.string({
				message: "Please enter product description.",
			})
			.min(10, "Description must be at least 10 characters.")
			.max(1000, "Description must be less than 1000 characters."),
		price: z.coerce
			.number()
			.nonnegative("Price cannot be negative.")
			.gt(0, { message: "Price must be greater than 0." }),
		discounted_price: z.coerce
			.number()
			.nonnegative("Discounted price cannot be negative.")
			.optional(),
		startDate: z.string().optional(),
		endDate: z.string().optional(),
		colors: z
			.string()
			.regex(
				/^#(?:[0-9a-fA-F]{3}){1,2}$/,
				"Invalid color format (must be a valid hex code)."
			)
			.optional(),
		variations: z
			.number()
			.int("Variations must be an integer.")
			.min(1, "Variations must be at least 1.")
			.optional(),
		stocks: z
			.number()
			.int("Stocks must be an integer.")
			.nonnegative("Stocks cannot be negative.")
			.optional(),
		size: z
			.string()
			.regex(/^(XS|S|M|L|XL|XXL)$/, "Size must be one of XS, S, M, L, XL, XXL.")
			.optional(),
	})
	.refine(
		(data) => {
			if (data.startDate && data.endDate) {
				// Convert strings to Date objects for comparison
				const startDate = new Date(data.startDate);
				const endDate = new Date(data.endDate);
				return endDate >= startDate; // `endDate` must not be before `startDate`
			}
			return true; // If either `startDate` or `endDate` is missing, pass validation
		},
		{
			message: "End date cannot be earlier than Start date.",
			path: ["startDate"], // Attach the error message to the `startDate` field
		}
	);

export const ImportProductSchema = z
	.object({
		sku: z.string({
			required_error: "SKU is empty.",
			invalid_type_error: "SKU must be a string.",
		}),
		images: z.string({
			required_error: "Image is empty.",
			invalid_type_error: "Image must be a string.",
		}),
		name: z
			.string({
				required_error: "Product name is empty.",
				invalid_type_error: "Product name must be a string.",
			})
			.min(3, {
				message: "Product name must be at least 3 characters.",
			})
			.max(100, {
				message: "Product name must not exceed 100 characters.",
			})
			.trim(),
		category: z
			.string({
				required_error: "Category is empty.",
				invalid_type_error: "Category must be a string.",
			})
			.min(1, {
				message: "Category cannot be empty.",
			})
			.trim(),
		description: z
			.string({
				required_error: "Description is empty.",
				invalid_type_error: "Description must be a string.",
			})
			.max(1000, {
				message: "Description must not exceed 1000 characters.",
			})
			.trim(),
		price: z.coerce
			.number({
				required_error: "Price is empty.",
				invalid_type_error: "Invalid Price.",
			})
			.nonnegative({
				message: "Price cannot be negative.",
			})
			.gt(0, { message: "Price must be greater than 0." }),
		discounted_price: z.coerce
			.number({
				required_error: "Discounted price is empty.",
				invalid_type_error: "Discounted price must be a number.",
			})
			.nonnegative({
				message: "Discounted price cannot be negative.",
			})
			.optional(),
	})
	.strict();
