import { z } from "zod";

const AccessSchema = z.object({
	view: z.boolean(),
	add: z.boolean(),
	edit: z.boolean(),
	delete: z.boolean(),
});

const PermissionSchema = z.object({
	module: z.enum([
		"Banner Management",
		"Users List",
		"Roles & Permission",
		"Customer Management",
		"Product Management",
		"Category Management",
		"Store Management",
		"Library",
		"Account Settings",
	]),
	access: AccessSchema,
});

export const RoleFormSchema = z.object({
	name: z
		.string({
			required_error: "Please enter role name.",
		})
		.min(2, {
			message: "Role name must be at least 2 characters.",
		})
		.max(20, {
			message: "Role name must not exceed 20 characters.",
		})
		.regex(/^[A-Za-z.'\s-]+$/, {
			message: "Role name can only contain letters.",
		})
		.trim(),
	description: z
		.string({
			required_error: "Please enter description.",
		})
		.min(5, {
			message: "Description is too short.",
		})
		.max(250, {
			message: "Description is too long.",
		})
		.trim(),
});
