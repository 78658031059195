import { useNavigate } from "react-router-dom";

// Types
import { ColumnDef } from "@tanstack/react-table";
import { ImportProduct } from "src/redux/product/types";

// Icons
import { FaSort } from "react-icons/fa";
import { Checkbox } from "src/shared/components/ui/checkbox";
import { CheckedState } from "@radix-ui/react-checkbox";

export const useDuplicateProductDataColumns =
	(): ColumnDef<ImportProduct>[] => {
		const navigate = useNavigate();

		const columns: ColumnDef<ImportProduct>[] = [
			{
				id: "select",
				header: ({ table }) => (
					<Checkbox
						checked={
							table.getIsAllPageRowsSelected() ||
							(table.getIsSomePageRowsSelected() && "indeterminate")
						}
						onCheckedChange={(value: CheckedState) =>
							table.toggleAllPageRowsSelected(!!value)
						}
						aria-label="Select all"
						className="w-[14px] h-[14px] ml-2"
					/>
				),
				cell: ({ row }) => (
					<Checkbox
						checked={row.getIsSelected()}
						onCheckedChange={(value) => row.toggleSelected(!!value)}
						aria-label="Select row"
						className="w-[14px] h-[14px] ml-2"
					/>
				),
				enableSorting: false,
				enableHiding: false,
			},
			{
				accessorKey: "sku",
				header: ({ column }) => (
					<button
						type="button"
						className="flex w-full"
						onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
					>
						<FaSort className="h-3 w-3 mr-1 group-hover:cursor-pointer text-gray-400" />
						<p className="font-bold">SKU</p>
					</button>
				),
				cell: ({ row }) => {
					return (
						<div className="overflow-hidden text-ellipsis whitespace-nowrap">
							{row.getValue("sku")}
						</div>
					);
				},
			},
			{
				accessorKey: "name",
				header: ({ column }) => (
					<button
						type="button"
						className="flex w-full"
						onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
					>
						<FaSort className="h-3 w-3 mr-1 group-hover:cursor-pointer text-gray-400" />
						<p className="font-bold">Name</p>
					</button>
				),
				cell: ({ row }) => {
					const storeName: string = row.getValue("name");
					return (
						<div
							className="w-[20ch] overflow-hidden text-ellipsis whitespace-nowrap"
							title={storeName}
						>
							{row.getValue("name")}
						</div>
					);
				},
			},
			{
				accessorKey: "description",
				header: ({ column }) => (
					<button
						type="button"
						className="flex items-center w-full"
						onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
					>
						<FaSort className="h-3 w-3 mr-1 group-hover:cursor-pointer text-gray-400" />
						<p className="font-bold">Description</p>
					</button>
				),
				cell: ({ row }) => {
					return (
						<div className="w-[12em] overflow-hidden text-ellipsis line-clamp-2">
							{row.getValue("description")}
						</div>
					);
				},
			},
			{
				accessorKey: "price",
				header: () => <p className="font-bold">Price</p>,
				cell: ({ row }) => {
					return (
						<div className="overflow-hidden text-ellipsis whitespace-nowrap max-w-24">
							{row.getValue("price")}
						</div>
					);
				},
			},
			{
				accessorKey: "discounted_price",
				header: () => <p className="font-bold">Disc. Price</p>,
				cell: ({ row }) => {
					return (
						<div className="overflow-hidden text-ellipsis whitespace-nowrap max-w-24">
							{row.getValue("discounted_price")}
						</div>
					);
				},
			},
			{
				accessorKey: "category",
				header: () => <p className="font-bold">Category</p>,
				cell: ({ row }) => {
					return (
						<div className="overflow-hidden text-ellipsis whitespace-nowrap max-w-24">
							{row.getValue("category")}
						</div>
					);
				},
			},
			{
				accessorKey: "images",
				header: () => <p className="font-bold">Image Links</p>,
				cell: ({ row }) => {
					return (
						<div className="overflow-hidden text-ellipsis whitespace-nowrap max-w-24">
							{row.getValue("images")}
						</div>
					);
				},
			},
		];

		return columns;
	};
