import { useCallback, useMemo, useState } from "react";
import { useRolesColumns } from "./columns";
import { useGetRolesQuery } from "../../redux/role/apiSlice";
import { useNavigate } from "react-router-dom";

// Types
import { Role } from "../../redux/role/types";

// Components
import { DataTable } from "src/shared/components/table/DataTable";
import { Label } from "src/shared/components/ui/label";
import { Button } from "src/shared/components/ui/button";
import PageControls from "src/shared/components/control/PageControls";
import PageContainer from "src/shared/components/layout/container/PageContainer";
import PageHeader from "src/shared/components/layout/header/PageHeader";
import SearchInput from "src/shared/components/textfield/SearchInput";
import Loading from "src/shared/components/loading-indicator/Loading";
import ConfirmationDialog from "src/shared/components/dialog/ConfirmationDialog";

// Icons
import ButtonAddIconSvg from "src/assets/svg/ButtonAddIconSvg";
import ButtonDeleteIconSvg from "src/assets/svg/ButtonDeleteIconSvg";
import NotificationDialog from "src/shared/components/dialog/NotificationDialog";

const RolePage = () => {
	const navigate = useNavigate();

	// DATA
	const { data: rolesArray = [], isFetching: isRolesArrayFecthing } =
		useGetRolesQuery();

	// ! Remove me on API Integration and use isLoading by rtk query
	const [showLoading, setShowLoading] = useState(false);

	const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
	const [isMultipleDeleteModalOpen, setIsMultipleDeleteModalOpen] =
		useState(false);
	const [isDeleteNotifModalOpen, setIsDeleteNotifModalOpen] = useState(false);
	const [selectedRole, setSelectedRole] = useState({
		id: 0,
		name: "",
	});
	const [searchValue, setSearchValue] = useState("");

	const [selectedRows, setSelectedRows] = useState<Role[]>([]);

	const filteredRoles = useMemo(() => {
		return rolesArray.filter(
			(role) =>
				role.name.toLowerCase().includes(searchValue.toLowerCase()) ||
				role.description.toLowerCase().includes(searchValue.toLowerCase())
		);
	}, [searchValue, rolesArray]);

	const handleSelectionChange = useCallback((newSelectedRows: Role[]) => {
		setSelectedRows(newSelectedRows);
	}, []);

	const _onOpenDeleteModal = (id: number, name: string) => {
		setIsDeleteModalOpen(true);
		setSelectedRole({
			id: id,
			name: name,
		});
	};

	const _onCloseDeleteModal = () => {
		setIsDeleteModalOpen(false);
	};

	const _onConfirmDelete = async () => {
		setShowLoading(true);
		setTimeout(async () => {
			setShowLoading(false);
			setIsDeleteModalOpen(false);
			setTimeout(() => {
				setIsDeleteNotifModalOpen(true);
			}, 250);
		}, 2000);
	};

	const _onOpenMultipleDeleteModal = () => {
		setIsMultipleDeleteModalOpen(true);
	};

	const _onCloseMultipleDeleteModal = () => {
		setIsMultipleDeleteModalOpen(false);
	};

	const _onConfirmMultipleDelete = async () => {
		setShowLoading(true);
		setTimeout(async () => {
			setShowLoading(false);
			setIsMultipleDeleteModalOpen(false);
			setTimeout(() => {
				setIsDeleteNotifModalOpen(true);
			}, 250);
		}, 2000);
	};

	const _onDeleteNotifOkayPress = () => {
		setIsDeleteNotifModalOpen(false);
	};

	const columns = useRolesColumns(_onOpenDeleteModal);

	if (isRolesArrayFecthing) {
		return <Loading />;
	}

	return (
		<PageContainer>
			<PageHeader>
				<Label variant="title">Roles & Permission</Label>
			</PageHeader>
			<PageControls>
				<div className="flex gap-2 w-full sm:w-[15em]">
					<Button onClick={() => navigate("add")}>
						<ButtonAddIconSvg fill="#292d32" className="h-6 w-6" />
						Add Roles
					</Button>
					<Button
						variant="destructive"
						size="md"
						disabled={selectedRows.length < 1}
						onClick={_onOpenMultipleDeleteModal}
					>
						<ButtonDeleteIconSvg
							fill={`${selectedRows.length < 1 ? "#cbcbcc" : "white"}`}
							className="h-5 w-5 mb-[2px]"
						/>
						Delete
					</Button>
				</div>
				<div className="w-full xl:w-fit flex flex-row-reverse sm:flex-row gap-6 ml-auto justify-end items-center">
					<SearchInput setSearchValue={setSearchValue} />
				</div>
			</PageControls>

			<div className="mt-8">
				<DataTable
					data={filteredRoles}
					columns={columns}
					onSelectionChange={handleSelectionChange}
					hasPagination={rolesArray.length > 10}
				/>
			</div>

			<ConfirmationDialog
				title="Delete Role?"
				description={`Role ${selectedRole.name} will be deleted`}
				closeButtonLabel="Cancel"
				confirmButtonLabel="Delete"
				confirmButtonIcon={ButtonDeleteIconSvg}
				modalState={isDeleteModalOpen}
				isLoading={showLoading}
				_onConfirm={_onConfirmDelete}
				_onCancel={_onCloseDeleteModal}
			/>

			<ConfirmationDialog
				title={`Delete Selected Roles?`}
				description={`${selectedRows.length} roles will be deleted`}
				closeButtonLabel="Cancel"
				confirmButtonLabel="Delete"
				confirmButtonIcon={ButtonDeleteIconSvg}
				modalState={isMultipleDeleteModalOpen}
				isLoading={showLoading}
				_onConfirm={_onConfirmMultipleDelete}
				_onCancel={_onCloseMultipleDeleteModal}
			/>

			<NotificationDialog
				description={
					selectedRows.length > 0
						? "Roles has been deleted successfully!"
						: "Role has been deleted successfully!"
				}
				confirmButtonLabel="Ok"
				modalState={isDeleteNotifModalOpen}
				_onConfirm={_onDeleteNotifOkayPress}
			/>
		</PageContainer>
	);
};

export default RolePage;
