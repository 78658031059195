import { Outlet } from "react-router-dom";
import SideBar from "../sidebar/SideBar";
import NavBar from "../navbar/NavBar";
import { useWindowResize } from "src/shared/hooks/useWindowResize";

const RootLayout = () => {
	useWindowResize();

	return (
		<div className="relative flex flex-col xl:flex-row h-screen w-screen  max-h-screen min-w-screen overflow-auto bg-background">
			<div className="sticky top-0 z-[9999] h-auto">
				<SideBar />
			</div>
			<div className="flex flex-col grow">
				<div>
					<NavBar />
				</div>
				<div className="grow">
					<Outlet />
				</div>
			</div>
		</div>
	);
};

export default RootLayout;
