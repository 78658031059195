import { ColumnDef } from "@tanstack/react-table";
import React, { useCallback, useMemo, useState } from "react";
import { Store } from "src/redux/store/types";
import { DataTable } from "src/shared/components/table/DataTable";
import { formatDate } from "src/shared/helpers/formatDate";

type StoreTableProps = {
	storesArray: Store[];
	columns: ColumnDef<Store>[];
	searchValue: string;
	isActiveTable: boolean;
	setSelectedRows: React.Dispatch<React.SetStateAction<Store[]>>;
};

const StoreTables = ({
	storesArray,
	columns,
	searchValue,
	isActiveTable,
	setSelectedRows,
}: StoreTableProps) => {
	const filteredStores = useMemo(() => {
		return storesArray.filter(
			(store) =>
				store.name.toLowerCase().includes(searchValue.toLowerCase()) ||
				store.system_name.toLowerCase().includes(searchValue.toLowerCase()) ||
				store.store_code.toLowerCase().includes(searchValue.toLowerCase()) ||
				store.opening_hrs.toLowerCase().includes(searchValue.toLowerCase()) ||
				store.landline_no.toLowerCase().includes(searchValue.toLowerCase()) ||
				store.postal_code.toLowerCase().includes(searchValue.toLowerCase()) ||
				store.city.toLowerCase().includes(searchValue.toLowerCase()) ||
				store.address.toLowerCase().includes(searchValue.toLowerCase())
		);
	}, [searchValue, storesArray]);

	const handleSelectionChange = useCallback((newSelectedRows: Store[]) => {
		setSelectedRows(newSelectedRows);
	}, []);

	return (
		<div>
			{isActiveTable && (
				<div className="mt-8">
					<DataTable
						data={filteredStores}
						columns={columns}
						onSelectionChange={handleSelectionChange}
						hasPagination={storesArray.length > 10}
					/>
				</div>
			)}

			{!isActiveTable && (
				<div className="mt-8">
					<DataTable
						data={filteredStores}
						columns={columns}
						onSelectionChange={handleSelectionChange}
						hasPagination={storesArray.length > 10}
					/>
				</div>
			)}
		</div>
	);
};

export default StoreTables;
