// Utils
import {
	validateFileExtension,
	validateFileSize,
} from "src/shared/helpers/validateAttachment";

// Types
import { UseFormReturn } from "react-hook-form";
import { Library } from "src/redux/library/types";

// Components
import { Label } from "src/shared/components/ui/label";
import { Textarea } from "src/shared/components/ui/textarea";
import { FormField, FormMessage } from "src/shared/components/form/Form";
import FormController from "src/shared/components/form/FormController";
import UploadButton from "src/shared/components/button/UploadButton";
import { SetStateAction } from "react";

type AboutUsFormProp = {
	form: UseFormReturn<Library, any, undefined>;
	setImagePath: React.Dispatch<SetStateAction<string>>;
};

const AboutUsForm = ({ form, setImagePath }: AboutUsFormProp) => {
	return (
		<div className="grid xl:grid-cols-2 gap-4">
			<FormField
				control={form.control}
				name="image"
				render={({ field: { value, onChange, ...fieldProps } }) => {
					return (
						<div className="grid w-full xl:col-span-1 gap-2">
							<Label variant="form" htmlFor="banner">
								Banner<span className="text-destructive-foreground">*</span>
							</Label>
							<div
								className="h-[14em] xl:h-[22em] border-[1px] border-secondary rounded-md flex items-center justify-center"
								style={{
									backgroundImage: `url(${value})`,
									backgroundSize: "cover",
									backgroundPosition: "center",
								}}
							>
								<UploadButton
									buttonText="Upload Image"
									accept="image/*"
									onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
										const file = e.target.files?.[0];

										if (file) {
											const isAttachmentValid = validateFileSize({
												file: file,
												form: form,
												errorMessage: "Image size must not exceed 2mb.",
												fieldName: "image",
												sizeLimit: 2,
											});

											const isExtensionValid = validateFileExtension({
												file: file,
												form: form,
												errorMessage:
													"Invalid file type. Please upload a .png, .jpg, or .jpeg file.",
												fieldName: "image",
												validExtensions: [
													"image/jpeg",
													"image/jpg",
													"image/png",
												],
											});

											if (!isExtensionValid || !isAttachmentValid) {
												return;
											} else {
												form.clearErrors();
											}

											const imageUrl = URL.createObjectURL(file);

											setImagePath(file.name);
											onChange(imageUrl);

											// Here you would typically upload the file to your server
											// and then update the form with the returned URL
											// uploadFileToServer(file).then(url => onChange(url));
										}
									}}
									value="" // Keep the file input value empty
									{...fieldProps}
								/>
							</div>
							{form.formState.errors.image && (
								<FormMessage>
									<>{form.formState.errors.image}</>
								</FormMessage>
							)}
						</div>
					);
				}}
			/>
			<FormField
				control={form.control}
				name="content"
				render={({ field }) => (
					<FormController label="Content" required>
						<div className="flex flex-col w-full h-[20em] sm:h-[14em] xl:h-[22em] gap-2 xl:col-span-1">
							<Textarea id="content" {...field} rows={20} wrap="soft" />
						</div>
					</FormController>
				)}
			/>
		</div>
	);
};

export default AboutUsForm;
