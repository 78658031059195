import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import storeArray from "src/data/store/stores.json";
import { Store } from "./types";
import {
	MultipleDeleteApiResponse,
	SuccessMultipleDataResponse,
} from "../types";
import { baseQueryWithReauth } from "src/shared/helpers/prepareBaseQuery";

export const storesApi = createApi({
	reducerPath: "storesApi",
	tagTypes: ["Stores", "Store"],
	baseQuery: baseQueryWithReauth,
	endpoints: (builder) => ({
		getStores: builder.query<Store[], void>({
			query: () => ({
				url: "/store/get_all",
				method: "GET",
			}),
			transformResponse: ({
				status,
				data,
				message,
			}: SuccessMultipleDataResponse<Store>) => {
				const formattedLongLat = data.map((store) => {
					return {
						...store,
						latitude: Number(store.latitude),
						longitude: Number(store.longitude),
					};
				});

				return formattedLongLat;
			},
			providesTags: ["Stores"],
			keepUnusedDataFor: 10,
		}),
		getSelectedStore: builder.query<Store, number>({
			query: (id: number) => ({
				url: `store/${id}`,
				method: "GET",
			}),
			transformResponse: ({
				status,
				data,
				message,
			}: SuccessMultipleDataResponse<Store>) => {
				const formattedLongLa: Store = {
					...data[0],
					longitude: Number(data[0].longitude),
					latitude: Number(data[0].latitude),
				};

				return formattedLongLa;
			},
			keepUnusedDataFor: 0,
		}),
		addStores: builder.mutation<void, Store>({
			query: (newStore) => ({
				url: "/store/create",
				method: "POST",
				body: newStore,
			}),
			invalidatesTags: ["Stores"],
		}),
		updateStore: builder.mutation<void, Store>({
			query: (updatedStore) => ({
				url: `/store/${updatedStore.id}`,
				method: "PATCH",
				body: updatedStore,
			}),
			invalidatesTags: ["Stores"],
		}),
		deleteStore: builder.mutation<void, number>({
			query: (id) => ({
				url: `/store/${id}`,
				method: "DELETE",
			}),
			invalidatesTags: ["Stores"],
		}),
		deleteMultipleStores: builder.mutation<MultipleDeleteApiResponse, Store[]>({
			query: (data) => {
				return {
					url: "/delete",
					method: "PUT",
					body: data,
				};
			},
		}),
	}),
});

export const {
	useGetStoresQuery,
	useGetSelectedStoreQuery,
	useAddStoresMutation,
	useUpdateStoreMutation,
	useDeleteStoreMutation,
	useDeleteMultipleStoresMutation,
} = storesApi;
