import { useNavigate } from "react-router-dom";

// Types
import { ColumnDef } from "@tanstack/react-table";

// Utils

// Components
import { Checkbox } from "src/shared/components/ui/checkbox";
import { Button } from "src/shared/components/ui/button";
import { Label } from "src/shared/components/ui/label";

// Icons
import { FaSort } from "react-icons/fa";
import TableEditIconSvg from "src/assets/svg/table/TableEditIconSvg";
import TableDeleteIconSvg from "src/assets/svg/table/TableDeleteIconSvg";
import { Store } from "src/redux/store/types";
import { formatDate } from "src/shared/helpers/formatDate";

type StoreColumnProps = (id: number, name: string) => void;

export const useStoreColumns = (
	_onOpenDeleteModal?: StoreColumnProps
): ColumnDef<Store>[] => {
	const navigate = useNavigate();

	const columns: ColumnDef<Store>[] = [
		{
			id: "select",
			header: ({ table }) => (
				<Checkbox
					checked={
						table.getIsAllPageRowsSelected() ||
						(table.getIsSomePageRowsSelected() && "indeterminate")
					}
					onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
					aria-label="Select all"
					className="w-[14px] h-[14px] ml-2"
				/>
			),
			cell: ({ row }) => (
				<Checkbox
					checked={row.getIsSelected()}
					onCheckedChange={(value) => row.toggleSelected(!!value)}
					aria-label="Select row"
					className="w-[14px] h-[14px] ml-2"
				/>
			),
			enableSorting: false,
			enableHiding: false,
		},
		{
			accessorKey: "store_code",
			header: ({ column }) => (
				<Button
					variant="table_filter"
					className="w-fit"
					onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
				>
					<FaSort className="h-4 w-4 group-hover:cursor-pointer text-gray-400" />
					<Label className="group-hover:cursor-pointer ">
						Store <br />
						Code
					</Label>
				</Button>
			),
			cell: ({ row }) => (
				<div className="w-[5em] overflow-hidden text-ellipsis whitespace-nowrap text-center">
					{row.getValue("store_code")}
				</div>
			),
		},
		{
			accessorKey: "system_name",
			header: ({ column }) => (
				<Button
					variant="table_filter"
					className="w-fit"
					onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
				>
					<FaSort className="h-4 w-4 group-hover:cursor-pointer text-gray-400" />
					<Label className="group-hover:cursor-pointer">
						System <br />
						Name
					</Label>
				</Button>
			),
			cell: ({ row }) => (
				<div className="w-[7em] overflow-hidden text-ellipsis whitespace-nowrap text-center">
					{row.getValue("system_name")}
				</div>
			),
		},
		{
			accessorKey: "postal_code",
			header: ({ column }) => (
				<Button
					variant="table_filter"
					className="max-w-[5em]"
					onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
				>
					<FaSort className="h-4 w-4 group-hover:cursor-pointer text-gray-400" />
					<Label className="group-hover:cursor-pointer w-[4em]">
						Postal <br /> Code
					</Label>
				</Button>
			),
			cell: ({ row }) => (
				<div className="w-[5em] overflow-hidden text-ellipsis whitespace-nowrap text-center">
					{row.getValue("postal_code")}
				</div>
			),
		},
		{
			accessorKey: "name",
			header: ({ column }) => (
				<Button
					variant="table_filter"
					className="w-fit"
					onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
				>
					<FaSort className="h-4 w-4 group-hover:cursor-pointer text-gray-400" />
					<Label className="group-hover:cursor-pointer">
						Store <br />
						Name
					</Label>
				</Button>
			),
			cell: ({ row }) => (
				<div className="max-w-[10em] w-full overflow-hidden text-ellipsis whitespace-nowrap">
					{row.getValue("name")}
				</div>
			),
		},
		{
			accessorKey: "address",
			header: ({ column }) => (
				<Button
					variant="table_filter"
					className="w-fit"
					onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
				>
					<FaSort className="h-4 w-4 group-hover:cursor-pointer text-gray-400" />
					<Label className="group-hover:cursor-pointer">Address</Label>
				</Button>
			),
			cell: ({ row }) => (
				<div className="max-w-[20em] xl:max-w-[16em] w-full overflow-hidden text-ellipsis whitespace-nowrap">
					{row.getValue("address")}
				</div>
			),
		},
		{
			accessorKey: "opening_hrs",
			header: ({ column }) => (
				<Button
					variant="table_filter"
					className="w-fit xl:w-[3em] text-wrap xl:mr-2 2xl:mr-0 2xl:w-[6em]"
					onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
				>
					<FaSort className="h-4 w-4 group-hover:cursor-pointer text-gray-400" />
					<Label className="group-hover:cursor-pointer">Opening Hrs.</Label>
				</Button>
			),
			cell: ({ row }) => (
				<div className="pl-2 max-w-[6em] xl:max-w-[4em] 2xl:max-w-[7em] w-full overflow-hidden text-ellipsis whitespace-nowrap ">
					{row.getValue("opening_hrs")}
				</div>
			),
		},
		{
			accessorKey: "landline_no",
			header: ({ column }) => (
				<Button
					variant="table_filter"
					className="w-fit"
					onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
				>
					<FaSort className="h-4 w-4 group-hover:cursor-pointer text-gray-400" />
					<Label className="group-hover:cursor-pointer">Landline</Label>
				</Button>
			),
			cell: ({ row }) => (
				<div className="w-[8em] overflow-hidden text-ellipsis whitespace-nowrap pl-2">
					{row.getValue("landline_no")}
				</div>
			),
		},
		{
			id: "actions",
			header: () => (
				<div>
					<Label>Action</Label>
				</div>
			),
			cell: ({ row }) => {
				return (
					<div className="flex flex-row gap-3">
						<Button
							variant="icon"
							size="icon"
							onClick={() => {
								console.log("Navigate");
								navigate(`/store/${row.original.id}`);
							}}
						>
							<TableEditIconSvg fill="#292D32" className="h-6 w-6" />
						</Button>
						<Button
							variant="icon"
							size="icon"
							onClick={() => {
								if (_onOpenDeleteModal)
									_onOpenDeleteModal(row.original.id, row.original.name);
							}}
						>
							<TableDeleteIconSvg fill="#292D32" className="h-6 w-6" />
						</Button>
					</div>
				);
			},
		},
	];

	return columns;
};
