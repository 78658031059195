import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import {
	useGetAboutUsInfoQuery,
	useGetContactInfoQuery,
	useGetSocialMediaLinksQuery,
	useUpdateAboutUsMutation,
	useUpdateContactMutation,
	useUpdateSocialMediaMutation,
} from "src/redux/library/apiSlice";

// Library
import { zodResolver } from "@hookform/resolvers/zod";

// Utils
import {
	AboutUs,
	Contact,
	Library,
	Platform,
	SocialMediaLink,
} from "src/redux/library/types";
import { LibraryFormSchema } from "src/redux/library/schema";

// Components
import { Form } from "src/shared/components/form/Form";
import { Label } from "src/shared/components/ui/label";
import { Button } from "src/shared/components/ui/button";
import PageHeader from "src/shared/components/layout/header/PageHeader";
import PageContainer from "src/shared/components/layout/container/PageContainer";
import SectionContainer from "src/shared/components/layout/container/SectionContainer";
import SectionHeader from "src/shared/components/layout/header/SectionHeader";
import ConfirmationDialog from "src/shared/components/dialog/ConfirmationDialog";
import ButtonLoading from "src/shared/components/loading-indicator/ButtonLoading";
import Loading from "src/shared/components/loading-indicator/Loading";
import SocialMediaForm from "./fragments/SocialMediaForm";
import ContactInfoForm from "./fragments/ContactInfoForm";
import AboutUsForm from "./fragments/AboutUsForm";
import NotificationDialog from "src/shared/components/dialog/NotificationDialog";
import { useToast } from "src/shared/hooks/useToast";
import { ToastClose } from "src/shared/components/ui/toast";
import { X } from "lucide-react";
import { isErrorResponse } from "src/shared/helpers/errorAssertion";

const LibraryForm = () => {
	const { toast } = useToast();
	const navigate = useNavigate();

	const [_updateAboutUs, { isLoading: isAboutUsUpdateLoading }] =
		useUpdateAboutUsMutation();
	const [_updateSocialMedia, { isLoading: isSocialMediaUpdateLoading }] =
		useUpdateSocialMediaMutation();
	const [_updateContactInfo, { isLoading: isContactInfoUpdateLoading }] =
		useUpdateContactMutation();

	const { data: aboutUs, isLoading: isFetchingLibrary } =
		useGetAboutUsInfoQuery();
	const { data: socialMediaPlatforms, isLoading: isFetchingSocialMedia } =
		useGetSocialMediaLinksQuery();
	const { data: contactInfo, isLoading: isFetchingContactInfo } =
		useGetContactInfoQuery();

	const [isLeaveModalOpen, setIsLeaveModalOpen] = useState(false);
	const [isSaveModalOpen, setIsSaveModalOpen] = useState(false);
	const [isNotifModalOpen, setIsNotifModalOpen] = useState(false);

	const [imagePath, setImagePath] = useState("");

	const form = useForm<Library>({
		resolver: zodResolver(LibraryFormSchema),
		defaultValues: {
			image: "",
			content: "",
			contact_info: {
				email_address: "",
				mobile_no: undefined,
				caremail: "",
				main_office_address: "",
			},
			social_media: [],
		},
		mode: "onTouched",
		reValidateMode: "onSubmit",
		criteriaMode: "all",
	});

	const { isDirty, isValid } = form.formState;

	const _onConfirmLeave = () => {
		form.reset();
		setIsLeaveModalOpen(false);
	};

	const _onCancelPress = () => {
		if (!form.formState.isDirty) navigate("/library");

		setIsLeaveModalOpen(true);
	};

	const _onSavePress = () => {
		setIsSaveModalOpen(true);
	};

	const _onConfirmSave = () => {
		_onSubmit(form.getValues());
	};

	const _onSubmit = async (values: Library) => {
		if (aboutUs && socialMediaPlatforms && contactInfo) {
			const apiCalls: Promise<any>[] = [];

			let isAboutUsUpdated = !(
				aboutUs?.content === values.content && aboutUs?.image === values.image
			);

			let isContactInfoUpdated = !(
				contactInfo?.caremail === values.contact_info.caremail &&
				contactInfo?.email_address === values.contact_info.email_address &&
				contactInfo?.mobile_no === values.contact_info.mobile_no &&
				contactInfo?.main_office_address ===
					values.contact_info.main_office_address
			);

			let isSocialMediaUpdated = socialMediaPlatforms.some(
				(platform, index) => {
					const correspondingValue = values.social_media[index];

					// Compare platform and correspondingValue fields
					return (
						platform.icon !== correspondingValue.icon ||
						platform.platform !== correspondingValue.platform ||
						platform.social_media_link !==
							correspondingValue.social_media_link ||
						!!platform.status !== correspondingValue.status
					);
				}
			);

			const image = `http://example.com/${imagePath}`;

			try {
				setIsSaveModalOpen(false);

				if (isAboutUsUpdated) {
					const aboutUsReqBody: AboutUs = {
						id: aboutUs.id,
						image: image,
						content: values.content,
						created_date: aboutUs.created_date,
						updated_date: aboutUs.updated_date,
						created_from: aboutUs.created_from,
						updated_from: aboutUs.updated_from,
						created_by: aboutUs.created_by,
						updated_by: aboutUs.updated_by,
						country_code: aboutUs.country_code,
						status: aboutUs.status,
						social_media_link: aboutUs.social_media_link,
						contact_info: aboutUs.contact_info,
						is_delete: aboutUs.is_delete,
					};

					const aboutUsPromise = _updateAboutUs(aboutUsReqBody).then(
						(aboutUsResponse) => {
							if (aboutUsResponse.error) {
								throw aboutUsResponse.error;
							}
							return aboutUsResponse;
						}
					);

					apiCalls.push(aboutUsPromise);
				}

				if (isContactInfoUpdated) {
					const contactInfoReqBody: Contact = {
						id: contactInfo?.id,
						email_address: values.contact_info.email_address,
						caremail: values.contact_info.caremail,
						mobile_no: values.contact_info.mobile_no,
						main_office_address: values.contact_info.main_office_address,
						created_date: contactInfo?.created_date,
						is_delete: contactInfo?.is_delete,
						created_from: 1,
						created_by: contactInfo?.created_by,
						updated_by: Number(localStorage.getItem("USER_ID")),
						country_code: contactInfo?.country_code,
						status: 1,
					};

					const contactInfoPromise = _updateContactInfo(
						contactInfoReqBody
					).then((contactInfoResponse) => {
						if (contactInfoResponse.error) {
							throw contactInfoResponse.error;
						}
						return contactInfoResponse;
					});
					apiCalls.push(contactInfoPromise);
				}

				if (isSocialMediaUpdated) {
					const socialMediaPromises = values.social_media.map((socmed) => {
						const socialMediaLinksReqBody: SocialMediaLink = {
							id: socmed.id,
							platform: socmed.platform,
							icon: socmed.icon,
							social_media_link: socmed.social_media_link,
							updated_by: 153,
							updated_from: 1,
							country_code: "US",
							status: !socmed.status ? 0 : 1,
						};

						return _updateSocialMedia(socialMediaLinksReqBody).then(
							(socialMediaResponse) => {
								if (socialMediaResponse.error) {
									throw socialMediaResponse.error;
								}
								return socialMediaResponse;
							}
						);
					});

					apiCalls.push(...socialMediaPromises);
				}

				await Promise.all(apiCalls);

				form.reset(values, {
					keepDirty: false,
					keepTouched: false,
					keepIsValid: false,
					keepErrors: false,
				});
				setTimeout(() => {
					setIsNotifModalOpen(true);
				}, 150);
			} catch (error) {
				if (isErrorResponse(error)) {
					toast({
						description:
							error.status > 499
								? "Something went wrong. Please try again."
								: error.data.message,
						duration: 2000,
						variant: "destructive",
						action: (
							<ToastClose
								className="absolute top-1/2 -translate-y-1/2 right-5 text-white hover:text-gray-200 focus:text-gray-200"
								aria-label="Close"
							>
								<X className="w-5 h-5" />
							</ToastClose>
						),
					});
				}
			}
		}
	};

	const _onNotifOkayPress = () => {
		setIsNotifModalOpen(false);
	};

	useEffect(() => {
		if (
			!isFetchingLibrary ||
			!isFetchingSocialMedia ||
			!isFetchingContactInfo
		) {
			if (aboutUs && Array.isArray(socialMediaPlatforms) && contactInfo) {
				const platforms = socialMediaPlatforms.map((platform) => {
					return {
						id: platform.id,
						status: !!platform.status,
						icon: platform.icon,
						platform: platform.platform,
						social_media_link: platform.social_media_link,
					};
				});

				const library: Library = {
					content: aboutUs.content,
					image: aboutUs.image,
					social_media: platforms,
					contact_info: {
						email_address: contactInfo.email_address,
						mobile_no: contactInfo.mobile_no,
						caremail: contactInfo.caremail,
						main_office_address: contactInfo.main_office_address,
					},
				};

				form.reset(library, {
					keepDirty: false,
					keepTouched: false,
					keepIsValid: false,
					keepErrors: false,
				});
			}
		}
	}, [aboutUs, socialMediaPlatforms, contactInfo]);

	if (isFetchingLibrary || isFetchingSocialMedia || isFetchingContactInfo) {
		return <Loading />;
	}

	return (
		<Form {...form}>
			<div className="w-auto h-auto relative">
				<form onSubmit={form.handleSubmit(_onSubmit)}>
					<PageContainer>
						<PageHeader>
							<Label variant="title">Library</Label>
						</PageHeader>

						{/* TODO Page Content? */}
						<div className="flex flex-col gap-6">
							<Label variant="description">
								Changes will reflect on the app. Last edited on{" "}
								<span className="font-bold">15/08/2022</span> at{" "}
								<span className="font-bold">16:40:51</span>
							</Label>

							<SectionContainer>
								<SectionHeader className="flex-col-reverse sm:flex-row gap-4">
									<div className="flex flex-col gap-2">
										<Label variant="header">About Us</Label>
										<Label variant="description">
											Displayed on the About MR.DIY page of the app.
										</Label>
									</div>
									<div className="grid grid-cols-2 sm:grid-cols-3 gap-2 w-full sm:w-[40%] lg:w-[30%]">
										<Button
											className="col-span-1 sm:col-span-2 xl:w-[80%] xl:ml-auto"
											size="sm"
											disabled={
												!isDirty ||
												!isValid ||
												isAboutUsUpdateLoading ||
												isSocialMediaUpdateLoading ||
												isContactInfoUpdateLoading
											}
											onClick={_onSavePress}
										>
											{isAboutUsUpdateLoading ||
											isSocialMediaUpdateLoading ||
											isContactInfoUpdateLoading ? (
												<ButtonLoading />
											) : (
												"Save"
											)}
										</Button>
										<Button
											className="col-span-1"
											variant="secondary"
											size="sm"
											disabled={
												!isDirty ||
												isAboutUsUpdateLoading ||
												isSocialMediaUpdateLoading ||
												isContactInfoUpdateLoading
											}
											onClick={_onCancelPress}
										>
											Cancel
										</Button>
									</div>
								</SectionHeader>
								<AboutUsForm form={form} setImagePath={setImagePath} />
							</SectionContainer>

							<ContactInfoForm form={form} />
							<SocialMediaForm form={form} />
						</div>
					</PageContainer>
				</form>
			</div>

			<ConfirmationDialog
				title="Save Changes?"
				description="Saving will apply all changes."
				confirmButtonLabel="Continue"
				closeButtonLabel="Cancel"
				modalState={isSaveModalOpen}
				_onCancel={() => setIsSaveModalOpen(false)}
				_onConfirm={_onConfirmSave}
				confirmButtonVariant="default"
			/>

			<ConfirmationDialog
				title="Confirm Cancel?"
				description="Canceling will discard unsaved changes."
				confirmButtonLabel="Continue"
				closeButtonLabel="Cancel"
				modalState={isLeaveModalOpen}
				_onCancel={() => setIsLeaveModalOpen(false)}
				_onConfirm={_onConfirmLeave}
			/>

			<NotificationDialog
				description={"Library updated successfully!"}
				confirmButtonLabel="Ok"
				modalState={isNotifModalOpen}
				_onConfirm={_onNotifOkayPress}
			/>
		</Form>
	);
};

export default LibraryForm;
