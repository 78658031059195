// Types
import { UseFormReturn } from "react-hook-form";
import { Product } from "src/redux/product/types";

// Components
import { FormField, FormMessage } from "src/shared/components/form/Form";
import { Button } from "src/shared/components/ui/button";
import { Input } from "src/shared/components/ui/input";
import { Label } from "src/shared/components/ui/label";
import FormController from "src/shared/components/form/FormController";

// Icon
import { X } from "lucide-react";

type PriceSectionProp = {
	form: UseFormReturn<Product, any, undefined>;
};

const PriceSection = ({ form }: PriceSectionProp) => {
	return (
		<div className="grid lg:grid-cols-2 gap-4">
			<Label variant="sub_header" className="lg:col-span-2">
				Price
			</Label>

			<div className="w-full flex flex-1 gap-6 ml-auto justify-end items-center">
				<div className="relative  w-full">
					<FormField
						control={form.control}
						name="price"
						render={({ field }) => (
							<>
								<FormController
									label="Original"
									required
									hasValidationMessage={false}
								>
									<Input
										type="number"
										step="0.01"
										{...field}
										value={String(field.value) === "0" ? "0.00" : field.value}
										onChange={(e) => {
											const value = e.target.value || "0.00";
											field.onChange(value);
										}}
									/>
								</FormController>
								<div className="absolute bottom-0 translate-y-3 lg:translate-y-4">
									{form.formState.errors.price && (
										<FormMessage className="text-destructive-foreground">
											<>{form.formState.errors.price}</>
										</FormMessage>
									)}
								</div>
							</>
						)}
					/>
					<Button
						variant="icon"
						size="icon"
						className="absolute right-1 top-11 transform -translate-y-1/2 text-disabled-foreground"
						onClick={() => {
							form.resetField("price");
						}}
					>
						<X className=" w-[14px] h-[14px]" />
					</Button>
				</div>
			</div>

			<div className="w-full flex flex-1 gap-6 ml-auto justify-end items-center">
				<div className="relative w-full">
					<FormField
						control={form.control}
						name="discounted_price"
						render={({ field }) => (
							<>
								<FormController label="Discounted" hasValidationMessage={false}>
									<Input
										type="number"
										step="0.01"
										{...field}
										value={String(field.value) === "0" ? "0.00" : field.value}
										onChange={(e) => {
											const value = e.target.value || "0.00";
											field.onChange(value);
										}}
									/>
								</FormController>
								<div className="absolute bottom-0 translate-y-3 lg:translate-y-4">
									{form.formState.errors.discounted_price && (
										<FormMessage className="text-destructive-foreground">
											<>{form.formState.errors.discounted_price}</>
										</FormMessage>
									)}
								</div>
							</>
						)}
					/>
					<Button
						variant="icon"
						size="icon"
						className="absolute right-1 top-11 transform -translate-y-1/2 text-disabled-foreground"
						onClick={() => form.resetField("discounted_price")}
					>
						<X className=" w-[14px] h-[14px]" />
					</Button>
				</div>
			</div>
		</div>
	);
};

export default PriceSection;
