import { AccountLoggedIn } from "../account/types";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { SuccessResponse } from "../types";

type Credential = {
	email: string;
	password: string;
};

export const authApi = createApi({
	reducerPath: "authApi",
	baseQuery: fetchBaseQuery({
		baseUrl: process.env.REACT_APP_API_URL,
	}),
	endpoints: (builder) => ({
		login: builder.mutation<SuccessResponse<AccountLoggedIn>, Credential>({
			query: (credentials) => ({
				url: "/auth",
				method: "POST",
				body: { ...credentials, authType: "user", imei: "A" },
			}),
		}),
	}),
});

export const { useLoginMutation } = authApi;
