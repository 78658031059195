import {
	AlertDialog,
	AlertDialogContent,
	AlertDialogDescription,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogTitle,
} from "src/shared/components/ui/alert-dialog";
import { Label } from "../ui/label";
import { Button } from "../ui/button";
import ButtonLoading from "../loading-indicator/ButtonLoading";

type LeavePageDialogProps = {
	description: string;
	confirmButtonLabel: string;
	modalState: boolean;
	_onConfirm: () => void;
};

const NotificationDialog = ({
	description,
	confirmButtonLabel,
	modalState,
	_onConfirm,
}: LeavePageDialogProps) => {
	return (
		<AlertDialog open={modalState} onOpenChange={_onConfirm}>
			<AlertDialogContent className="w-[80%] max-w-[16em] lg:max-w-[18em] xl:max-w-[20em] 2xl:max-w-[25em] 2xl:w-[20%] xl:space-y-3 sm:pt-8 lg:space-y-2 rounded-2xl sm:rounded-2xl lg:rounded-2xl xl:rounded-2xl">
				<AlertDialogHeader className="space-y-0 sm:text-center lg:space-y-2">
					<AlertDialogDescription>
						<Label
							variant="description"
							className="text-secondary sm:text-xs md:text-sm 2xl:text-base font-semibold leading-none"
						>
							{description}
						</Label>
					</AlertDialogDescription>
				</AlertDialogHeader>
				<AlertDialogFooter className="flex flex-row gap-2 sm:gap-0 w-full lg:w-[90%] lg:mx-auto">
					<Button
						variant="default"
						size="sm"
						className="w-fit px-12 mx-auto 2xl:text-base 2xl:py-5 2xl:px-14"
						onClick={_onConfirm}
					>
						{confirmButtonLabel}
					</Button>
				</AlertDialogFooter>
			</AlertDialogContent>
		</AlertDialog>
	);
};

export default NotificationDialog;
