export type CustomErrorResponse = {
	data: {
		data: unknown;
		status: number;
		message: string;
	};
	status: number;
};

export const isErrorResponse = (
	error: unknown
): error is CustomErrorResponse => {
	return (
		typeof error === "object" &&
		error != null &&
		"status" in error &&
		"data" in error &&
		typeof (error as any).data?.message === "string"
	);
};

export type NotFoundErrorResponse = {
	status: number;
	data?: any;
};

export const isErrorResult = (
	result: any
): result is { error: NotFoundErrorResponse } => {
	return result && "error" in result;
};
