import { UseFormReturn, useWatch } from "react-hook-form";
import { useEffect } from "react";
import { useSelector } from "react-redux";

// Types
import { RootState } from "src/redux/store";
import { Store } from "src/redux/store/types";

// Utils
import { _handleLocationChange } from "src/redux/store/locationSlice";

// Component
import { Input } from "src/shared/components/ui/input";
import { FormField, FormMessage } from "src/shared/components/form/Form";
import LongitudeSvg from "src/assets/svg/LongitudeSvg";
import LatitudeSvg from "src/assets/svg/LatitudeSvg";
import FormController from "src/shared/components/form/FormController";
import MapComponent from "src/shared/components/map/Map";
import { Label } from "src/shared/components/ui/label";
import {
	RadioGroup,
	RadioGroupItem,
} from "src/shared/components/ui/radio-group";
import SectionHeader from "src/shared/components/layout/header/SectionHeader";

type LocationSectionProps = {
	form: UseFormReturn<Store, any, undefined>;
	selectedStore?: Store;
};

const LocationSection = ({ form, selectedStore }: LocationSectionProps) => {
	const locationSlice = useSelector((state: RootState) => state.location);

	const lat = useWatch({
		control: form.control,
		name: "latitude",
	});

	useEffect(() => {
		form.setValue("city", locationSlice.city, { shouldDirty: true });
		form.setValue("latitude", locationSlice.lat, { shouldDirty: true });
		form.setValue("longitude", locationSlice.long, { shouldDirty: true });
		const postalCode = form.watch("postal_code");

		if (postalCode) {
			form.trigger();
		}
	}, [locationSlice, lat]);

	return (
		<>
			<SectionHeader className="lg:col-span-3">
				<Label variant="header">Store Information</Label>
			</SectionHeader>
			<div className="lg:col-span-1">
				<FormField
					control={form.control}
					name="name"
					render={({ field }) => (
						<FormController label="Store Name" required>
							<Input {...field} />
						</FormController>
					)}
				/>
			</div>
			<div className="lg:col-span-2">
				<FormField
					control={form.control}
					name="address"
					render={({ field }) => (
						<FormController label="Detailed Address" required>
							<Input {...field} />
						</FormController>
					)}
				/>
			</div>

			<div className="row-start-5 lg:col-span-1 lg:row-span-3">
				<FormController label="Pin Location">
					<div>
						<MapComponent
							defaultCenter={{
								lat: !selectedStore ? 14.22016 : selectedStore.latitude,
								lng: !selectedStore ? 121.03616 : selectedStore.longitude,
							}}
						/>
						{form.formState.errors.longitude && (
							<FormMessage className="text-destructive">
								<>{form.formState.errors.longitude.message}</>
							</FormMessage>
						)}
					</div>
				</FormController>
			</div>
			<div className="grid lg:grid-cols-2 lg:col-span-2 lg:col-start-2 gap-6">
				<div>
					<FormField
						control={form.control}
						name="store_code"
						render={({ field }) => (
							<FormController label="Store Code" required>
								<Input {...field} />
							</FormController>
						)}
					/>
				</div>

				<div>
					<FormField
						control={form.control}
						name="system_name"
						render={({ field }) => (
							<FormController label="System Name" required>
								<Input {...field} />
							</FormController>
						)}
					/>
				</div>
				<div>
					<FormField
						control={form.control}
						name="postal_code"
						render={({ field }) => (
							<FormController label="Postal Code" required>
								<Input {...field} />
							</FormController>
						)}
					/>
				</div>
				<div>
					<FormField
						control={form.control}
						name="city"
						render={({ field }) => (
							<FormController label="City/Municipality" required>
								<Input {...field} disabled={!!locationSlice.city} />
							</FormController>
						)}
					/>
				</div>
				<div>
					<FormField
						control={form.control}
						name="opening_hrs"
						render={({ field }) => (
							<FormController label="Opening Hours" required>
								<Input {...field} type="string" />
							</FormController>
						)}
					/>
				</div>
				<div>
					<FormField
						control={form.control}
						name="landline_no"
						render={({ field }) => (
							<FormController label="Landline No." required>
								<Input {...field} type="string" />
							</FormController>
						)}
					/>
				</div>
				<div>
					<div className="row items-center">
						<LongitudeSvg className="mr-2" fill={"#000"} />
						<label className="text-disabled-foreground text-[0.65rem] lg:text-sm tracking-tight">
							Longitude
							<span className="text-destructive-foreground">*</span>
						</label>
					</div>
					<FormField
						control={form.control}
						name="longitude"
						render={({ field }) => (
							<FormController hasValidationMessage={false}>
								<Input {...field} type="number" step="any" disabled />
							</FormController>
						)}
					/>
				</div>
				<div>
					<div className="row items-center">
						<LatitudeSvg className="mr-2" fill={"#000"} />
						<label className="text-disabled-foreground text-[0.65rem] lg:text-sm tracking-tight">
							Latitude<span className="text-destructive-foreground">*</span>
						</label>
					</div>
					<FormField
						control={form.control}
						name="latitude"
						render={({ field }) => (
							<FormController hasValidationMessage={false}>
								<Input {...field} type="number" step="any" disabled />
							</FormController>
						)}
					/>
				</div>
				<div>
					<FormField
						control={form.control}
						name="status"
						render={({ field }) => (
							<FormController label="Status" required>
								<RadioGroup
									onValueChange={(value) => field.onChange(Number(value))}
									value={String(field.value)}
									className="flex gap-4 mt-2"
								>
									<div className="flex items-center space-x-2">
										<RadioGroupItem value="1" id="active" />
										<Label
											htmlFor="active"
											variant="form"
											className="text-secondary"
										>
											Active
										</Label>
									</div>
									<div className="flex items-center space-x-2">
										<RadioGroupItem value="0" id="inactive" />
										<Label
											htmlFor="inactive"
											variant="form"
											className="text-secondary"
										>
											Inactive
										</Label>
									</div>
								</RadioGroup>
							</FormController>
						)}
					/>
				</div>
			</div>
		</>
	);
};

export default LocationSection;
