import { RootState } from "src/redux/store";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { toggleSmallScreenNav } from "src/redux/slices/sidebarSlice";

// Components
import NavigationList from "./NavigationList";
import ProfileAvatar from "../avatar/ProfileAvatar";
import { Command } from "src/shared/components/ui/command";
import SidebarToggleButton from "../../button/SidebarToggleButton";

// Icons & Images
import MRDIYlogo from "src/assets/images/MRDIYlogo.png";
import diyLogo from "src/assets/images/mini_diy_logo.png";
import ImageBackground from "src/shared/components/ImageBackground";
import { RxHamburgerMenu } from "react-icons/rx";
import { useAuth } from "src/providers/AuthProvider";

type LargeScreenProps = {
	isLargeScreenNavCollapsed: boolean;
};

const SideBar = () => {
	const dispatch = useDispatch();
	const { permissions } = useAuth();

	const isLargeScreenNavCollapsed = useSelector(
		(state: RootState) => state.sidebar.isLargeScreenNavCollapsed
	);
	const isSmallScreenNavOpen = useSelector(
		(state: RootState) => state.sidebar.isSmallScreenNavOpen
	);

	// Handle opening/closing small screen navigation
	const handleSmallScreenNavToggle = () => {
		dispatch(toggleSmallScreenNav());
	};

	return (
		<aside
			className={`h-fit w-full flex flex-row relative transition-all duration-500 ease-in-out z-[9999] xl:h-screen
     ${!isLargeScreenNavCollapsed ? "xl:w-[18em]" : "xl:w-[110px]"}`}
		>
			<Command className="flex flex-row justify-between bg-[#DCDCDC] rounded-none shadow-md w-full xl:flex-col xl:justify-start">
				<div id="small-nav" className="h-fit w-fit my-auto xl:hidden">
					<SidebarToggleButton
						icon={RxHamburgerMenu}
						screen="Small"
						className="m-auto ml-2 shadow-none drop-shadow-none border-[2px] border-black/75 bg-transparent  active:bg-transparent"
					/>
				</div>
				{isLargeScreenNavCollapsed ? (
					<ImageBackground
						id="logo-icon"
						className="h-20 w-20 my-1 mx-auto bg-contain bg-no-repeat bg-center"
						style={{ backgroundImage: `url(${diyLogo})` }}
					/>
				) : (
					<ImageBackground
						id="logo"
						className="h-20 w-32 sm:w-32 xl:w-44 mx-auto bg-contain bg-no-repeat bg-center inline xl:my-4"
						style={{ backgroundImage: `url(${MRDIYlogo})` }}
					/>
				)}
				<NavigationList
					isLargeScreenNavCollapsed={isLargeScreenNavCollapsed}
					isSmallScreenNavOpen={isSmallScreenNavOpen}
					setIsSmallScreenNavOpen={handleSmallScreenNavToggle}
				/>
				<div className="flex xl:hidden mr-2">
					<ProfileAvatar
						isLargeScreenNavCollapsed={isLargeScreenNavCollapsed}
					/>
				</div>
			</Command>
		</aside>
	);
};

export default SideBar;
