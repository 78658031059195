import { createBrowserRouter } from "react-router-dom";

// Component
import ProtectedRoute from "./shared/components/ProtectedRoute";

// Layout
import RootLayout from "./shared/components/layout/root/RootLayout";
import AuthLayout from "./shared/components/layout/auth/AuthLayout";

// Modules
import AccountPage from "./modules/account";
import BannerPage from "./modules/banner";
import CustomerPage from "./modules/customer";
import CategoryPage from "./modules/category";
import StorePage from "./modules/store";
import ResetPassword from "./modules/reset-password/ResetPassword";
import ForgotPasswordPage from "./modules/auth/ForgotPassword";

import UserPage from "./modules/user";
import LoginForm from "./modules/auth/LoginForm";

// Forms
import UserForm from "./modules/user/UserForm";
import BannerForm from "./modules/banner/BannerForm";
import CustomerForm from "./modules/customer/CustomerForm";
import ProductForm from "./modules/product/fragments/ProductForm";
import LibraryForm from "./modules/library";
import StoreForm from "./modules/store/StoreForm";
import ProductPage from "./modules/product";
import RolePage from "./modules/roles";
import RoleForm from "./modules/roles/RoleForm";
import BannerProductsList from "./modules/banner/fragments/BannerProductsList";
import ImportStoreForm from "./modules/store/fragments/ImportStoreForm";
import ImportProductForm from "./modules/product/fragments/ImportProductForm";
import ServerIssuePage from "./shared/components/ServerIssuePage";

export const router = createBrowserRouter([
	{
		element: (
			<ProtectedRoute>
				<RootLayout />
			</ProtectedRoute>
		),
		path: "/",
		children: [
			{
				path: "user",
				element: <UserPage />,
			},
			{
				path: "user/add",
				element: <UserForm />,
			},
			{
				path: "user/:userId",
				element: <UserForm />,
			},
			{
				path: "role",
				element: <RolePage />,
			},
			{
				path: "role/add",
				element: <RoleForm />,
			},
			{
				path: "role/:roleId",
				element: <RoleForm />,
			},
			{
				path: "customer",
				element: <CustomerPage />,
			},
			{
				path: "customer/:customerId",
				element: <CustomerForm />,
			},
			{
				path: "product",
				element: <ProductPage />,
			},
			{
				path: "product/add",
				element: <ProductForm />,
			},
			{
				path: "product/:productId",
				element: <ProductForm />,
			},
			{
				path: "product/import",
				element: <ImportProductForm />,
			},
			{
				path: "store",
				element: <StorePage />,
			},
			{
				path: "store/add",
				element: <StoreForm />,
			},
			{
				path: "store/:storeId",
				element: <StoreForm />,
			},
			{
				path: "store/import",
				element: <ImportStoreForm />,
			},
			{
				path: "banner",
				element: <BannerPage />,
			},
			{
				path: "banner/add",
				element: <BannerForm />,
			},
			{
				path: "banner/:bannerId",
				element: <BannerForm />,
			},
			{
				path: "banner/add/products",
				element: <BannerProductsList />,
			},
			{
				path: "banner/:bannerId/add/products",
				element: <BannerProductsList />,
			},
			{
				path: "library",
				element: <LibraryForm />,
			},
			{
				path: "account",
				element: <AccountPage />,
			},
			{
				path: "category",
				element: <CategoryPage />,
			},
		],
	},
	{
		path: "/auth/*",
		element: <AuthLayout />,
		children: [
			{
				index: true,
				element: <LoginForm />,
			},
			{
				path: "forgot-password",
				element: <ForgotPasswordPage />,
			},
			{
				path: "reset-password",
				element: <ResetPassword />,
			},
		],
	},
	{
		path: "*", // Catch-all route for unmatched paths
		element: <ServerIssuePage />,
	},
]);
