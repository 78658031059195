import React from "react";
import { UseFormReturn } from "react-hook-form";

// Types

// Components
import { Input } from "src/shared/components/ui/input";
import { Label } from "src/shared/components/ui/label";
import {
	Select,
	SelectContent,
	SelectGroup,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from "src/shared/components/ui/select";
import { FormDescription, FormField } from "src/shared/components/form/Form";
import { Textarea } from "src/shared/components/ui/textarea";
import FormController from "src/shared/components/form/FormController";
import UploadButton from "src/shared/components/button/UploadButton";

// Image
import image from "src/assets/images/image.png";
import { Banner } from "src/redux/banner/types";
import { Category } from "src/redux/category/types";
import {
	validateFileExtension,
	validateFileSize,
} from "src/shared/helpers/validateAttachment";

type BannerInformationSectionProps = {
	form: UseFormReturn<Banner, any, undefined>;
	categoriesOption: Category[];
};

const BannerInformationSection = ({
	form,
	categoriesOption,
}: BannerInformationSectionProps) => {
	return (
		<div className="grid lg:grid-cols-2 lg:gap-x-8 space-y-2">
			<div className="lg:col-start-1">
				<FormField
					control={form.control}
					name="title"
					render={({ field }) => (
						<FormController label="Banner Title" required>
							<Input {...field} />
						</FormController>
					)}
				/>
			</div>

			<div className="lg:col-start-1">
				<FormField
					control={form.control}
					name="categoryId"
					render={({ field }) => (
						<FormController label="Banner Category">
							<Select
								onValueChange={(value) => field.onChange(Number(value))}
								value={String(field.value)}
							>
								<SelectTrigger
									className={`pl-4 ${
										!field.value ? "text-gray-300" : "text-secondary"
									}`}
								>
									<SelectValue placeholder="Select a category" />
								</SelectTrigger>
								<SelectContent>
									<SelectGroup>
										<SelectItem value="0" disabled>
											No Category
										</SelectItem>
										{categoriesOption.map((category) => {
											return (
												<SelectItem
													value={String(category.id)}
													key={category.id}
												>
													{category.name}
												</SelectItem>
											);
										})}
									</SelectGroup>
								</SelectContent>
							</Select>
						</FormController>
					)}
				/>
			</div>

			<div className="lg:col-start-1">
				<FormField
					control={form.control}
					name="description"
					render={({ field }) => (
						<FormController label="Banner Description" required>
							<Textarea
								id="content"
								rows={11}
								wrap="soft"
								placeholder="..."
								className="p-1"
								{...field}
							/>
						</FormController>
					)}
				/>
			</div>

			<div className="mt-8 lg:mt-0 lg:row-start-1 lg:row-span-3 lg:col-start-2">
				<FormField
					control={form.control}
					name="image"
					render={({ field: { value, onChange, ...fieldProps } }) => (
						<>
							<FormController label="Banner Image" required>
								<div className="relative h-[15em] lg:h-[20em] border-[1px] border-secondary rounded-md overflow-hidden">
									{value && (
										<div
											className="absolute inset-0 bg-cover bg-no-repeat bg-center opacity-70"
											style={{
												backgroundImage: `url(${value})`,
											}}
										/>
									)}

									<div className="relative h-full w-full flex items-center justify-center">
										{!value && (
											<div className="absolute h-20 w-20 top-14 lg:top-[30%] flex items-center justify-center">
												<img src={image} alt="upload-image" />
											</div>
										)}

										<div className="absolute bottom-10 lg:bottom-1/4">
											<UploadButton
												buttonText="Upload Image"
												accept="image/*"
												onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
													const file = e.target.files?.[0];

													if (file) {
														const isAttachmentValid = validateFileSize({
															file: file,
															form: form,
															errorMessage: "Image size must not exceed 2mb.",
															fieldName: "image",
															sizeLimit: 2,
														});

														const isExtensionValid = validateFileExtension({
															file: file,
															form: form,
															errorMessage:
																"Invalid file type. Please upload a .png, .jpg, or .jpeg file.",
															fieldName: "image",
															validExtensions: [
																"image/jpeg",
																"image/jpg",
																"image/png",
															],
														});

														if (!isExtensionValid || !isAttachmentValid) {
															return;
														} else {
															form.clearErrors();
														}

														const imageUrl = URL.createObjectURL(file);

														onChange(imageUrl);

														// Here you would typically upload the file to your server
														// and then update the form with the returned URL
														// uploadFileToServer(file).then(url => onChange(url));
													}
												}}
												value="" // Keep the file input value empty
												{...fieldProps}
											/>
										</div>
									</div>
								</div>
							</FormController>
							<FormDescription
								className={`flex flex-col gap-1 lg:gap-0 leading-tight tracking-tight text-secondary ${
									form.formState.errors.image ? "mt-1" : "mt-0"
								}`}
							>
								<Label variant="description">
									&bull; Recommended image resolution is minimum of{" "}
									<span className="font-bold">400px</span> by{" "}
									<span className="font-bold">400px</span>
								</Label>
								<Label variant="description">
									&bull; Image file size should be no more than{" "}
									<span className="font-bold">2mb</span>
								</Label>
								<Label variant="description">
									&bull; Allowed image formats are{" "}
									<span className="font-bold">.jpg or .jpeg</span> and{" "}
									<span className="font-bold">.png</span> only
								</Label>
							</FormDescription>
						</>
					)}
				/>
			</div>
		</div>
	);
};

export default BannerInformationSection;
