import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useCallback, useState } from "react";
import { useValidStoreDataColumns } from "../hooks/useValidStoreData";
import { useInvalidStoreDataColumns } from "../hooks/useInvalidStoreData";
import { useGetStoresQuery } from "src/redux/store/apiSlice";
import { useSelector } from "react-redux";

// Libraries
import { zodResolver } from "@hookform/resolvers/zod";
import * as XLSX from "xlsx";

// Types
import { InvalidStore, Store } from "src/redux/store/types";
import { RootState } from "src/redux/store";

// Utils
import {
	_downloadFile,
	SheetData,
	StoreHeaders,
} from "src/shared/helpers/exportFile";
import { ImportStoreSchema, StoreFormSchema } from "src/redux/store/schema";

// Component
import { Button } from "src/shared/components/ui/button";
import { Input } from "src/shared/components/ui/input";
import { Label } from "src/shared/components/ui/label";
import { Form } from "src/shared/components/form/Form";
import { DataTable } from "src/shared/components/table/DataTable";
import PageContainer from "src/shared/components/layout/container/PageContainer";
import FormHeader from "src/shared/components/layout/header/FormHeader";
import SectionContainer from "src/shared/components/layout/container/SectionContainer";
import ConfirmationDialog from "src/shared/components/dialog/ConfirmationDialog";
import Loading from "src/shared/components/loading-indicator/Loading";

// Icons
import { LoaderCircle } from "lucide-react";
import DownloadIconSvg from "src/assets/svg/DownloadIconSvg";
import SuccessImportDataIconSvg from "src/assets/svg/SuccessImportDataIconSvg";
import ImportIconSvg from "src/assets/svg/ImportIconSvg";
import ImportDataIcon from "src/assets/svg/ImportDataIconSvg";
import ButtonLoading from "src/shared/components/loading-indicator/ButtonLoading";
import { useDuplicatedStoreDataColumns } from "../hooks/useDuplicatedStoreData";

const ImportStoreForm = () => {
	const navigate = useNavigate();
	const { isLargeScreenNavCollapsed } = useSelector(
		(state: RootState) => state.sidebar
	);

	const [isLeaveModalOpen, setIsLeaveModalOpen] = useState(false);

	const { data: stores, isFetching: isStoreFetching } = useGetStoresQuery();
	const [selectedRows, setSelectedRows] = useState<Store[]>([]);
	const [duplicatedData, setDuplicatedData] = useState<SheetData[]>([]);

	const validDataColumns = useValidStoreDataColumns();
	const invalidDataColumns = useInvalidStoreDataColumns();
	const duplicatedDataColumns = useDuplicatedStoreDataColumns();

	// ? Can be removed if no need for other validation in frontend
	const form = useForm<Store>({
		resolver: zodResolver(StoreFormSchema),
		defaultValues: {
			name: "",
			address: "",
			postal_code: "",
			city: "",
			longitude: 0,
			latitude: 0,
			opening_hrs: "",
			landline_no: "",
			status: 0,
		},
		mode: "onChange",
		reValidateMode: "onSubmit",
		criteriaMode: "all",
	});

	const { isDirty } = form.formState;
	const [fileError, setFileError] = useState("");
	const [data, setData] = useState<SheetData[]>([]);
	const [validData, setValidData] = useState<SheetData[]>([]);
	const [invalidData, setInvalidData] = useState<SheetData[]>([]);
	const [isUploading, setIsUploading] = useState(false);
	const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);

	const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
		console.log("Handle upload");
		e.preventDefault();

		const fileInput = e.target;
		const file = fileInput.files?.[0];
		if (!file || !Array.isArray(stores)) return;

		const filename = file.name;

		if (
			filename.includes("Store_Template") ||
			filename.includes("Invalid_Store") ||
			filename.includes("Duplicated_Store")
		) {
			const reader = new FileReader();

			reader.onload = (event) => {
				const workbook = XLSX.read(event.target?.result as string, {
					type: "binary",
				});
				const sheetName = workbook.SheetNames[0];
				const sheet = workbook.Sheets[sheetName];
				const headers = XLSX.utils.sheet_to_json(sheet, {
					header: 1,
				})[0] as String[];
				const sheetData = XLSX.utils.sheet_to_json<SheetData>(sheet);

				const requiredHeaders = Object.keys(StoreHeaders);

				for (const header of requiredHeaders) {
					if (!headers.includes(header)) {
						setIsUploading(false);
						setFileError("Headers don't match to template headers.");
						return;
					}
				}

				const headerMap: Record<string, keyof Store> = {
					Store_Code: "store_code",
					System_Name: "system_name",
					Name: "name",
					Address: "address",
					Postal_Code: "postal_code",
					City: "city",
					Latitude: "latitude",
					Longitude: "longitude",
					Opening_Hrs: "opening_hrs",
					Landline_Number: "landline_no",
				};

				const remappedData = sheetData.map((row) => {
					const remappedRow: SheetData = {};

					for (const [excelKey, schemaKey] of Object.entries(headerMap)) {
						if (excelKey in row) {
							// Apply specific transformations based on the field type
							switch (schemaKey) {
								case "latitude":
								case "longitude":
									remappedRow[schemaKey] = Number(row[excelKey]);
									break;
								default:
									remappedRow[schemaKey] = String(row[excelKey]);
							}
						}
					}

					return remappedRow;
				});

				// Validate data and separate valid/invalid rows
				const validRows: SheetData[] = [];
				const invalidRows: InvalidStore[] = [];

				const uniqueStores = remappedData.filter(
					(store1, index) =>
						!remappedData.some(
							(store2, index2) =>
								index !== index2 && store1.store_code === store2.store_code
						) &&
						(!filename.includes("Duplicated")
							? !stores.some((store) => store.store_code === store1.store_code)
							: true)
				);

				const duplicates = remappedData.filter(
					(store1, index) =>
						remappedData.some(
							(store2, index2) =>
								index !== index2 && store1.store_code === store2.store_code
						) ||
						(!filename.includes("Duplicated") &&
							stores?.some((store) => store.store_code === store1.store_code))
				);

				// Validate data and separate valid/invalid rows
				uniqueStores.forEach((row, index) => {
					// Validate remaining rows
					const result = ImportStoreSchema.safeParse(row);

					if (result.success) {
						validRows.push(result.data);
					} else {
						let errMsg = "There is an error in";

						if (result.error.errors.length === 2) {
							const errorFields = result.error.errors.map((err) => {
								const name = String(err.path[0]).replace(/_/g, " ");
								return name.charAt(0).toUpperCase() + name.slice(1);
							});
							errMsg += ` ${errorFields.join(", ")}`;
						} else if (result.error.errors.length >= 3) {
							errMsg =
								"There are multiple issues in this row. Please review all columns thoroughly to ensure accuracy.";
						} else if (result.error.errors.length === 1) {
							errMsg = result.error.errors[0].message;
						}

						const invalidRow: any = {
							id: index + 1,
							row: index + 1,
							reason: errMsg,
							name: row.name || "",
							address: row.address || "",
							postal_code: row.postal_code || "",
							city: row.city || "",
							longitude: row.longitude ?? 0,
							store_code: row.store_code || "",
							system_name: row.system_name || "",
							latitude: row.latitude ?? 0,
							landline_no: row.landline_no,
							opening_hrs: row.opening_hrs,
						};
						invalidRows.push(invalidRow);
					}
				});

				setData(remappedData);
				setValidData(validRows);
				setInvalidData(invalidRows);
				setDuplicatedData(duplicates);
				setIsUploading(false);
			};

			setIsUploading(true);
			setTimeout(() => {
				reader.readAsBinaryString(file);
			}, 2000);
		} else {
			setFileError("Please make sure to use the template provided.");
			setIsUploading(false);
			return;
		}

		fileInput.value = "";
	};

	const handleOverwrite = () => {
		setIsUploading(true);
		// Validate data and separate valid/invalid rows
		const validRows: SheetData[] = [];
		const invalidRows: InvalidStore[] = [];

		const uniqueStores = selectedRows.filter(
			(store1, index) =>
				!selectedRows.some(
					(store2, index2) =>
						index !== index2 && store1.store_code === store2.store_code
				)
		);

		const duplicates = selectedRows.filter((store1, index) =>
			selectedRows.some(
				(store2, index2) =>
					index !== index2 && store1.store_code === store2.store_code
			)
		);

		uniqueStores.forEach((row, index) => {
			const result = ImportStoreSchema.safeParse(row);

			if (result.success) {
				validRows.push(result.data);
			} else {
				let errMsg = "There is an error in";

				if (result.error.errors.length == 2) {
					const errorFields = result.error.errors.map((err) => {
						const name = String(err.path[0]).replace(/_/g, " ");
						return name.charAt(0).toUpperCase() + name.slice(1);
					});
					errMsg += ` ${errorFields.join(", ")}`;
				} else if (result.error.errors.length >= 3) {
					errMsg =
						"There are multiple issues in this row. Please review all columns thoroughly to ensure accuracy.";
				} else if (result.error.errors.length === 1) {
					errMsg = result.error.errors[0].message;
				}

				const errorMessage = errMsg;

				const invalidRow: any = {
					id: index + 1,
					row: index + 1,
					reason: errorMessage,
					name: row.name || "",
					address: row.address || "",
					postal_code: row.postal_code || "",
					city: row.city || "",
					longitude: row.longitude ?? 0,
					store_code: row.store_code || "",
					system_name: row.system_name || "",
					latitude: row.latitude ?? 0,
					landline_no: row.landline_no,
					opening_hrs: row.opening_hrs,
				};
				invalidRows.push(invalidRow);
			}
		});

		setTimeout(() => {
			handleSelectionChange([]);
			setData(selectedRows);
			setValidData(validRows);
			setInvalidData(invalidRows);
			setDuplicatedData(duplicates);
			setIsUploading(false);
		}, 2000);
	};

	const handleSelectionChange = useCallback((newSelectedRows: Store[]) => {
		setSelectedRows(newSelectedRows);
	}, []);

	const _onConfirmSave = () => {
		handleOverwrite();
		setIsConfirmModalOpen(false);
	};

	const _onConfirmLeave = () => {
		navigate("/store");

		form.reset();
	};

	const onCancelPress = () => {
		if (!isDirty) navigate("/store");

		setIsLeaveModalOpen(true);
	};

	if (isStoreFetching) {
		return <Loading />;
	}

	return (
		<Form {...form}>
			<form>
				<PageContainer>
					<FormHeader>
						<Label variant="title">Import Store Data</Label>
						<div className="w-full h-fit sm:w-fit flex gap-2">
							<Button
								variant="secondary"
								size="md"
								type="reset"
								onClick={onCancelPress}
							>
								Cancel
							</Button>
						</div>
					</FormHeader>

					<SectionContainer>
						<div className="mt-4">
							<Label variant="sub_header">Upload Store Data</Label>
						</div>

						<div className="grid place-items-center px-10 w-full h-[20em] lg:h-[12em] rounded-xl border-[1px] border-gray-300">
							<div className="flex flex-col gap-4 items-center lg:justify-between lg:h-full lg:py-8">
								<Label
									variant="description"
									className="tracking-tight text-center"
								>
									Click the Upload Button and choose a{" "}
									<span className="font-semibold">.xlsx</span> file to import
									store data
								</Label>
								{isUploading ? (
									<LoaderCircle className="h-12 w-12 text-secondary animate-spin" />
								) : (
									<>
										{data.length > 0 ? (
											<SuccessImportDataIconSvg
												fill="currentColor"
												className="h-16 w-16 lg:h-14 lg:w-14"
											/>
										) : (
											<ImportDataIcon
												fill="#292D32"
												className="h-16 w-16 lg:h-14 lg:w-14"
											/>
										)}
									</>
								)}
							</div>
						</div>

						<div className="w-fit h-fit mx-auto">
							<Input
								id="input"
								type="file"
								className="hidden"
								onChange={handleFileUpload}
							/>
							<Button
								type="button"
								onClick={() => {
									setFileError("");
									document.getElementById("input")?.click();
								}}
							>
								<ImportIconSvg fill="currentColor" className="w-5 h-5" />
								Upload File
							</Button>
						</div>
						{fileError && (
							<Label className="text-destructive w-fit mx-auto">
								{fileError}
							</Label>
						)}
					</SectionContainer>

					<div className="flex flex-col gap-4 lg:gap-8 mt-8 z-10">
						<Label variant="sub_header">Upload Results</Label>

						<div className="flex flex-col max-w-full gap-2">
							<Label variant="form">Valid Data</Label>

							<div className="w-full">
								<DataTable
									data={validData as Store[]}
									columns={validDataColumns}
									emptyTableText="No Store Data has been Imported."
								/>
							</div>
						</div>

						<div
							className={`w-full max-w-full ${
								isLargeScreenNavCollapsed == false
									? "xl:max-w-[calc(100vw-24em)]"
									: "xl:max-w-full"
							}`}
						>
							<div className="w-full flex justify-between items-center">
								<Label variant="form">Invalid Data</Label>
								<Button
									size="sm"
									variant="secondary"
									className="w-fit"
									disabled={invalidData.length == 0}
									onClick={() =>
										_downloadFile(invalidData as InvalidStore[], "Store")
									}
								>
									<DownloadIconSvg
										fill={invalidData.length == 0 ? "#cbcbcc" : "white"}
										className="h-5 w-5"
									/>{" "}
									Download File
								</Button>
							</div>

							<div className="w-full">
								<DataTable
									data={invalidData as InvalidStore[]}
									columns={invalidDataColumns}
									emptyTableText="No Store Data has been Imported."
								/>
							</div>
						</div>

						{duplicatedData.length >= 1 ? (
							<div className="flex flex-col max-w-full gap-2 mt-4 lg:mt-6">
								<div className="flex flex-row justify-between items-center">
									{" "}
									<Label variant="form">Duplicated Data</Label>
									<div className="flex gap-2">
										<Button
											size="sm"
											variant="default"
											className="w-fit px-10"
											disabled={selectedRows.length == 0 || isUploading}
											onClick={() => setIsConfirmModalOpen(true)}
										>
											{isUploading ? <ButtonLoading /> : <>Overwrite</>}
										</Button>
										<Button
											size="sm"
											variant="secondary"
											className="w-fit"
											disabled={duplicatedData.length == 0}
											onClick={() =>
												_downloadFile(
													duplicatedData as Store[],
													"Duplicated Store"
												)
											}
										>
											<DownloadIconSvg
												fill={duplicatedData.length == 0 ? "#cbcbcc" : "white"}
												className="h-5 w-5"
											/>{" "}
											Download File
										</Button>
									</div>
								</div>

								<div className="w-full">
									<DataTable
										data={duplicatedData as Store[]}
										columns={duplicatedDataColumns}
										hasPagination={duplicatedData.length >= 10}
										emptyTableText="No product Data has been Imported."
										onSelectionChange={handleSelectionChange}
									/>
								</div>
							</div>
						) : null}
					</div>
				</PageContainer>
			</form>

			<ConfirmationDialog
				title="Leave page?"
				description="Changes are not yet saved."
				confirmButtonLabel="Leave"
				closeButtonLabel="Cancel"
				modalState={isLeaveModalOpen}
				_onCancel={() => setIsLeaveModalOpen(false)}
				_onConfirm={_onConfirmLeave}
			/>

			<ConfirmationDialog
				title="Overwrite existing data?"
				description="Once confirmed data will be overwritten."
				confirmButtonLabel="Continue"
				closeButtonLabel="Cancel"
				modalState={isConfirmModalOpen}
				_onCancel={() => setIsConfirmModalOpen(false)}
				_onConfirm={_onConfirmSave}
				confirmButtonVariant="default"
			/>
		</Form>
	);
};
export default ImportStoreForm;
