import { z } from "zod";

export const CategoryFormSchema = z
	.object({
		name: z
			.string({ message: "Please enter category name." })
			.min(2, {
				message: "Category name must be at least 2 characters.",
			})
			.max(30, {
				message: "Category name must not exceed 20 characters.",
			}),
		image: z.union([z.instanceof(File), z.string().url()]),
	})
	.strict();
