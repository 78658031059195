import { useCallback, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useUserColumns } from "./columns";
import {
	useGetUsersQuery,
	useDeleteUserMutation,
	useDeleteMultipleUsersMutation,
} from "src/redux/user/apiSlice";

// Types
import { UserWithFullNameAndRole } from "src/redux/user/types";

// Components
import { Label } from "src/shared/components/ui/label";
import { DataTable } from "src/shared/components/table/DataTable";
import { Button } from "src/shared/components/ui/button";
import PageContainer from "src/shared/components/layout/container/PageContainer";
import PageHeader from "src/shared/components/layout/header/PageHeader";
import PageControls from "src/shared/components/control/PageControls";
import SearchInput from "src/shared/components/textfield/SearchInput";
import ConfirmationDialog from "src/shared/components/dialog/ConfirmationDialog";
import Loading from "src/shared/components/loading-indicator/Loading";

// Icons
import ButtonDeleteIconSvg from "src/assets/svg/ButtonDeleteIconSvg";
import ButtonAddIconSvg from "src/assets/svg/ButtonAddIconSvg";
import NotificationDialog from "src/shared/components/dialog/NotificationDialog";

const UserPage = () => {
	const navigate = useNavigate();
	const { data: usersArray = [], isFetching: isUsersArrayFetching } =
		useGetUsersQuery();
	const [_deleteUser] = useDeleteUserMutation();
	const [_deleteUsers] = useDeleteMultipleUsersMutation();

	// ! Remove me on API Integration and use isLoading by rtk query
	const [showLoading, setShowLoading] = useState(false);

	const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
	const [isMultipleDeleteModalOpen, setIsMultipleDeleteModalOpen] =
		useState(false);
	const [isDeleteNotifModalOpen, setIsDeleteNotifModalOpen] = useState(false);
	const [searchValue, setSearchValue] = useState("");
	const [selectedUser, setSelectedUser] = useState({
		id: 0,
		name: "",
	});
	const [selectedRows, setSelectedRows] = useState<UserWithFullNameAndRole[]>(
		[]
	);

	const filteredUsers = useMemo(() => {
		return usersArray.filter(
			(user) =>
				user.name.toLowerCase().includes(searchValue.toLowerCase()) ||
				user.email.toLowerCase().includes(searchValue.toLowerCase()) ||
				user.roleName.toLowerCase().includes(searchValue.toLowerCase())
		);
	}, [searchValue, usersArray]);

	const handleSelectionChange = useCallback(
		(newSelectedRows: UserWithFullNameAndRole[]) => {
			setSelectedRows(newSelectedRows);
		},
		[]
	);

	const _onOpenDeleteModal = (id: number, name: string) => {
		setIsDeleteModalOpen(true);
		setSelectedUser({
			id: id,
			name: name,
		});
	};

	const _onCloseDeleteModal = () => {
		setIsDeleteModalOpen(false);
	};

	const _onConfirmDelete = async () => {
		setShowLoading(true);
		setTimeout(async () => {
			setShowLoading(false);
			setIsDeleteModalOpen(false);
			setTimeout(() => {
				setIsDeleteNotifModalOpen(true);
			}, 250);
		}, 2000);
	};

	const _onOpenMultipleDeleteModal = () => {
		setIsMultipleDeleteModalOpen(true);
	};

	const _onCloseMultipleDeleteModal = () => {
		setIsMultipleDeleteModalOpen(false);
	};

	const _onConfirmMultipleDelete = async () => {
		setShowLoading(true);
		setTimeout(async () => {
			setShowLoading(false);
			setIsMultipleDeleteModalOpen(false);
			setTimeout(() => {
				setIsDeleteNotifModalOpen(true);
			}, 250);
		}, 2000);
	};

	const _onDeleteNotifOkayPress = () => {
		setIsDeleteNotifModalOpen(false);
	};

	const columns = useUserColumns(_onOpenDeleteModal);

	if (isUsersArrayFetching) {
		return <Loading />;
	}

	return (
		<PageContainer>
			<PageHeader>
				<Label variant="title">Users List</Label>
			</PageHeader>

			<PageControls>
				<div className="flex gap-2 w-full sm:w-[15em]">
					<Button onClick={() => navigate("add")}>
						<ButtonAddIconSvg fill="#292d32" className="h-6 w-6" />
						Add User
					</Button>
					<Button
						variant="destructive"
						size="md"
						disabled={selectedRows.length < 1}
						onClick={_onOpenMultipleDeleteModal}
					>
						<ButtonDeleteIconSvg
							fill={`${selectedRows.length < 1 ? "#cbcbcc" : "white"}`}
							className="h-5 w-5 mb-[2px]"
						/>
						Delete
					</Button>
				</div>
				<div className="w-full xl:w-fit flex flex-row-reverse sm:flex-row gap-6 ml-auto justify-end items-center">
					<SearchInput setSearchValue={setSearchValue} />
				</div>
			</PageControls>

			<div className="mt-8">
				<DataTable
					data={filteredUsers}
					columns={columns}
					onSelectionChange={handleSelectionChange}
					hasPagination={usersArray.length > 10}
				/>
			</div>

			<ConfirmationDialog
				title="Delete User?"
				description={`User ${selectedUser.name} will be deleted`}
				closeButtonLabel="Cancel"
				confirmButtonLabel="Delete"
				confirmButtonIcon={ButtonDeleteIconSvg}
				modalState={isDeleteModalOpen}
				isLoading={showLoading}
				_onConfirm={_onConfirmDelete}
				_onCancel={_onCloseDeleteModal}
			/>

			<ConfirmationDialog
				title={`Delete Selected Users?`}
				description={`${selectedRows.length} users will be deleted`}
				closeButtonLabel="Cancel"
				confirmButtonLabel="Delete"
				confirmButtonIcon={ButtonDeleteIconSvg}
				modalState={isMultipleDeleteModalOpen}
				isLoading={showLoading}
				_onConfirm={_onConfirmMultipleDelete}
				_onCancel={_onCloseMultipleDeleteModal}
			/>

			<NotificationDialog
				description={
					selectedRows.length > 0
						? "Users has been deleted successfully!"
						: "User has been deleted successfully!"
				}
				confirmButtonLabel="Ok"
				modalState={isDeleteNotifModalOpen}
				_onConfirm={_onDeleteNotifOkayPress}
			/>
		</PageContainer>
	);
};

export default UserPage;
