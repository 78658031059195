import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useGetSelectedRoleQuery } from "../../redux/role/apiSlice";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";

// Libraries
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";

// Utils
import { Access, Permission } from "../../redux/role/types";
import { RoleFormSchema } from "../../redux/role/schema";

// Component
import { Button } from "src/shared/components/ui/button";
import { Input } from "src/shared/components/ui/input";
import { Label } from "src/shared/components/ui/label";
import { Checkbox } from "src/shared/components/ui/checkbox";
import { Form, FormField, FormMessage } from "src/shared/components/form/Form";
import PageContainer from "src/shared/components/layout/container/PageContainer";
import SectionContainer from "src/shared/components/layout/container/SectionContainer";
import SectionHeader from "src/shared/components/layout/header/SectionHeader";
import ButtonCheckIconSvg from "src/assets/svg/ButtonCheckIconSvg";
import FormHeader from "src/shared/components/layout/header/FormHeader";
import FormController from "src/shared/components/form/FormController";
import ConfirmationDialog from "src/shared/components/dialog/ConfirmationDialog";
import Loading from "src/shared/components/loading-indicator/Loading";
import ButtonLoading from "src/shared/components/loading-indicator/ButtonLoading";
import NotificationDialog from "src/shared/components/dialog/NotificationDialog";

const MODULES = [
	"Banner Management",
	"Users List",
	"Roles & Permission",
	"Customer Management",
	"Product Management",
	"Category Management",
	"Store Management",
	"Library",
	"Account Settings",
];

const RoleForm = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const pathSegments = location.pathname.split("/");
	const { roleId } = useParams<{ roleId: string }>();

	const { data: selectedRole, isFetching: isSelectedRoleFetching } =
		useGetSelectedRoleQuery({ id: Number(roleId) });

	const [showLoading, setShowLoading] = useState(false);

	const [isLeaveModalOpen, setIsLeaveModalOpen] = useState(false);
	const [isSaveModalOpen, setIsSaveModalOpen] = useState(false);
	const [isNotifModalOpen, setIsNotifModalOpen] = useState(false);

	const [isAllAccessFalse, setIsAllAccessFalse] = useState(false);
	const [rolePermissions, setRolePermissions] = useState({
		permissions: MODULES.map(
			(module) =>
				({
					module,
					access: {
						view: false,
						add: false,
						edit: false,
						delete: false,
					} as Access,
				} as Permission)
		),
	});

	const form = useForm({
		resolver: zodResolver(RoleFormSchema),
		defaultValues: {
			name: "",
			description: "",
		},
		mode: "onChange",
		reValidateMode: "onSubmit",
		criteriaMode: "all",
	});

	const { isDirty, isValid } = form.formState;

	const _onConfirmLeave = () => {
		navigate("/role");

		form.reset();
	};

	const _onCancelPress = () => {
		if (!isDirty) navigate("/role");

		setIsLeaveModalOpen(true);
	};

	const _onSavePress = () => {
		const allAccessFalse = rolePermissions.permissions.every(
			(permission: Permission) =>
				Object.values(permission.access).every((access) => access === false)
		);

		if (!allAccessFalse) {
			setIsSaveModalOpen(true);
		} else {
			setIsAllAccessFalse(true);
		}
	};

	const _onConfirmSave = () => {
		_onSubmit(form.getValues());
	};

	const _onSubmit = (values: z.infer<typeof RoleFormSchema>) => {
		if (values) {
			setShowLoading(true);
			setTimeout(async () => {
				setShowLoading(false);
				setIsNotifModalOpen(true);
			}, 2000);
			setIsSaveModalOpen(false);
		}
	};

	const _onNotifOkayPress = () => {
		setIsNotifModalOpen(false);
		navigate("/role");
	};

	const _handlePermissionChange = (
		moduleIndex: number,
		accessType: keyof Access,
		checked: boolean
	) => {
		const newPermissions = rolePermissions.permissions.map(
			(permission, index) => {
				if (index === moduleIndex) {
					if (accessType === "view" && !checked) {
						// When unchecking view, set ALL access types to false
						return {
							...permission,
							access: {
								view: false,
								add: false,
								edit: false,
								delete: false,
							},
						};
					} else {
						// For other cases, just update the specific access
						return {
							...permission,
							access: {
								...permission.access,
								[accessType]: checked,
							},
						};
					}
				}
				return permission;
			}
		);

		const allAccessFalse = newPermissions.every((permission: Permission) =>
			Object.values(permission.access).every((access) => access === false)
		);

		setIsAllAccessFalse(allAccessFalse);
		setRolePermissions({ permissions: newPermissions });
	};

	useEffect(() => {
		if (selectedRole) {
			form.reset(
				{
					name: selectedRole.name,
					description: selectedRole.description,
				},
				{
					keepDirty: false,
					keepTouched: false,
					keepIsValid: false,
					keepErrors: false,
				}
			);

			setRolePermissions((prevState) => ({
				...prevState,
				permissions: prevState.permissions.map((permission) => {
					const matchedPermission = selectedRole.permissions.find(
						(selectedPermission) =>
							selectedPermission.module === permission.module
					);
					return matchedPermission
						? { ...permission, access: matchedPermission.access }
						: permission;
				}),
			}));
		}
	}, [selectedRole, form]);

	if (isSelectedRoleFetching) {
		return <Loading />;
	}

	if (!isSelectedRoleFetching && !selectedRole) {
		if (pathSegments[2] != "add") {
			navigate("/role", { replace: true });
		}
	}

	return (
		<Form {...form}>
			<div className="h-auto relative">
				<form onSubmit={form.handleSubmit(_onSubmit)}>
					<PageContainer>
						<FormHeader>
							<Label variant="title">Roles & Permissions</Label>
							<div className="w-full h-fit sm:w-fit flex gap-2">
								<Button
									disabled={!isValid || showLoading}
									onClick={_onSavePress}
								>
									{showLoading ? (
										<ButtonLoading />
									) : (
										<>
											<ButtonCheckIconSvg
												className="h-5 w-5"
												fill={!isValid ? "#cbcbcc" : "#292D32"}
											/>
											Save
										</>
									)}
								</Button>
								<Button
									variant="secondary"
									size="md"
									type="button"
									onClick={_onCancelPress}
									disabled={showLoading}
								>
									Cancel
								</Button>
							</div>
						</FormHeader>

						<SectionContainer className="lg:grid-cols-3">
							<SectionHeader className="lg:col-span-3">
								<Label variant="header">
									{!selectedRole?.id
										? "Add Role & Permissions"
										: "Edit Role & Permissions"}
								</Label>
							</SectionHeader>

							<div>
								<FormField
									control={form.control}
									name="name"
									render={({ field }) => (
										<FormController label="Role Name">
											<Input {...field} />
										</FormController>
									)}
								/>
							</div>
							<div className="lg:col-span-2">
								<FormField
									control={form.control}
									name="description"
									render={({ field }) => (
										<FormController label="Description">
											<Input {...field} />
										</FormController>
									)}
								/>
							</div>
						</SectionContainer>

						<SectionContainer className="mt-6 lg:mt-8 mb-4 sm:mb-0">
							<SectionHeader className="grid space-y-2 sm:space-y-1 lg:mb-4">
								<Label className="text-[0.85rem] sm:text-[0.90rem] lg:text-base">
									Module Permissions
								</Label>
								<Label variant="description">
									*Unchecked module hides that module to that role. Unchecked
									permissions sets the module to be viewable only by that role.
								</Label>
								{isAllAccessFalse ? (
									<FormMessage className="text-destructive">
										You need to choose 1 permission.
									</FormMessage>
								) : null}
							</SectionHeader>
						</SectionContainer>

						<div className="flex flex-col">
							<div
								className="grid grid-cols-3 pb-2 border-b-[1px] border-gray-400 border-t-none
							border-x-none sm:mb-0 lg:m-0 px-2 mb-0"
							>
								<Label variant="description" className="col-span-1 font-bold">
									Module
								</Label>
								<Label variant="description" className="col-span-2 font-bold">
									Permission
								</Label>
							</div>
							<div className="overflow-auto mt-2 lg:mt-4">
								{rolePermissions.permissions.map((permission, index) => (
									<div
										className={`col-span-3 grid grid-cols-3 px-2 pb-4 ${
											permission.module.includes("Store Management")
												? "mb-4 border-b-[1px] border-gray-400"
												: ""
										}`}
										key={permission.module}
									>
										<Label variant="description" className="col-span-1 mr-2">
											{permission.module}
										</Label>
										<div className="col-span-2 flex gap-8 items-center">
											{Object.entries(permission.access).map(
												([accessType, accessValue]) => {
													const formattedAccess =
														accessType[0].toUpperCase() + accessType.slice(1);

													const isViewAccessChecked =
														permission.access["view"] === true;

													const isDisabled =
														accessType !== "view" && !isViewAccessChecked;

													const handleCheckedChange = (checked: boolean) => {
														if (accessType === "view") {
															if (!checked) {
																// When unchecking view, set all other permissions to false
																Object.keys(permission.access).forEach(
																	(type) => {
																		if (type !== "view") {
																			_handlePermissionChange(
																				index,
																				type as keyof Access,
																				false
																			);
																		}
																	}
																);
															}
														}
														// Always update the clicked permission
														_handlePermissionChange(
															index,
															accessType as keyof Access,
															checked
														);
													};

													return (
														<div
															key={accessType}
															className="flex justify-center items-center gap-1 lg:gap-2"
														>
															<Checkbox
																className="w-3 h-3 xl:w-4 xl:h-4"
																checked={accessValue}
																disabled={isDisabled || showLoading}
																onCheckedChange={handleCheckedChange}
															/>
															<Label variant="description">
																{formattedAccess}
															</Label>
														</div>
													);
												}
											)}
										</div>
									</div>
								))}
							</div>
						</div>
					</PageContainer>
					<ConfirmationDialog
						title="Leave page?"
						description="Changes are not yet saved."
						confirmButtonLabel="Leave"
						closeButtonLabel="Cancel"
						modalState={isLeaveModalOpen}
						_onCancel={() => setIsLeaveModalOpen(false)}
						_onConfirm={_onConfirmLeave}
					/>

					<ConfirmationDialog
						title="Save Changes?"
						description="Saving will apply all changes."
						confirmButtonLabel="Continue"
						closeButtonLabel="Cancel"
						modalState={isSaveModalOpen}
						_onCancel={() => setIsSaveModalOpen(false)}
						_onConfirm={_onConfirmSave}
						confirmButtonVariant="default"
					/>

					<NotificationDialog
						description={
							!roleId
								? "Role has been added successfully!"
								: "Role has been updated successfully!"
						}
						confirmButtonLabel="Ok"
						modalState={isNotifModalOpen}
						_onConfirm={_onNotifOkayPress}
					/>
				</form>
			</div>
		</Form>
	);
};

export default RoleForm;
