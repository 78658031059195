import { Search } from "lucide-react";
import { Button } from "src/shared/components/ui/button";
import { Input } from "src/shared/components/ui/input";
import { Label } from "src/shared/components/ui/label";

const LevelThreeForm = () => {
	return (
		<div className="col-span-3 flex flex-col gap-6 h-[35em] lg:h-[70vh] xl:h-[72vh] w-full items-center lg:col-span-1">
			<Label className="text-[0.825rem] sm:text-sm py-2">Level 3</Label>

			<div className="w-full">
				<div className="relative w-full row-start-1">
					<Search className="absolute left-0 bottom-0 transform -translate-y-1/2 w-[14px] h-[14px] md:w-4 md:h-4 text-secondary" />
					<Input
						placeholder="Search"
						className="pl-6 lg:pl-8 text-[0.65rem] sm:text-sm"
						disabled
					/>
				</div>
			</div>
			<Button size="sm" className="w-fit" disabled>
				Add Category
			</Button>

			<div className="hidden xl:flex flex-col gap-4 h-[25em] max-h-[25em] px-2 w-full overflow-auto"></div>
		</div>
	);
};

export default LevelThreeForm;
