import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useGetRolesQuery } from "../../redux/role/apiSlice";
import {
	useGetCurrentUserAccountQuery,
	useUpdateAccountMutation,
} from "src/redux/account/apiSlice";

// Types
import { Account } from "src/redux/account/types";

// Utils
import { AccountFormSchema } from "src/redux/account/schema";

// Libraries
import { zodResolver } from "@hookform/resolvers/zod";

// Components
import { Form } from "src/shared/components/form/Form";
import AccountFormDescription from "./fragments/AccountFormDescription";
import AccountFormHeader from "./fragments/AccountFormHeader";
import AccountFormImageSection from "./fragments/AccountFormImageSection";
import AccountFormDetailsSection from "./fragments/AccountFormDetailsSection";
import PageContainer from "src/shared/components/layout/container/PageContainer";
import Loading from "src/shared/components/loading-indicator/Loading";
import ConfirmationDialog from "src/shared/components/dialog/ConfirmationDialog";
import NotificationDialog from "src/shared/components/dialog/NotificationDialog";

const AccountForm = () => {
	const navigate = useNavigate();

	const { data: roles, isFetching: isFetchingRoles } = useGetRolesQuery();
	// const { data: currentUser, isFetching: isFetchingUser } =
	// 	useGetCurrentUserAccountQuery(auth?.authUser?.id ?? 0);
	const { data: currentUser, isFetching: isFetchingUser } =
		useGetCurrentUserAccountQuery(0);
	const [_updateAccount] = useUpdateAccountMutation();
	// ! Remove me on API Integration and use isLoading by rtk query
	const [showLoading, setShowLoading] = useState(false);

	const [isLeaveModalOpen, setIsLeaveModalOpen] = useState(false);
	const [isSaveModalOpen, setIsSaveModalOpen] = useState(false);
	const [isNotifModalOpen, setIsNotifModalOpen] = useState(false);

	const form = useForm<Account>({
		resolver: zodResolver(AccountFormSchema),
		mode: "onTouched",
		reValidateMode: "onBlur",
		criteriaMode: "all",
	});

	const _onConfirmLeave = () => {
		form.reset();
		setIsLeaveModalOpen(false);
	};

	const _onCancelPress = () => {
		if (!form.formState.isDirty) navigate("/account");

		setIsLeaveModalOpen(true);
	};

	const _onSavePress = () => {
		setIsSaveModalOpen(true);
	};

	const _onConfirmSave = () => {
		_onSubmit(form.getValues());
	};

	const _onSubmit = (values: Account) => {
		if (values) {
			setShowLoading(true);
			setTimeout(async () => {
				await _updateAccount(values);
				form.reset(
					{
						email: values?.email,
						firstName: values?.firstName,
						lastName: values?.lastName,
						roleId: values?.roleId,
						image: values?.image,
					},
					{
						keepDirty: false,
						keepTouched: false,
						keepIsValid: false,
						keepErrors: false,
					}
				);

				setShowLoading(false);
				setTimeout(() => {
					setIsNotifModalOpen(true);
				}, 150);
			}, 2000);

			setIsSaveModalOpen(false);
		}
	};

	const _onNotifOkayPress = () => {
		setIsNotifModalOpen(false);
	};

	useEffect(() => {
		if (currentUser) {
			form.reset(
				{
					email: currentUser?.email,
					firstName: currentUser?.firstName,
					lastName: currentUser?.lastName,
					roleId: currentUser?.roleId,
					image: currentUser?.image,
				},
				{
					keepDirty: false,
					keepTouched: false,
					keepIsValid: false,
					keepErrors: false,
				}
			);
		}
	}, [currentUser, form]);

	if (isFetchingUser || isFetchingRoles) {
		return <Loading />;
	}
	return (
		<Form {...form}>
			<div className="h-auto w-auto relative">
				<form onSubmit={form.handleSubmit(_onSubmit)}>
					<PageContainer>
						<AccountFormHeader
							isDirty={form.formState.isDirty}
							isValid={form.formState.isValid}
							isLoading={showLoading}
							_onCancelPress={_onCancelPress}
							_onSavePress={_onSavePress}
						/>
						<AccountFormDescription />

						<div className="mt-10 grid grid-rows-3 sm:grid-rows-1 grid-cols-1 sm:grid-cols-6 gap-8 w-full h-full">
							<AccountFormImageSection form={form} />
							<AccountFormDetailsSection form={form} roleOptions={roles} />
						</div>
					</PageContainer>
				</form>
			</div>

			<ConfirmationDialog
				title="Save Changes?"
				description="Saving will apply all changes."
				confirmButtonLabel="Continue"
				closeButtonLabel="Cancel"
				modalState={isSaveModalOpen}
				_onCancel={() => setIsSaveModalOpen(false)}
				_onConfirm={_onConfirmSave}
				confirmButtonVariant="default"
			/>

			<ConfirmationDialog
				title="Confirm Cancel?"
				description="Canceling will discard unsaved changes."
				confirmButtonLabel="Continue"
				closeButtonLabel="Cancel"
				modalState={isLeaveModalOpen}
				_onCancel={() => setIsLeaveModalOpen(false)}
				_onConfirm={_onConfirmLeave}
			/>

			<NotificationDialog
				description={"Account has been updated successfully!"}
				confirmButtonLabel="Ok"
				modalState={isNotifModalOpen}
				_onConfirm={_onNotifOkayPress}
			/>
		</Form>
	);
};

export default AccountForm;
