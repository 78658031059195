import { createApi } from "@reduxjs/toolkit/query/react";
import { Customer, CustomerWithFullName, UpdateCustomerType } from "./types";
import {
	MultipleDeleteApiResponse,
	SuccessMultipleDataResponse,
} from "../types";
import { baseQueryWithReauth } from "src/shared/helpers/prepareBaseQuery";

export const customersApi = createApi({
	reducerPath: "customersApi",
	tagTypes: ["Customers", "Customer"],
	baseQuery: baseQueryWithReauth,
	endpoints: (builder) => ({
		getCustomers: builder.query<CustomerWithFullName[], void>({
			query: () => ({
				url: "/customer/get_all",
				method: "GET",
			}),
			transformResponse: ({
				status,
				data,
				message,
			}: SuccessMultipleDataResponse<Customer>) => {
				const customersWithFullName: CustomerWithFullName[] = data.map(
					(customer) => {
						return {
							...customer,
							name: `${customer.firstname} ${customer.lastname}`,
						};
					}
				);
				return customersWithFullName;
			},
			providesTags: ["Customers"],
			keepUnusedDataFor: 0,
		}),
		getSelectedCustomer: builder.query<Customer, number>({
			query: (id: number) => ({
				url: `customer/${id}`,
				method: "GET",
			}),
			transformResponse: ({
				status,
				data,
				message,
			}: SuccessMultipleDataResponse<Customer>) => {
				return data[0];
			},
			keepUnusedDataFor: 0,
		}),
		updateCustomer: builder.mutation<void, UpdateCustomerType>({
			query: (updatedCustomer) => ({
				url: `/customer/${updatedCustomer.id}`,
				method: "PATCH",
				body: updatedCustomer,
			}),
		}),
		deleteCustomer: builder.mutation<void, number>({
			query: (id) => ({
				url: `/user/${id}`,
				method: "DELETE",
			}),
			invalidatesTags: ["Customers"],
		}),
		deleteMultipleCustomers: builder.mutation<
			MultipleDeleteApiResponse,
			Customer[]
		>({
			query: (customers) => {
				return {
					url: "/delete",
					method: "PUT",
					body: customers,
				};
			},
		}),
	}),
});

export const {
	useGetCustomersQuery,
	useGetSelectedCustomerQuery,
	useUpdateCustomerMutation,
	useDeleteCustomerMutation,
	useDeleteMultipleCustomersMutation,
} = customersApi;
