// Type
import { Banner, BannerAPI } from "./types";

// Data
import bannersArray from "../../data/banner/banners.json";
// import { createBaseQueryWithAuth } from "src/shared/helpers/prepareBaseQuery";
import { RootState } from "../store";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { formatDate, formatDateToIso } from "src/shared/helpers/formatDate";
import { SuccessMultipleDataResponse, SuccessResponse } from "../types";
import { baseQueryWithReauth } from "src/shared/helpers/prepareBaseQuery";

const BASE_URL = process.env.REACT_APP_API_URL;

export const bannersApi = createApi({
	reducerPath: "bannersApi",
	tagTypes: ["Banners", "Banner"],
	baseQuery: baseQueryWithReauth,
	endpoints: (builder) => ({
		getActiveBanners: builder.query<BannerAPI[], void>({
			query: () => ({
				url: "/banner/get_all",
				method: "GET",
			}),
			transformResponse: ({
				status,
				data,
				message,
			}: SuccessMultipleDataResponse<BannerAPI>) => {
				const activeBanners = data.filter(
					(banner: BannerAPI) => banner.status === 0
				);

				const banners = activeBanners.map((banner) => {
					// console.log("===>", formatDateToIso(banner.startDate));
					return {
						...banner,
						startDate: formatDate(banner.startDate),
						endDate: formatDate(banner.endDate),
					};
				});

				return activeBanners;
			},
			keepUnusedDataFor: 0,
		}),
		getInactiveBanners: builder.query<Banner[], void>({
			queryFn() {
				return new Promise<{ data: Banner[] }>((resolve, reject) => {
					try {
						setTimeout(() => {
							const inactiveBanners = bannersArray.filter(
								(banner: Banner) => banner.status === 0
							);

							resolve({ data: inactiveBanners });
						}, 750);
					} catch (error) {
						reject({ error: error as Error });
					}
				});
			},
		}),
		getSelectedBanner: builder.query<Banner, { id: number }>({
			async queryFn({ id }) {
				return new Promise<{ data: Banner }>((resolve, reject) => {
					try {
						setTimeout(async () => {
							const selectedBanner = bannersArray.find(
								(banner) => id === banner.id
							);

							if (selectedBanner) {
								// Convert the image path to a Blob and create a URL
								const response = await fetch(selectedBanner.image);
								const blob = await response.blob();
								const imageUrl = URL.createObjectURL(blob);

								const transformedBanner = {
									...selectedBanner,
									image: imageUrl, // This will be something like "blob:http://localhost:3000/1234-5678"
								};

								resolve({
									data: transformedBanner as Banner,
								});
							} else {
								reject({ error: new Error("Banner not found") });
							}
						}, 750);
					} catch (error) {
						reject({ error: error as Error });
					}
				});
			},
			keepUnusedDataFor: 0,
		}),
		addBanner: builder.mutation<void, Banner>({
			query: (newBanner) => ({
				url: "/new",
				method: "POST",
				body: newBanner,
			}),
			invalidatesTags: ["Banners"],
		}),
		updateBanner: builder.mutation<void, Banner>({
			query: (updatedBanner) => ({
				url: `/update/${updatedBanner.id}`,
				method: "PUT",
				body: updatedBanner,
			}),
			invalidatesTags: ["Banners"],
		}),
		deleteBanner: builder.mutation<void, string>({
			query: (id) => ({
				url: `/delete/${id}`,
				method: "DELETE",
			}),
			invalidatesTags: ["Banners"],
		}),
	}),
});

export const {
	useGetActiveBannersQuery,
	useGetInactiveBannersQuery,
	useGetSelectedBannerQuery,
	useAddBannerMutation,
	useUpdateBannerMutation,
	useDeleteBannerMutation,
} = bannersApi;

// TODO: Integrate force refetch. combine active and inactive if needs to refetch the data everytime
// forceRefetch
// Prevents caching
// keepUnusedDataFor: 0,
// providesTags: [{ type: "Banners", id: "ACTIVE" }],
