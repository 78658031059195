import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import userArray from "src/data/user/users.json";
import rolesArray from "src/data/role/roles.json";

import { User, UserWithFullNameAndRole } from "src/redux/user/types";
import { MultipleDeleteApiResponse } from "src/redux/types";

export const usersApi = createApi({
	reducerPath: "usersApi",
	tagTypes: ["Users", "User"],
	baseQuery: fetchBaseQuery({ baseUrl: "/" }),
	endpoints: (builder) => ({
		getUsers: builder.query<UserWithFullNameAndRole[], void>({
			async queryFn() {
				return new Promise<{ data: UserWithFullNameAndRole[] }>(
					(resolve, reject) => {
						try {
							setTimeout(() => {
								const usersWithFullName = userArray
									.map((user: User) => {
										const foundRole = rolesArray.find(
											(role) => user.roleId === role.id
										);

										if (foundRole) {
											return {
												...user,
												name: `${user.firstName} ${user.lastName}`,
												roleName: foundRole.name,
											};
										} else {
											return undefined;
										}
									})
									.filter(
										(user): user is UserWithFullNameAndRole =>
											user !== undefined
									);

								// Ensure you have a valid result
								if (usersWithFullName.length > 0) {
									resolve({
										data: usersWithFullName,
									});
								} else {
									reject({
										message: "No valid users found!",
									});
								}
							}, 1000);
						} catch (error) {
							reject({ error: error as Error });
						}
					}
				);
			},
		}),
		getSelectedUser: builder.query<User, number>({
			async queryFn(id) {
				return new Promise<{ data: User }>((resolve, reject) => {
					try {
						setTimeout(async () => {
							const selectedUser = userArray.find((user) => id === user.id);

							if (selectedUser) {
								resolve({
									data: selectedUser,
								});
							} else {
								reject({ error: new Error("User not found") });
							}
						}, 1000);
					} catch (error) {
						reject({ error: error as Error });
					}
				});
			},
			keepUnusedDataFor: 0,
		}),
		addUser: builder.mutation<void, User>({
			query: (newUser) => ({
				url: "/new",
				method: "POST",
				body: newUser,
			}),
			invalidatesTags: ["Users"],
		}),
		updateUser: builder.mutation<void, User>({
			query: (updatedUser) => ({
				url: `/user/${updatedUser.id}`,
				method: "PUT",
				body: updatedUser,
			}),
			invalidatesTags: ["Users"],
		}),
		deleteUser: builder.mutation<UserWithFullNameAndRole[], number>({
			query: (id) => ({
				url: `/delete/${id}`,
				method: "DELETE",
			}),
			invalidatesTags: ["Users"],
		}),
		deleteMultipleUsers: builder.mutation<MultipleDeleteApiResponse, User[]>({
			query: (data) => {
				return {
					url: "/delete",
					method: "PUT",
					body: data,
				};
			},
		}),
	}),
});

export const {
	useGetUsersQuery,
	useGetSelectedUserQuery,
	useAddUserMutation,
	useUpdateUserMutation,
	useDeleteUserMutation,
	useDeleteMultipleUsersMutation,
} = usersApi;
