// Types
import { ColumnDef } from "@tanstack/react-table";
import { Store } from "src/redux/store/types";

// Icons
import { FaSort } from "react-icons/fa";
import { Checkbox } from "src/shared/components/ui/checkbox";
import { CheckedState } from "@radix-ui/react-checkbox";

type StoreColumnProps = (id: number, name: string) => void;

export const useDuplicatedStoreDataColumns = (
	_onOpenDeleteModal?: StoreColumnProps
): ColumnDef<Store>[] => {
	const columns: ColumnDef<Store>[] = [
		{
			id: "select",
			header: ({ table }) => (
				<Checkbox
					checked={
						table.getIsAllPageRowsSelected() ||
						(table.getIsSomePageRowsSelected() && "indeterminate")
					}
					onCheckedChange={(value: CheckedState) =>
						table.toggleAllPageRowsSelected(!!value)
					}
					aria-label="Select all"
					className="w-[14px] h-[14px] ml-2"
				/>
			),
			cell: ({ row }) => (
				<Checkbox
					checked={row.getIsSelected()}
					onCheckedChange={(value) => row.toggleSelected(!!value)}
					aria-label="Select row"
					className="w-[14px] h-[14px] ml-2"
				/>
			),
			enableSorting: false,
			enableHiding: false,
		},
		{
			accessorKey: "store_code",
			header: ({ column }) => (
				<button
					type="button"
					className="flex justify-between w-fit"
					onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
				>
					<FaSort className="h-3 w-3 group-hover:cursor-pointer text-gray-400 my-auto mr-1" />
					<p className="font-bold">
						Store
						<br />
						Code
					</p>
				</button>
			),
			cell: ({ row }) => {
				const storeCode: string = row.getValue("store_code");
				return (
					<div
						className="w-[6ch] overflow-hidden text-ellipsis whitespace-nowrap text-center"
						title={storeCode}
					>
						{row.getValue("store_code")}
					</div>
				);
			},
		},
		{
			accessorKey: "system_name",
			header: ({ column }) => (
				<button
					type="button"
					className="flex justify-between w-fit"
					onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
				>
					<FaSort className="h-3 w-3 group-hover:cursor-pointer text-gray-400 my-auto mr-1" />
					<p className="font-bold">
						System
						<br />
						Name
					</p>
				</button>
			),
			cell: ({ row }) => {
				const systemName: string = row.getValue("system_name");
				return (
					<div
						className="w-[8ch] overflow-hidden text-ellipsis whitespace-nowrap text-center"
						title={systemName}
					>
						{row.getValue("system_name")}
					</div>
				);
			},
		},
		{
			accessorKey: "name",
			header: ({ column }) => (
				<button
					type="button"
					className="flex justify-between w-fit"
					onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
				>
					<FaSort className="h-3 w-3 group-hover:cursor-pointer text-gray-400 my-auto mr-1" />
					<p className="font-bold">Name</p>
				</button>
			),
			cell: ({ row }) => {
				const storeName: string = row.getValue("name");
				return (
					<div
						className="w-[12ch] overflow-hidden text-ellipsis whitespace-nowrap text-center"
						title={storeName}
					>
						{row.getValue("name")}
					</div>
				);
			},
		},
		{
			accessorKey: "address",
			header: ({ column }) => (
				<button
					type="button"
					className="flex justify-between w-fit"
					onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
				>
					<FaSort className="h-3 w-3 group-hover:cursor-pointer text-gray-400 my-auto mr-1" />
					<p className="font-bold">Address</p>
				</button>
			),
			cell: ({ row }) => {
				return (
					<div className="w-[8em] overflow-hidden text-ellipsis line-clamp-2">
						{row.getValue("address")}
					</div>
				);
			},
		},
		{
			accessorKey: "postal_code",
			header: ({ column }) => (
				<button
					type="button"
					className="flex justify-between w-fit"
					onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
				>
					<FaSort className="h-3 w-3 group-hover:cursor-pointer text-gray-400 my-auto mr-1" />
					<p className="font-bold">Code</p>
				</button>
			),
			cell: ({ row }) => {
				return (
					<div className="w-[5ch] overflow-hidden text-ellipsis whitespace-nowrap">
						{row.getValue("postal_code")}
					</div>
				);
			},
		},
		{
			accessorKey: "city",
			header: ({ column }) => (
				<button
					type="button"
					className="flex justify-between w-fit"
					onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
				>
					<FaSort className="h-3 w-3 group-hover:cursor-pointer text-gray-400 my-auto mr-1" />
					<p className="font-bold">City</p>
				</button>
			),
		},
		{
			accessorKey: "opening_hrs",
			header: ({ column }) => <p className="font-bold w-fit">Opening</p>,
			cell: ({ row }) => {
				return (
					<div className="w-[8ch] overflow-hidden text-ellipsis whitespace-nowrap">
						{row.getValue("opening_hrs")}
					</div>
				);
			},
		},
		{
			accessorKey: "landline_no",
			header: () => <p className="font-bold w-fit">Landline</p>,
			cell: ({ row }) => {
				return (
					<div className="w-[8ch] overflow-hidden text-ellipsis whitespace-nowrap">
						{row.getValue("landline_no")}
					</div>
				);
			},
		},
		{
			accessorKey: "latitude",
			header: () => <p className="font-bold">Lat</p>,
			cell: ({ row }) => {
				return (
					<div className="w-[8ch] overflow-hidden text-ellipsis whitespace-nowrap max-w-24">
						{row.getValue("latitude")}
					</div>
				);
			},
		},
		{
			accessorKey: "longitude",
			header: () => <p className="font-bold">Long</p>,
			cell: ({ row }) => {
				return (
					<div className="w-[8ch] overflow-hidden text-ellipsis whitespace-nowrap max-w-24">
						{row.getValue("longitude")}
					</div>
				);
			},
		},
	];

	return columns;
};
