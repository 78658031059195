import { useNavigate } from "react-router-dom";
import { useToast } from "src/shared/hooks/useToast";

// Types
import { ColumnDef } from "@tanstack/react-table";
import { Product, ProductWithCategory } from "src/redux/product/types";

// Components
import { Button } from "src/shared/components/ui/button";
import { Checkbox } from "src/shared/components/ui/checkbox";
import { Label } from "src/shared/components/ui/label";
import { ToastClose } from "src/shared/components/ui/toast";

// Icons
import { FaSort } from "react-icons/fa";
import { X } from "lucide-react";
import TableEditIconSvg from "src/assets/svg/table/TableEditIconSvg";
import TableDeleteIconSvg from "src/assets/svg/table/TableDeleteIconSvg";
import TableViewIconSvg from "src/assets/svg/table/TableViewIconSvg";
import TableAddIconSvg from "src/assets/svg/table/TableAddIconSvg";

type ProductColumnProps = {
	page?: string;
	_onOpenDeleteModal?: (id: number) => void;
	_onPreviewPress?: (product: ProductWithCategory) => void;
};

export const useProductColumns = ({
	page = "Products",
	_onOpenDeleteModal,
	_onPreviewPress,
}: ProductColumnProps): ColumnDef<ProductWithCategory>[] => {
	const navigate = useNavigate();
	const { toast } = useToast();

	const columns: ColumnDef<ProductWithCategory>[] = [
		{
			id: "select",
			header: ({ table }) => (
				<Checkbox
					checked={
						table.getIsAllPageRowsSelected() ||
						(table.getIsSomePageRowsSelected() && "indeterminate")
					}
					onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
					aria-label="Select all"
					className="w-[14px] h-[14px] ml-2"
				/>
			),
			cell: ({ row }) => (
				<Checkbox
					checked={row.getIsSelected()}
					onCheckedChange={(value) => row.toggleSelected(!!value)}
					aria-label="Select row"
					className="w-[14px] h-[14px] ml-2"
				/>
			),
			enableSorting: false,
			enableHiding: false,
		},
		{
			accessorKey: "id",
			header: ({ column }) => (
				<div className="mx-4">
					<span className="pl-1">#</span>
				</div>
			),
			cell: ({ row }) => (
				<div className="flex text-xs font-bold text-center">
					<p className="mx-auto inline-block">{row.getValue("id")}</p>
				</div>
			),
		},
		{
			accessorKey: "sku",
			header: ({ column }) => (
				<Button
					variant="table_filter"
					className="w-fit"
					onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
				>
					<FaSort className="h-4 w-4 group-hover:cursor-pointer text-gray-400" />
					<Label className="group-hover:cursor-pointer">SKU</Label>
				</Button>
			),
		},
		{
			accessorKey: "name",
			header: ({ column }) => (
				<Button
					variant="table_filter"
					className="w-fit"
					onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
				>
					<FaSort className="h-4 w-4 group-hover:cursor-pointer text-gray-400" />
					<Label className="group-hover:cursor-pointer">Name</Label>
				</Button>
			),
		},
		{
			accessorKey: "image",
			header: () => (
				<div>
					<Label className={`${page == "Product" ? "ml-10" : "ml-6"}`}>
						Image
					</Label>
				</div>
			),
			cell: ({ row }) => {
				const images = row.original.images;
				const image =
					Array.isArray(images) && images.length > 0 ? [...images] : [];
				return image.length != 0 ? (
					<div
						className={`mx-4 rounded-sm overflow-hidden bg-cover bg-center ${
							page == "Product" ? "h-24 w-24" : "h-16 w-16"
						}`}
						style={{
							backgroundImage: `url('${image[0]}')`,
						}}
					/>
				) : null;
			},
		},
		{
			accessorKey: "categoryName",
			header: ({ column }) => (
				<Button
					variant="table_filter"
					className="w-fit"
					onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
				>
					<FaSort className="h-4 w-4 group-hover:cursor-pointer text-gray-400" />
					<Label className="group-hover:cursor-pointer">Category</Label>
				</Button>
			),
			cell: ({ row }) => (
				<div className="flex">
					<p className="w-fit">{row.original.categoryName}</p>
				</div>
			),
		},
		{
			accessorKey: "description",
			header: ({ column }) => (
				<Button
					variant="table_filter"
					className="w-fit"
					onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
				>
					<FaSort className="h-4 w-4 group-hover:cursor-pointer text-gray-400" />
					<Label className="group-hover:cursor-pointer">Description</Label>
				</Button>
			),
			cell: ({ row }) => (
				<div className="max-h-16 max-w-[20em] overflow-hidden text-ellipsis line-clamp-3">
					{row.original.description}
				</div>
			),
		},
		{
			id: "actions",
			header: () => (
				<div>
					<Label>Action</Label>
				</div>
			),
			cell: ({ row }) => {
				return (
					<>
						{page == "Products" ? (
							<div className="flex flex-row gap-3">
								<Button
									variant="icon"
									size="icon"
									onClick={() => navigate(`${row.original.id}`)}
								>
									<TableEditIconSvg fill="#292D32" className="h-6 w-6" />
								</Button>
								<Button
									variant="icon"
									size="icon"
									onClick={() => {
										if (_onOpenDeleteModal) {
											_onOpenDeleteModal(row.original.id);
										}
									}}
								>
									<TableDeleteIconSvg fill="#292D32" className="h-6 w-6" />
								</Button>
							</div>
						) : (
							<>
								{page == "Banner Form" ? (
									<div className="flex flex-row gap-3">
										<Button
											variant="icon"
											size="icon"
											onClick={() => {
												if (_onPreviewPress) {
													_onPreviewPress(row.original);
												}
											}}
										>
											<TableViewIconSvg fill="#292D32" className="h-6 w-6" />
										</Button>
										<Button
											variant="icon"
											size="icon"
											onClick={() => {
												if (_onOpenDeleteModal) {
													_onOpenDeleteModal(row.original.id);
												}
											}}
										>
											<TableDeleteIconSvg fill="#292D32" className="h-6 w-6" />
										</Button>
									</div>
								) : (
									<div className="flex flex-row gap-3">
										<Button
											variant="icon"
											size="icon"
											onClick={() => {
												toast({
													description: "A product has been added.",
													duration: 2000,
													variant: "success",
													action: (
														<ToastClose
															className="absolute top-1/2 -translate-y-1/2 right-5 text-white hover:text-gray-200 focus:text-gray-200"
															aria-label="Close"
														>
															<X className="w-5 h-5" />
														</ToastClose>
													),
												});
											}}
										>
											<TableAddIconSvg fill="#292D32" className="h-6 w-6" />
										</Button>
										<Button
											variant="icon"
											size="icon"
											onClick={() => {
												if (_onPreviewPress) {
													_onPreviewPress(row.original);
												}
											}}
										>
											<TableViewIconSvg fill="#292D32" className="h-6 w-6" />
										</Button>
									</div>
								)}{" "}
							</>
						)}
					</>
				);
			},
		},
	];

	return columns;
};
