import React from "react";

const ServerIssuePage = () => {
	return (
		<div className="flex flex-col items-center justify-center h-screen text-center bg-gray-100">
			<h1 className="text-3xl font-bold text-red-500 mb-4">Server Issue</h1>
			<p className="text-lg text-gray-700 max-w-[50%]">
				We're currently experiencing some issues. Please contact your system
				administrator or try again later.
			</p>
		</div>
	);
};

export default ServerIssuePage;
