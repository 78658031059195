import { useCallback, useEffect, useMemo, useState } from "react";
import { useGetProductsQuery } from "../../redux/product/apiSlice";
import { useProductColumns } from "./columns";
import { useNavigate } from "react-router-dom";

// Types
import { ProductWithCategory } from "src/redux/product/types";

// Utils
import { _exportTemplate } from "src/shared/helpers/exportFile";

// Components
import { DataTable } from "src/shared/components/table/DataTable";
import { Label } from "src/shared/components/ui/label";
import { Button } from "src/shared/components/ui/button";
import PageControls from "src/shared/components/control/PageControls";
import PageContainer from "src/shared/components/layout/container/PageContainer";
import PageHeader from "src/shared/components/layout/header/PageHeader";
import ImportButton from "src/shared/components/button/ImportButton";
import ExportButton from "src/shared/components/button/ExportButton";
import SearchInput from "src/shared/components/textfield/SearchInput";
import Loading from "src/shared/components/loading-indicator/Loading";
import ConfirmationDialog from "src/shared/components/dialog/ConfirmationDialog";

// Icons
import ButtonAddIconSvg from "src/assets/svg/ButtonAddIconSvg";
import ButtonDeleteIconSvg from "src/assets/svg/ButtonDeleteIconSvg";
import FilterIconSvg from "src/assets/svg/FilterIconSvg";
import FilterByCategoriesModal from "./fragments/FilterByCategoriesModal";
import { useGetCategoriesQuery } from "src/redux/category/apiSlice";
import { Category, CategoryFilter } from "src/redux/category/types";
import NotificationDialog from "src/shared/components/dialog/NotificationDialog";

const ProductPage = () => {
	const navigate = useNavigate();

	const { data: productsArray = [], isFetching: isProductsFetching } =
		useGetProductsQuery();

	const { data: categoriesArray = [], isFetching: isCategoriesFetching } =
		useGetCategoriesQuery();

	// ! Remove me on API Integration and use isLoading by rtk query
	const [showLoading, setShowLoading] = useState(false);
	const [searchValue, setSearchValue] = useState("");
	const [selectedRows, setSelectedRows] = useState<ProductWithCategory[]>([]);
	const [selectedCategories, setSelectedCategories] = useState<
		CategoryFilter[]
	>([]);

	const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
	const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
	const [isMultipleDeleteModalOpen, setIsMultipleDeleteModalOpen] =
		useState(false);
	const [isDeleteNotifModalOpen, setIsDeleteNotifModalOpen] = useState(false);

	const filteredProducts = useMemo(() => {
		let filteredProd: ProductWithCategory[] = [];
		let filterOptionCount = 0;

		selectedCategories.forEach((category) => {
			if (category.checked) {
				filterOptionCount += 1;
				productsArray.forEach((product) => {
					if (product.categoryId == category.id) {
						filteredProd.push(product);
					}
				});
			}
		});

		if (filterOptionCount === 0) {
			return productsArray.filter(
				(product) =>
					product.sku.toLowerCase().includes(searchValue.toLowerCase()) ||
					product.name.toLowerCase().includes(searchValue.toLowerCase()) ||
					product.description
						.toLowerCase()
						.includes(searchValue.toLowerCase()) ||
					product.categoryName.toLowerCase().includes(searchValue.toLowerCase())
			);
		} else {
			return filteredProd.filter(
				(product) =>
					product.sku.toLowerCase().includes(searchValue.toLowerCase()) ||
					product.name.toLowerCase().includes(searchValue.toLowerCase()) ||
					product.description
						.toLowerCase()
						.includes(searchValue.toLowerCase()) ||
					product.categoryName.toLowerCase().includes(searchValue.toLowerCase())
			);
		}
	}, [searchValue, productsArray, selectedCategories]);

	const handleSelectionChange = useCallback(
		(newSelectedRows: ProductWithCategory[]) => {
			setSelectedRows(newSelectedRows);
		},
		[]
	);

	const _onOpenDeleteModal = (id: number) => {
		setIsDeleteModalOpen(true);
	};

	const _onCloseDeleteModal = () => {
		setIsDeleteModalOpen(false);
	};

	const _onConfirmDelete = async () => {
		setShowLoading(true);
		setTimeout(async () => {
			setShowLoading(false);
			setIsDeleteModalOpen(false);
			setTimeout(() => {
				setIsDeleteNotifModalOpen(true);
			}, 250);
		}, 2000);
	};

	const _onOpenMultipleDeleteModal = () => {
		setIsMultipleDeleteModalOpen(true);
	};

	const _onCloseMultipleDeleteModal = () => {
		setIsMultipleDeleteModalOpen(false);
	};

	const _onConfirmMultipleDelete = async () => {
		setShowLoading(true);
		setTimeout(async () => {
			setShowLoading(false);
			setIsMultipleDeleteModalOpen(false);
			setTimeout(() => {
				setIsDeleteNotifModalOpen(true);
			}, 250);
		}, 2000);
	};

	const _onDeleteNotifOkayPress = () => {
		setIsDeleteNotifModalOpen(false);
	};

	const columns = useProductColumns({ _onOpenDeleteModal });

	useEffect(() => {
		categoriesArray.forEach((category) => {
			setSelectedCategories((prev) => {
				return [
					...prev,
					{
						...category,
						checked: false,
					},
				];
			});
		});
	}, [categoriesArray]);

	if (isProductsFetching || isCategoriesFetching) {
		return <Loading />;
	}

	return (
		<PageContainer>
			<PageHeader>
				<Label variant="title">Product Management</Label>

				<div className="m-0 p-0 h-fit w-fit flex gap-2">
					<ExportButton onClick={() => _exportTemplate("Product")} />
					<ImportButton
						buttonText="Import Product Data"
						onClick={() => navigate("import")}
					/>
				</div>
			</PageHeader>

			<PageControls>
				<div className="flex gap-2 w-full sm:w-[15em]">
					<Button onClick={() => navigate("add")}>
						<ButtonAddIconSvg fill="#292d32" className="h-6 w-6" />
						Add Product
					</Button>
					<Button
						variant="destructive"
						disabled={selectedRows.length < 1}
						onClick={_onOpenMultipleDeleteModal}
					>
						<ButtonDeleteIconSvg
							fill={`${selectedRows.length < 1 ? "#cbcbcc" : "white"}`}
							className="h-5 w-5 mb-[2px]"
						/>
						Delete
					</Button>
				</div>
				<div className="w-full xl:w-fit flex flex-row-reverse sm:flex-row gap-6 ml-auto justify-end items-center">
					<Button
						variant="icon"
						size="icon"
						onClick={() => setIsFilterModalOpen(true)}
					>
						<FilterIconSvg fill="#292d32" className="h-5 w-5 mt-1" />
					</Button>

					<SearchInput setSearchValue={setSearchValue} />
				</div>
			</PageControls>

			<div className="mt-8">
				<DataTable
					data={filteredProducts}
					columns={columns}
					onSelectionChange={handleSelectionChange}
					hasPagination={productsArray.length > 10}
				/>
			</div>

			<FilterByCategoriesModal
				modalState={isFilterModalOpen}
				modalTrigger={() => setIsFilterModalOpen(!isFilterModalOpen)}
				categories={selectedCategories}
				setCategories={setSelectedCategories}
			/>

			<ConfirmationDialog
				title="Delete Product?"
				description={`This product will be deleted`}
				closeButtonLabel="Cancel"
				confirmButtonLabel="Delete"
				confirmButtonIcon={ButtonDeleteIconSvg}
				modalState={isDeleteModalOpen}
				isLoading={showLoading}
				_onConfirm={_onConfirmDelete}
				_onCancel={_onCloseDeleteModal}
			/>

			<ConfirmationDialog
				title={`Delete Selected Products?`}
				description={`${selectedRows.length} products will be deleted`}
				closeButtonLabel="Cancel"
				confirmButtonLabel="Delete"
				confirmButtonIcon={ButtonDeleteIconSvg}
				modalState={isMultipleDeleteModalOpen}
				isLoading={showLoading}
				_onConfirm={_onConfirmMultipleDelete}
				_onCancel={_onCloseMultipleDeleteModal}
			/>

			<NotificationDialog
				description={
					selectedRows.length > 0
						? "Products has been deleted successfully!"
						: "Product has been deleted successfully!"
				}
				confirmButtonLabel="Ok"
				modalState={isDeleteNotifModalOpen}
				_onConfirm={_onDeleteNotifOkayPress}
			/>
		</PageContainer>
	);
};

export default ProductPage;
