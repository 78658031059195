import { UseFormReturn } from "react-hook-form";
import { Category } from "src/redux/category/types";
import { Product } from "src/redux/product/types";
import { FormField } from "src/shared/components/form/Form";
import { Input } from "src/shared/components/ui/input";
import { Label } from "src/shared/components/ui/label";
import {
	Select,
	SelectContent,
	SelectGroup,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from "src/shared/components/ui/select";
import FormController from "src/shared/components/form/FormController";

type ProductDetailsSectionProp = {
	form: UseFormReturn<Product, any, undefined>;
	categoriesOption: Category[];
};

const ProductDetailsSection = ({
	form,
	categoriesOption,
}: ProductDetailsSectionProp) => {
	return (
		<div className="grid lg:grid-cols-3 gap-4">
			<Label variant="sub_header" className="col-span-3">
				Product Details
			</Label>
			<div className="col-span-3 lg:col-span-1">
				<FormField
					control={form.control}
					name="sku"
					render={({ field }) => (
						<FormController label="SKU" required>
							<Input {...field} placeholder="-" />
						</FormController>
					)}
				/>
			</div>
			<div className="col-span-3 lg:col-span-1">
				<FormField
					control={form.control}
					name="name"
					render={({ field }) => (
						<FormController label="Name" required>
							<Input {...field} placeholder="-" />
						</FormController>
					)}
				/>
			</div>
			<div className="col-span-3 lg:col-span-1">
				<FormField
					control={form.control}
					name="categoryId"
					render={({ field }) => (
						<FormController label="Category" required>
							<Select
								onValueChange={(value) => field.onChange(Number(value))}
								value={String(field.value)}
							>
								<SelectTrigger
									className={`${
										!field.value ? "text-gray-300" : "text-secondary"
									}`}
								>
									<SelectValue placeholder="Select a category" />
								</SelectTrigger>
								<SelectContent>
									<SelectGroup>
										<SelectItem value="0" disabled>
											No Category
										</SelectItem>
										<>
											{categoriesOption.map((category) => (
												<SelectItem
													key={category.id}
													value={String(category.id)}
												>
													{category.name}
												</SelectItem>
											))}
										</>
									</SelectGroup>
								</SelectContent>
							</Select>
						</FormController>
					)}
				/>
			</div>
			<div className="col-span-3">
				<FormField
					control={form.control}
					name="description"
					render={({ field }) => (
						<FormController label="Description" required>
							<Input {...field} placeholder="-" />
						</FormController>
					)}
				/>
			</div>
		</div>
	);
};

export default ProductDetailsSection;
