import React, { ElementType, forwardRef, HTMLProps } from "react";
import { Input } from "../ui/input";
import { Button } from "../ui/button";
import ImportIconSvg from "src/assets/svg/ImportIconSvg";
import { cn } from "src/lib/utils";

type UploadButtonProps = HTMLProps<HTMLInputElement> & {
	icon?: ElementType;
	buttonText?: string;
	className?: string;
};

const UploadButton = forwardRef<HTMLInputElement, UploadButtonProps>(
	({ icon: Icon, buttonText, className, ...props }, ref) => {
		return (
			<div className={cn("", className)}>
				<Input id="input" type="file" className="hidden" {...props} ref={ref} />
				<Button
					type="button"
					onClick={() => document.getElementById("input")?.click()}
					variant="upload"
				>
					{!Icon ? (
						<ImportIconSvg fill="currentColor" className="w-5 h-5 mr-2" />
					) : (
						<Icon fill="currentColor" className="w-5 h-5" />
					)}
					{buttonText && (
						<span className="text-xs lg:text-base">{buttonText}</span>
					)}
				</Button>
			</div>
		);
	}
);

export default UploadButton;
