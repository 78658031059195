import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { Account } from "./types";
import accountsArray from "src/data/accounts/accounts.json";

export const accountsApi = createApi({
	reducerPath: "accountsApi",
	tagTypes: ["Accounts"],
	baseQuery: fetchBaseQuery({ baseUrl: "/" }),
	endpoints: (builder) => ({
		getAccounts: builder.query<Account[], void>({
			queryFn() {
				return new Promise<{ data: Account[] }>((resolve, reject) => {
					try {
						setTimeout(() => {
							// TODO: Change this to api call
							const accounts = accountsArray.map((account: Account) => {
								return {
									...account,
								};
							});

							resolve({ data: accounts });
						}, 1000);
					} catch (error) {
						reject({ error: error as Error });
					}
				});
			},
		}),
		getCurrentUserAccount: builder.query<Account, number>({
			async queryFn(id) {
				return new Promise<{ data: Account }>((resolve, reject) => {
					try {
						setTimeout(async () => {
							const currentUserLoggedIn = accountsArray.find(
								(account) => id === account.id
							);

							if (currentUserLoggedIn) {
								const response = await fetch(currentUserLoggedIn.image);

								const blob = await response.blob();
								const imageUrl = !currentUserLoggedIn.image
									? ""
									: URL.createObjectURL(blob);
								const userAccount = {
									...currentUserLoggedIn,
									image: imageUrl,
								};

								resolve({
									data: userAccount as Account,
								});
							} else {
								reject({ error: new Error("Account Not Found!") });
							}

							resolve({
								data: currentUserLoggedIn as Account,
							});
						}, 1000);
					} catch (error) {
						reject({ error: error as Error });
					}
				});
			},
			keepUnusedDataFor: 0,
		}),
		addAccount: builder.mutation<void, Account>({
			query: (newAccount) => ({
				url: "/new",
				method: "POST",
				body: newAccount,
			}),
			invalidatesTags: ["Accounts"],
		}),
		updateAccount: builder.mutation<void, Account>({
			query: (updatedAccount) => ({
				url: `/update/${updatedAccount.id}`,
				method: "PUT",
				body: updatedAccount,
			}),
		}),
		deleteAccount: builder.mutation<void, string>({
			query: (id) => ({
				url: `/delete/${id}`,
				method: "DELETE",
			}),
			invalidatesTags: ["Accounts"],
		}),
	}),
});

export const {
	useGetAccountsQuery,
	useGetCurrentUserAccountQuery,
	useAddAccountMutation,
	useUpdateAccountMutation,
	useDeleteAccountMutation,
} = accountsApi;
