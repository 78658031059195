import { createApi } from "@reduxjs/toolkit/query/react";

// Type
import { Category } from "./types";
import { SuccessMultipleDataResponse } from "../types";

// Utils
import { baseQueryWithReauth } from "src/shared/helpers/prepareBaseQuery";

export const categoryApi = createApi({
	reducerPath: "categoryApi",
	tagTypes: ["Categories", "Category"],
	baseQuery: baseQueryWithReauth,
	endpoints: (builder) => ({
		getCategories: builder.query<Category[], void>({
			query: () => ({
				url: "/category/get_all",
				method: "GET",
			}),
			transformResponse({
				status,
				data,
				message,
			}: SuccessMultipleDataResponse<Category>) {
				const categories = data.map((category) => {
					return {
						...category,
					};
				});

				return categories;
			},
			providesTags: ["Categories"],
			keepUnusedDataFor: 0,
		}),
		getSelectedCategory: builder.query<Category, number>({
			query: (id) => ({
				url: `/category/${id}`,
				method: "GET",
			}),
			transformResponse({
				status,
				data,
				message,
			}: SuccessMultipleDataResponse<Category>) {
				const category = {
					...data[0],
				};

				return category;
			},
			keepUnusedDataFor: 0,
		}),
		addCategory: builder.mutation<void, Omit<Category, "id">>({
			query: (newCategory) => ({
				url: "/category/create",
				method: "POST",
				body: newCategory,
			}),
			invalidatesTags: ["Categories"],
		}),
		updateCategory: builder.mutation<void, Category>({
			query: (updatedCategory) => ({
				url: `/category/${updatedCategory.id}`,
				method: "PATCH",
				body: updatedCategory,
			}),
			invalidatesTags: ["Categories"],
		}),
		deleteCategory: builder.mutation<void, number>({
			query: (id) => ({
				url: `/category/${id}`,
				method: "DELETE",
			}),
			invalidatesTags: ["Categories"],
		}),
	}),
});

export const {
	useGetCategoriesQuery,
	useGetSelectedCategoryQuery,
	useAddCategoryMutation,
	useUpdateCategoryMutation,
	useDeleteCategoryMutation,
} = categoryApi;
