import { createSlice } from "@reduxjs/toolkit";

type LocationState = {
	city: string;
	lat: number;
	long: number;
};

const initialState: LocationState = {
	city: "",
	lat: 0,
	long: 0,
};

const locationSlice = createSlice({
	name: "location",
	initialState,
	reducers: {
		_handleLocationChange: (state, action) => {
			state.city = action.payload.city || "";
			state.lat = action.payload.lat || 0;
			state.long = action.payload.long || 0;
		},
	},
});

export const { _handleLocationChange } = locationSlice.actions;
export default locationSlice.reducer;
