import { z } from "zod";

export const BannerFormSchema = z
	.object({
		title: z
			.string({
				required_error: "Please enter the title.",
			})
			.min(2, "Title must be at least 2 characters long.")
			.max(50, "Title must be less than 50 characters."),
		image: z
			.union([
				z.instanceof(File),
				z.string().url({
					message: "Banner Image is empty.",
				}),
			])
			.optional(),
		description: z
			.string({
				message: "Please enter banner description.",
			})
			.min(5, "Description must be at least 5 characters.")
			.max(750, "Description must be less than 750 characters."),
		products: z.number().nonnegative("Invalid products.").optional(),
		from: z.string().optional(),
		to: z.string().optional(),
		categoryId: z.coerce
			.number({
				required_error: "Please select a category.",
			})
			.min(0, "Category must be a non-negative integer")
			.optional(),
		status: z.coerce.number().min(0, "Status must be a non-negative integer"),
		order: z
			.number()
			.nonnegative("Order must be a non-negative number")
			.optional(),
	})
	.refine(
		(data) => {
			if (data.from && data.to) {
				// Convert strings to Date objects for comparison
				const fromDate = new Date(data.from);
				const toDate = new Date(data.to);
				return toDate >= fromDate; // `to` must not be before `from`
			}
			return true; // If either `from` or `to` is missing, pass validation
		},
		{
			message: "End date cannot be earlier than Start date.",
			path: ["from"], // Attach the error message to the `from` field
		}
	);
