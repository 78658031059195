import { jwtDecode } from "jwt-decode";
import {
	createContext,
	ReactNode,
	useContext,
	useEffect,
	useMemo,
	useState,
} from "react";
import { Permission } from "src/redux/role/types";
import { CustomJwtPayload } from "src/redux/types";

type AuthProviderValue = {
	permissions: Permission[];
	token: string;
	_logout: () => void;
	setCredentials: (permission: Permission[], token: string) => void;
};

const AuthContext = createContext<AuthProviderValue | undefined>(undefined);

AuthContext.displayName = "AuthContext";

export const useAuth = () => {
	const authContext = useContext(AuthContext);

	if (!authContext) {
		throw new Error("useAuth must be used within AuthProvider");
	}

	return authContext;
};

const AuthProvider = ({ children }: { children: ReactNode }) => {
	const permissionToken = localStorage.getItem("PERMISSION_TOKEN");

	const [token, setToken] = useState<string>(
		localStorage.getItem("ACCESS_TOKEN") ?? ""
	);
	const [permissions, setPermissions] = useState<Permission[]>([]);

	const setCredentials = (permissions: Permission[], token: string) => {
		setPermissions(permissions);
		setToken(token);
	};

	const _logout = async () => {
		const _removeUser = () => {
			localStorage.clear();
		};

		_removeUser();
		window.location.href = "/auth";
	};

	useEffect(() => {
		if (permissionToken) {
			const decodedPermissions = jwtDecode<CustomJwtPayload>(
				String(permissionToken) || ""
			);
			const { permissions: permissionArr } = decodedPermissions;

			setPermissions(permissionArr);
		}
	}, [permissionToken]);

	const value = useMemo(() => {
		return {
			permissions,
			token,
			_logout,
			setCredentials,
		};
	}, [token]);

	return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export default AuthProvider;
