import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useForm, useWatch } from "react-hook-form";
import { useEffect, useState } from "react";
import { useGetSelectedProductsQuery } from "../../../redux/product/apiSlice";
import { useGetCategoriesQuery } from "src/redux/category/apiSlice";

// Libraries
import { zodResolver } from "@hookform/resolvers/zod";

// Utils
import { Product } from "src/redux/product/types";
import { ProductFormSchema } from "src/redux/product/schema";

// Component
import { Button } from "src/shared/components/ui/button";
import { Label } from "src/shared/components/ui/label";
import { Form } from "src/shared/components/form/Form";
import PageContainer from "src/shared/components/layout/container/PageContainer";
import FormHeader from "src/shared/components/layout/header/FormHeader";
import ConfirmationDialog from "src/shared/components/dialog/ConfirmationDialog";
import Loading from "src/shared/components/loading-indicator/Loading";
import ButtonLoading from "src/shared/components/loading-indicator/ButtonLoading";
import ProductDetailsSection from "./ProductDetailsSection";
import PriceSection from "./PriceSection";
import DiscountSection from "./DiscountSection";
import ImagesSection from "./ImagesSection";

// Icons
import ButtonCheckIconSvg from "src/assets/svg/ButtonCheckIconSvg";
import NotificationDialog from "src/shared/components/dialog/NotificationDialog";

const ProductForm = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const pathSegments = location.pathname.split("/");
	const { productId } = useParams<{ productId: string }>();

	const { data: selectedProduct, isFetching: isSelectedProductFetching } =
		useGetSelectedProductsQuery({ id: Number(productId) });

	const { data: categoriesOption = [], isFetching: isCategoriesFetching } =
		useGetCategoriesQuery();

	// ! Remove me on API Integration and use isLoading by rtk query
	const [showLoading, setShowLoading] = useState(false);

	const [isLeaveModalOpen, setIsLeaveModalOpen] = useState(false);
	const [isSaveModalOpen, setIsSaveModalOpen] = useState(false);
	const [isNotifModalOpen, setIsNotifModalOpen] = useState(false);

	const form = useForm<Product>({
		resolver: zodResolver(ProductFormSchema),
		defaultValues: {
			sku: "",
			images: [],
			name: "",
			categoryId: 0,
			description: "",
			price: 0.0,
			discounted_price: 0.0,
			startDate: "",
			endDate: "",
		},
		mode: "onBlur",
		reValidateMode: "onSubmit",
		criteriaMode: "all",
	});

	const dates = useWatch({
		control: form.control,
		name: ["startDate", "endDate"],
	});

	const { isDirty, isValid } = form.formState;

	const _onConfirmLeave = () => {
		navigate("/product");

		form.reset();
	};

	const _onCancelPress = () => {
		if (!isDirty) navigate("/product");

		setIsLeaveModalOpen(true);
	};

	const _onSavePress = () => {
		setIsSaveModalOpen(true);
	};

	const _onConfirmSave = () => {
		_onSubmit(form.getValues());
	};

	const _onSubmit = (values: Product) => {
		if (values) {
			setShowLoading(true);
			setTimeout(async () => {
				setShowLoading(false);
				setIsNotifModalOpen(true);
			}, 2000);
			setIsSaveModalOpen(false);
		}
	};

	const _onNotifOkayPress = () => {
		setIsNotifModalOpen(false);
		navigate("/product");
	};

	// Update form values when selectedProduct data is available
	useEffect(() => {
		if (selectedProduct) {
			console.log(selectedProduct.startDate);
			form.reset(
				{
					sku: selectedProduct.sku,
					images: selectedProduct.images,
					name: selectedProduct.name,
					categoryId: selectedProduct.categoryId,
					description: selectedProduct.description,
					price: selectedProduct.price,
					discounted_price: selectedProduct.discounted_price,
					startDate: selectedProduct.startDate,
					endDate: selectedProduct.endDate,
				},
				{
					keepDirty: false,
					keepTouched: false,
					keepIsValid: false,
					keepErrors: false,
				}
			);
		}
	}, [selectedProduct, form]);

	useEffect(() => {
		if (isDirty) {
			form.trigger(["startDate", "endDate"]);
		}
	}, [dates]);

	if (isSelectedProductFetching || isCategoriesFetching) {
		return <Loading />;
	}

	if (!isSelectedProductFetching && !selectedProduct) {
		if (pathSegments[2] != "add") {
			navigate("/product", { replace: true });
		}
	}

	return (
		<Form {...form}>
			<div className="h-auto relative">
				<form onSubmit={form.handleSubmit(_onSubmit)}>
					<PageContainer className="space-y-4">
						<FormHeader>
							<Label variant="title">Product Management</Label>
							<div className="w-full h-fit sm:w-fit flex gap-2">
								<Button
									disabled={!isDirty || !isValid || showLoading}
									onClick={_onSavePress}
								>
									{showLoading ? (
										<ButtonLoading />
									) : (
										<>
											<ButtonCheckIconSvg
												className="h-5 w-5"
												fill={!isDirty || !isValid ? "#cbcbcc" : "#292D32"}
											/>
											Save
										</>
									)}
								</Button>
								<Button
									variant="secondary"
									size="md"
									type="reset"
									onClick={_onCancelPress}
									disabled={showLoading}
								>
									Cancel
								</Button>
							</div>
						</FormHeader>

						<ProductDetailsSection
							form={form}
							categoriesOption={categoriesOption}
						/>
						<PriceSection form={form} />
						<DiscountSection form={form} />
						<ImagesSection form={form} />
					</PageContainer>
				</form>
			</div>

			<ConfirmationDialog
				title="Leave page?"
				description="Changes are not yet saved."
				confirmButtonLabel="Leave"
				closeButtonLabel="Cancel"
				modalState={isLeaveModalOpen}
				_onCancel={() => setIsLeaveModalOpen(false)}
				_onConfirm={_onConfirmLeave}
			/>

			<ConfirmationDialog
				title="Save Changes?"
				description="Saving will apply all changes."
				confirmButtonLabel="Continue"
				closeButtonLabel="Cancel"
				modalState={isSaveModalOpen}
				_onCancel={() => setIsSaveModalOpen(false)}
				_onConfirm={_onConfirmSave}
				confirmButtonVariant="default"
			/>

			<NotificationDialog
				description={
					!productId
						? "Product has been added successfully!"
						: "Product has been updated successfully!"
				}
				confirmButtonLabel="Ok"
				modalState={isNotifModalOpen}
				_onConfirm={_onNotifOkayPress}
			/>
		</Form>
	);
};

export default ProductForm;
